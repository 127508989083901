import React from "react";
import { Route } from "react-router-dom";
import DashboardPage from "./DashboardPage";
import usePageViewTracker from "../common/Tracking";

const Dashboard = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Route
      path="/dashboard"
      render={(props) => (
        <DashboardPage session={session} window={window} {...props} />
      )}
    />
  );
};

export default Dashboard;

import React from "react";
import OrderList from "./OrderList";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import usePageViewTracker from "../common/Tracking";

const Order = ({ session, window }) => {
  usePageViewTracker(session);
  return (
    <Route
      exact={true}
      path="/order/orderLines"
      render={(props) => (
        <OrderList session={session} window={window} {...props} />
      )}
    />
  );
};

export default withRouter(Order);

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentList from "./DocumentList";
import usePageViewTracker from "../common/Tracking";

const Invoice = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path={"/invoice"}
        render={() => <Redirect replace to={"/invoice/invoices"} />}
      />
      <Route
        exact={true}
        path="/invoice/invoices"
        render={(props) => (
          <DocumentList session={session} window={window} {...props} />
        )}
      />
    </Switch>
  );
};

export default Invoice;

import React, { Component } from "react";
import { MainActionButton } from "../../common/Buttons";
import { TableBase } from "../../common/Tables";
import UserTagDialog from "./UserTagDialog";
import { getTagOptions } from "../../common/TagUtil";
import { InternalLink } from "../../common/Link";

class TagTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagOptions: [],
      showDialog: false,
    };
  }

  onUpdateTagsDialogShow = () => {
    if (this.props.session.hasRole("SYS_ADMIN")) {
      const clientId = this.props.user.organisations[0].clientId;
      let url = this.props.session.getApplication().links.tags;
      url += "?clientId=" + clientId;
      url += "&labelOnlyIncluded=" + false;
      url += "&visibleOnly=" + false;

      this.props.session.backendGet(url).then((response) => {
        this.setState({
          tagOptions: response,
          showDialog: true,
        });
      });
    } else {
      this.setState({
        tagOptions: getTagOptions(
          this.props.session.getApplication().tags,
          false,
          false,
        ),
        showDialog: true,
      });
    }
  };

  onClose = () => {
    this.setState({ showDialog: false, tagOptions: null });
  };

  onSave = () => {
    this.onClose();
    this.props.onChange();
  };

  getTagLink = (r) => {
    return (
      <InternalLink
        to={"/useradmin/tags/" + r.id}
        text={r.name}
        className="table-link"
      />
    );
  };

  render() {
    const columns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
        contentFunction: (r) => this.getTagLink(r),
      },
    ];

    const actions = [
      <MainActionButton
        key="edit-tags"
        onClick={this.onUpdateTagsDialogShow}
        prefix={"fas"}
        icon="edit"
        text="Edit Tags"
      />,
    ];

    return (
      <div>
        <UserTagDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDialog}
          currentTagIds={this.props.user.tags.map((o) => o.id)}
          url={this.props.user.links.tags}
          options={this.state.tagOptions}
          onClose={this.onClose}
          onSave={this.onSave}
        />
        <TableBase
          columns={columns}
          rows={this.props.user.tags}
          actions={actions}
        />
      </div>
    );
  }
}

export default TagTab;

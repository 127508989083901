import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PortsAlert } from "./PortsAlert";
import { DialogSection, WizardDialog } from "./Dialog";
import ReadOnlyForm from "./ReadOnlyForm";

export default class UpdateDomainLifecycleDialog extends Component {
  renew;

  constructor(props) {
    super(props);
    this.state = {
      subscriptions: null,
      includeSubscriptions: false,
    };
  }

  componentDidMount() {
    this.reloadSubscriptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadSubscriptions();
    }
  }

  reloadSubscriptions = () => {
    this.setState({ subscriptions: null, includeSubscriptions: false });
    if (this.props.domain?.links.subscriptions) {
      this.props.session.backendGet(
        this.props.domain.links.subscriptions,
        (response) => {
          this.setState({ subscriptions: response });
        },
      );
      this.renew = this.props.domain.onExpire === "Cancel";
    }
  };

  onCheckChange = (changeEvent) => {
    this.setState({
      includeSubscriptions: changeEvent.target.checked,
    });
  };

  onConfirm = () => {
    let subscriptionIds = [];
    if (
      this.state.includeSubscriptions &&
      this.state.subscriptions &&
      this.state.subscriptions.renewableSubscriptions &&
      this.state.subscriptions.renewableSubscriptions.length > 0
    ) {
      this.state.subscriptions.renewableSubscriptions.forEach((o) =>
        subscriptionIds.push(o.id),
      );
    }
    let resource = {
      renew: this.renew,
      relatedSubscriptionIds: subscriptionIds,
    };
    this.props.onConfirm(resource);
  };

  getSubscriptions = (subscriptions, updatable) => {
    let parts = [];
    let checkbox;
    let input;
    let text;

    if (this.renew) {
      text = "Renew all related services when they expire";
    } else {
      text = "Cancel all related services when they expire";
    }

    if (!updatable) {
      const tooltip = (
        <Tooltip style={{ position: "fixed" }}>
          Your user account does not have privileges to update the services,
          contact your client manager to activate this functionality
        </Tooltip>
      );
      input = (
        <input
          type="checkbox"
          onChange={this.onCheckChange}
          checked={this.state.includeSubscriptions}
          disabled
        />
      );
      checkbox = (
        <OverlayTrigger overlay={tooltip} placement="bottom">
          <label className="check-label">
            {input}
            &nbsp;{text}
          </label>
        </OverlayTrigger>
      );
    } else {
      input = (
        <input
          type="checkbox"
          onChange={this.onCheckChange}
          checked={this.state.includeSubscriptions}
        />
      );
      checkbox = (
        <label className="check-label">
          {input}&nbsp;&nbsp;{text}
        </label>
      );
    }

    parts.push(
      <div key="body">
        The domain has related services, they are shown in the list below. If
        you want them {this.renew ? "renewed" : "cancelled"} when they expire as
        well, tick the checkbox below.
        <br />
        <br />
      </div>,
    );

    let subscriptionList = [];
    subscriptions.forEach((o) => {
      subscriptionList.push({
        label: o.typeDescription,
        value: o.description,
        labelStyle: { paddingLeft: "20px" },
      });
    });

    let listPart = (
      <DialogSection title="">
        <ReadOnlyForm content={subscriptionList} />
      </DialogSection>
    );

    parts.push(checkbox);
    parts.push(listPart);

    return parts;
  };

  getSubscriptionSection = () => {
    if (!this.state.subscriptions) {
      return null;
    }

    let parts = [];
    const renewableSubscriptions =
      this.state.subscriptions.renewableSubscriptions;
    const allRenewableSubscriptions =
      this.state.subscriptions.allRenewableSubscriptions;
    const subscriptionsLifecycleUpdatable =
      this.state.subscriptions.subscriptionsLifecycleUpdatable;

    if (
      renewableSubscriptions &&
      renewableSubscriptions.length === 0 &&
      allRenewableSubscriptions
    ) {
      return parts;
    }

    if (renewableSubscriptions && renewableSubscriptions.length > 0) {
      parts.push(
        this.getSubscriptions(
          renewableSubscriptions,
          subscriptionsLifecycleUpdatable,
        ),
      );
    }

    if (!allRenewableSubscriptions) {
      parts.push(
        <div key="warning">
          <PortsAlert variant="warning">
            <strong>Note:</strong> The domain has services configured. Your user
            account does not have privileges to access to those services. Please
            contact your client manager for further details.
          </PortsAlert>
        </div>,
      );
    }

    return parts;
  };

  getBody = () => {
    if (!this.props.domain) {
      return null;
    }

    let parts = [];
    let action = this.renew ? "renew" : "cancel";

    parts.push(
      <div key="div">
        Are you sure you want to {action} domain {this.props.domain.name} when
        it expires?
      </div>,
    );
    parts.push(<br key="empty"></br>);
    parts.push(this.getSubscriptionSection());

    return <div>{parts}</div>;
  };

  render() {
    let title = "";
    if (this.props.domain) {
      let action = this.renew ? "Renew" : "Cancel";
      title = action + " domain on expiry";
    }

    return (
      <WizardDialog
        show={this.props.show}
        title={title}
        body={this.getBody()}
        errorMessage={this.props.errorMessage}
        height="sm"
        onCancel={this.props.onClose}
        onCreate={this.onConfirm}
      />
    );
  }
}

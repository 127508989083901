import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserList from "./user/UserList";
import UserPage from "./user/UserPage";
import OrganisationList from "./organisation/OrganisationList";
import IdentityProviderList from "./identityprovider/IdentityProviderList";
import TagList from "./tag/TagList";
import TagPage from "./tag/TagPage";
import usePageViewTracker from "../common/Tracking";

const UserAdmin = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path={"/useradmin"}
        render={() => <Redirect replace to={"/useradmin/users"} />}
      />
      <Route
        exact={true}
        path="/useradmin/users"
        render={() => <UserList session={session} window={window} />}
      />
      <Route
        path="/useradmin/users/:userName"
        render={(props) => (
          <UserPage session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/useradmin/accounts"
        render={() => <OrganisationList session={session} window={window} />}
      />
      <Route
        exact={true}
        path="/useradmin/tags"
        render={() => <TagList session={session} window={window} />}
      />
      <Route
        path="/useradmin/tags/:tagId"
        render={(props) => (
          <TagPage session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/useradmin/identityproviders"
        render={() => (
          <IdentityProviderList session={session} window={window} />
        )}
      />
    </Switch>
  );
};

export default UserAdmin;

import React, { Component } from "react";
import { MessageDialog } from "../common/Dialog";
import { RiskItems, RiskSpan } from "./RiskUtil";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class UpdateRiskDialog extends Component {
  getDescription = () => {
    if (this.props.nbrOfHits == 1) {
      return "Select the risk below.";
    } else {
      return (
        "Select the risk below for the " +
        this.props.nbrOfHits +
        " selected hits."
      );
    }
  };

  getTooltip = (tooltip) => {
    return (
      <Tooltip id="tooltip" className="position-absolute">
        {tooltip}
      </Tooltip>
    );
  };

  getItem = (value, tooltip) => {
    let className = "item item-" + value;
    if (value === this.props.value) {
      className += " item-selected";
    }

    let result = (
      <div className={className} onClick={() => this.props.onSave(value)} />
    );

    if (tooltip) {
      result = (
        <OverlayTrigger
          trigger="hover"
          overlay={this.getTooltip(tooltip)}
          placement="bottom"
          delay={500}
          rootClose={true}
        >
          {result}
        </OverlayTrigger>
      );
    }
    return result;
  };

  getItems = () => {
    const items = [];
    RiskItems.forEach((i) => items.push(this.getItem(i.item, i.description)));
    return items;
  };

  getBody = () => {
    return <RiskSpan>{this.getItems()}</RiskSpan>;
  };

  render() {
    return (
      <MessageDialog
        show={this.props.show}
        title="Update Risk"
        description={this.getDescription()}
        body={this.getBody()}
        onClose={this.props.onCancel}
      />
    );
  }
}

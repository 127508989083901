class Session {
  constructor(keycloak) {
    this._keycloak = keycloak;
    this._application = null;
  }

  getApplication() {
    return this._application;
  }

  setApplication(application) {
    this._application = application;
  }

  getBaseUrl() {
    return this._application.links.self;
  }

  getHostUrl() {
    return this._application.links.host;
  }

  getLogoutUrl() {
    return this._application.links.logout;
  }

  getPayUrl() {
    return this._application.links.pay;
  }

  getDomasUrl() {
    return this._application.links.domas;
  }

  getRoles() {
    return this._application.roles;
  }

  isInternalUser() {
    return this._application.internalUser;
  }

  getPortfolioOperations() {
    return this._application.portfolioOperations;
  }

  getSessionTimeout() {
    if (
      this._keycloak.refreshTokenParsed != null &&
      this._keycloak.refreshTokenParsed.exp != null
    ) {
      return this._keycloak.refreshTokenParsed.exp;
    }
    return 0;
  }

  hasRole() {
    for (let i = 0; i < arguments.length; i++) {
      if (this._application.roles.indexOf(arguments[i]) !== -1) return true;
    }
    return false;
  }

  getModules() {
    return this._application.modules;
  }

  getMenu() {
    let modules = this._application.modules;
    let moduleIds = Object.keys(modules);
    let result = [];

    for (let i = 0; i < moduleIds.length; i++) {
      let module = modules[moduleIds[i]];
      let item = result.find((o) => o.label === module.label);

      if (item) {
        item.menuItems = item.menuItems.concat(module.menuItems);
      } else {
        result.push({
          label: module.label,
          menuItems: module.menuItems,
          toolbarItems: module.toolbarItems,
        });
      }
    }

    return result;
  }

  backendGet(url, callback, onError) {
    return this.handleSessionTimeout(() => {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this._keycloak.token,
        },
      })
        .then((response) =>
          this._handleResponse(response, false, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendGetFile(url, callback, onError) {
    this.handleSessionTimeout(() => {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/octet-stream",
          Authorization: "Bearer " + this._keycloak.token,
        },
      })
        .then((response) =>
          this._handleResponse(response, true, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendDelete(url, callback, onError) {
    this.handleSessionTimeout(() => {
      fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this._keycloak.token,
        },
      })
        .then((response) =>
          this._handleResponse(response, false, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendPost(url, body, callback, onError) {
    this.handleSessionTimeout(() => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this._keycloak.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) =>
          this._handleResponse(response, false, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendPostFile(url, body, callback, onError) {
    this.handleSessionTimeout(() => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/octet-stream",
          Authorization: "Bearer " + this._keycloak.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) =>
          this._handleResponse(response, true, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendPut(url, body, callback, onError) {
    this.handleSessionTimeout(() => {
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this._keycloak.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) =>
          this._handleResponse(response, false, callback, onError),
        )
        .catch((error) => this._handleFail(error, onError));
    });
  }

  backendXhrSend(xhr, body) {
    this.handleSessionTimeout(() => {
      xhr.setRequestHeader("Authorization", "Bearer " + this._keycloak.token);
      xhr.send(body);
    });
  }

  _handleResponse(response, binary, callback, onError) {
    if (response.status === 204) {
      if (callback) {
        callback({});
      }
      return response;
    } else if (response.ok) {
      let promise;
      const contentType = response.headers.get("content-type");
      if (binary) {
        if (contentType && contentType.indexOf("text/plain") !== -1) {
          promise = response.text();
        } else {
          promise = response.arrayBuffer();
        }
      } else {
        if (contentType === "application/json") {
          promise = response.json();
        } else {
          promise = response.arrayBuffer();
        }
      }
      if (callback) {
        promise.then((body) => callback(body));
      }
      return promise;
    } else {
      response.text().then((text) => {
        const errorMessage = this.getErrorMessage(text);
        if (onError) {
          onError(errorMessage);
        } else {
          alert(errorMessage);
        }
      });
    }
  }

  _handleFail(error, onError) {
    let message = error.toString();
    if (onError) {
      onError(message);
    } else {
      alert(message);
    }
  }

  getErrorMessage(text) {
    try {
      let body = JSON.parse(text);
      return body.errorMessage;
    } catch (err) {
      return "Unexpected error";
    }
  }

  logout(redirectUri) {
    if (redirectUri) {
      this._keycloak.logout({ redirectUri: redirectUri });
    } else {
      this._keycloak.logout({ redirectUri: this.getLogoutUrl() });
    }
  }

  handleSessionTimeout(callback) {
    return this._keycloak
      .updateToken(30)
      .then(callback)
      .catch(() => this.logout());
  }

  reloadApplication = () => {
    this.backendGet("api/rest", (response) => {
      this.setApplication(response);
    });
  };
}

export default Session;

import React, { Component } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  ClearFix,
  Header,
  HeaderMeta,
  HeaderMetaIcon,
  HeaderMetaLabel,
  HeaderMetaText,
  Organisation,
  PaddedContent,
  TitleWrapper,
  TruncatedTitle,
} from "../../common/theme/Theme";
import styled from "styled-components";
import { ActionButton } from "../../common/Buttons";
import TagDialog from "./TagDialog";
import {
  getCascadeModeTooltip,
  getDisplayModeIcon,
  getDisplayModeTooltip,
  getTypeTooltip,
} from "../../common/TagUtil";

const TagButtons = styled.div`
  margin-top: 15px;
  display: block;
`;

class TagHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: props.tag,
      showTagDialog: false,
      conf: null,
    };
  }

  reload = () => {
    let url = this.state.tag.links.self;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        tag: response,
      });
    });
  };

  showTagDialog = () => {
    let confUrl = this.state.tag.links.configuration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showTagDialog: true });
    });
  };

  closeDialog = () => {
    this.setState({
      showTagDialog: false,
      conf: null,
    });
  };

  handleSubmit = () => {
    this.closeDialog();
    this.reload();
  };

  getActionButtons = () => {
    let parts = [];

    if (this.state.tag) {
      parts.push(
        <ActionButton
          text={"Edit Tag"}
          icon={"edit"}
          prefix={"fas"}
          onClick={this.showTagDialog}
        />,
      );
    }
    return parts;
  };

  getTagBasicInfo() {
    if (!this.state.tag) {
      return;
    }

    let fields = [];
    let row = [];

    let tooltipDisplayMode = (
      <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
        {getDisplayModeTooltip(
          this.state.tag.displayMode,
          this.state.tag.displayModeDescription,
        )}
      </Tooltip>
    );
    row.push(
      <HeaderMeta key="displayMode">
        <HeaderMetaLabel>Display Mode</HeaderMetaLabel>
        <OverlayTrigger placement="bottom" overlay={tooltipDisplayMode}>
          <HeaderMetaIcon>
            {getDisplayModeIcon(this.state.tag.displayMode)}
          </HeaderMetaIcon>
        </OverlayTrigger>
      </HeaderMeta>,
    );

    let tooltipCascade = (
      <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
        {getCascadeModeTooltip(this.state.tag.cascadeMode)}
      </Tooltip>
    );
    row.push(
      <HeaderMeta key="cascade">
        <HeaderMetaLabel>Cascade Mode</HeaderMetaLabel>
        <OverlayTrigger placement="bottom" overlay={tooltipCascade}>
          <HeaderMetaText>{this.state.tag.cascadeMode}</HeaderMetaText>
        </OverlayTrigger>
      </HeaderMeta>,
    );

    let tooltipType = (
      <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
        {getTypeTooltip(this.state.tag.tagType)}
      </Tooltip>
    );
    row.push(
      <HeaderMeta key="type">
        <HeaderMetaLabel>Tag Type</HeaderMetaLabel>
        <OverlayTrigger placement="bottom" overlay={tooltipType}>
          <HeaderMetaText>{this.state.tag.tagType}</HeaderMetaText>
        </OverlayTrigger>
      </HeaderMeta>,
    );

    fields.push(<Row key="basic-row-1">{row}</Row>);
    return <ClearFix>{fields}</ClearFix>;
  }

  render() {
    return (
      <div>
        <Header>
          <Container fluid>
            <Row>
              <Col xs={5}>
                <Row>
                  <TitleWrapper>
                    <TruncatedTitle
                      data={this.state.tag.name}
                      isTitle={true}
                      breakAfter={50}
                    ></TruncatedTitle>
                    <Organisation>{this.state.tag.organisation}</Organisation>
                  </TitleWrapper>
                </Row>
                <Row>
                  <Col>
                    <ClearFix />
                    <TagButtons>{this.getActionButtons()}</TagButtons>
                  </Col>
                </Row>
              </Col>
              <Col xs={5}>
                <PaddedContent padding={"0px 20px 0 0"}>
                  {this.getTagBasicInfo()}
                </PaddedContent>
              </Col>
            </Row>
          </Container>
        </Header>
        <TagDialog
          session={this.props.session}
          window={this.props.window}
          showDialog={this.state.showTagDialog}
          closeDialog={this.closeDialog}
          handleSubmit={this.handleSubmit}
          conf={this.state.conf}
          create={false}
        />
      </div>
    );
  }
}

export default TagHeader;

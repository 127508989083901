import React, { Component } from "react";
import { Loader } from "../../common/theme/Theme";
import IpRightHeader from "./IpRightHeader";

import { NavTab } from "react-router-tabs";
import Icon from "../../common/theme/Icons";
import { PageTabsNew } from "../../common/PageTabs";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentsTab from "./DocumentsTab";
import ClassesTab from "./ClassesTab";
import CountriesTab from "./CountriesTab";
import PartiesTab from "./PartiesTab";
import DetailsTab from "./DetailsTab";
import ProgressTab from "./ProgressTab";
import { PageViewTracker } from "../../common/Tracking";

class IpRightPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      ipRight: null,
      baseUrl: null,
      activeTab: 1,
      errorMessage: null,
      submit: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    let url =
      this.props.session.getModules().portfolio.links.ipRights +
      "/" +
      this.props.match.params.ipRightId;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        ipRight: response,
        loaded: true,
        baseUrl: `/portfolio/ipRights/${response.id}`,
      });
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "IP Rights",
          url: "/portfolio/ipRights",
        },
        {
          label: response.catchWord,
          url: null,
        },
      ];

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  };

  handleTabSelect = (event) => {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  };

  render() {
    const progress = () => {
      return (
        <ProgressTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const details = () => {
      return (
        <DetailsTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const classes = () => {
      return (
        <ClassesTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const parties = () => {
      return (
        <PartiesTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const countries = () => {
      return (
        <CountriesTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const documents = () => {
      return (
        <DocumentsTab
          ipRight={this.state.ipRight}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const wipo = this.state.ipRight?.wipo;
    let redirect = "/progress";

    if (wipo) {
      redirect = "/details";
    }

    const Tabs = (props) => {
      let tabs = [];
      let tabIndex = 1;

      if (!wipo) {
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"progress"}
            data-tab={tabIndex++}
            to={`${props.baseUrl}/progress`}
          >
            <Icon name="list-check" /> Progress
          </NavTab>,
        );
      }

      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"details"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/details`}
        >
          <Icon name="circle-info" /> Details
        </NavTab>,
        <NavTab
          onClick={this.handleTabSelect}
          key={"classes"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/classes`}
        >
          <Icon name="industry" /> Classes
        </NavTab>,
      );

      if (wipo) {
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"countries"}
            data-tab={tabIndex++}
            to={`${props.baseUrl}/countries`}
          >
            <Icon name="earth-americas" /> Countries
          </NavTab>,
        );
      }

      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"parties"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/parties`}
        >
          <Icon name="address-book" /> Parties
        </NavTab>,
        <NavTab
          onClick={this.handleTabSelect}
          key={"documents"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/documents`}
        >
          <Icon name="file-alt" /> Documents
        </NavTab>,
      );

      return tabs;
    };

    return (
      <Loader loaded={this.state.loaded}>
        <PageViewTracker session={this.props.session} />
        <IpRightHeader
          session={this.props.session}
          window={this.props.window}
          domain={this.state.domain}
          reloadIpRight={this.reload}
          ipRight={this.state.ipRight}
        />
        <PageTabsNew className={"nav nav-tabs"}>
          <Tabs ipRight={this.state.ipRight} baseUrl={this.state.baseUrl} />
        </PageTabsNew>
        <Switch>
          <Route
            exact
            path={`${this.state.baseUrl}`}
            key={"route-information-redirect-1"}
            render={() => (
              <Redirect replace to={`${this.state.baseUrl}${redirect}`} />
            )}
          />
          <Route
            path={`${this.state.baseUrl}/details`}
            component={details}
            key={"route-details"}
          />
          <Route
            path={`${this.state.baseUrl}/classes`}
            component={classes}
            key={"route-classes"}
          />
          <Route
            path={`${this.state.baseUrl}/parties`}
            component={parties}
            key={"route-parties"}
          />
          <Route
            path={`${this.state.baseUrl}/countries`}
            component={countries}
            key={"route-countries"}
          />
          <Route
            path={`${this.state.baseUrl}/documents`}
            component={documents}
            key={"route-documents"}
          />
          <Route
            path={`${this.state.baseUrl}/progress`}
            component={progress}
            key={"route-progress"}
          />
        </Switch>
      </Loader>
    );
  }
}

export default IpRightPage;

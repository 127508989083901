import React from "react";
import { Route, Switch } from "react-router-dom";
import CertificateList from "./CertificateList";
import usePageViewTracker from "../common/Tracking";

const CertAdmin = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path="/certadmin/certificates"
        render={(props) => (
          <CertificateList session={session} window={window} {...props} />
        )}
      />
    </Switch>
  );
};

export default CertAdmin;

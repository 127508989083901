import React, { Component } from "react";
import { SearchableFilter } from "./SearchableFilter";
import styled from "styled-components";

const FilterStyle = styled.span`
  .styled-filter-selector {
    width: 280px;
  }
`;

export default class SearchableOrganisationFilter extends Component {
  constructor(props) {
    super(props);
  }

  filterOrganisations(organisations) {
    let orgOptions = organisations.map((o) => {
      return {
        value: o.organisationId,
        label: o.description,
      };
    });
    return orgOptions;
  }

  getLoadOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.filterOrganisations;
    url += "?freetext=" + freetext;

    if (this.props.withId) {
      url += "&withId=true";
    }

    if (this.props.onlyClient) {
      url += "&onlyClient=true";
    }

    this.props.session.backendGet(url).then((response) => {
      callback(this.filterOrganisations(response));
    });
  };

  getStaticOptions = () => {
    return this.props.session.getApplication().organisations.map((o) => {
      return {
        value: o.organisationId,
        level: o.level,
        description: o.description,
      };
    });
  };

  render() {
    return (
      <FilterStyle>
        <SearchableFilter
          type="Organisations"
          session={this.props.session}
          onChange={this.props.onChange}
          hideExternal={this.props.hideExternal}
          staticOptions={this.getStaticOptions}
          loadOptions={this.getLoadOptions}
          initialTitle={this.props.initialTitle}
        />
      </FilterStyle>
    );
  }
}

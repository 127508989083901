import React, { Component } from "react";
import { Col, Container, Form, FormGroup, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  BlockingActionButton,
  BlockingMainActionButton,
  NavigationButton,
  SubmitFormButton,
} from "./Buttons";
import Colors from "./theme/Colors";
import { ProductDivider } from "./theme/Theme";
import { PortsAlert } from "../common/PortsAlert";

const PrettyModal = styled(Modal)`
  .modal-content {
    .modal-header {
      color: rgb(89, 91, 95);
      text-transform: uppercase;
      text-align: left;
      border-bottom: none;
      padding-left: 24px;
      padding-right: 24px;

      .modal-title {
        color: ${Colors.tertiary};
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1.5px;
      }
    }

    .modal-footer {
      border-top: none;
      padding: 12px 24px;
    }
  }

  .modal-dialog {
    font-family: "Libre Franklin", sans-serif;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-left: 0px;
    padding-right: 0px;
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .row > * {
    padding-left: 0px;
    padding-right: 0px;
  }

  .height-sm {
    height: 30vh;
  }

  .height-md {
    height: 50vh;
  }

  .height-lg {
    height: 70vh;
  }

  .modal-body {
    max-height: 70vh;
    overflow: auto !important;
    padding: 12px 24px;
  }

  .modal-body info {
    margin-top: 20px;
  }

  .modal-body table {
    margin-bottom: 10px;
  }

  .modal-body img {
    max-width: 700px;
  }

  .modal-body .section-title {
    color: ${Colors.tertiary};
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  .modal-body .section-title-space {
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  .modal-body .section-message {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.tertiary};
  }

  .modal-body .section-message svg {
    vertical-align: middle;
    margin-right: 20px;
  }

  .modal-body .section-description {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
    color: ${Colors.tertiary};
  }

  .modal-body .section-field {
    margin-bottom: 5px;
  }

  .modal-body .section-field-space {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .modal-body .section-field-indent {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  textarea {
    width: 100%;
    border-color: ${Colors.border};
    resize: none;
  }

  .download-link {
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.tertiary};
  }

  .modal-body .check-label {
    padding-bottom: 5px;
    margin-top: 20px;
    font-weight: 400;
  }

  .modal-body .check-label input {
    vertical-align: baseline;
  }
`;

const FormatMessage = styled.div`
  white-space: pre-wrap;
`;

const StyledSectionDiv = styled.div`
  &.section-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.section-item-inline {
    display: inline-block;
  }
`;

class MessageScroller extends Component {
  getMessage(object) {
    if (object == null) {
      return "";
    } else if (!Array.isArray(object)) {
      return object;
    } else {
      return object.reduce(
        (accumulator, currentValue) =>
          accumulator + this.getMessage(currentValue),
        "",
      );
    }
  }

  scroll(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (this.getMessage(this.props.message)) {
      this.scroll(this.props.elementId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.getMessage(this.props.message) &&
      this.getMessage(this.props.message) != this.getMessage(prevProps.message)
    ) {
      this.scroll(this.props.elementId);
    }
  }

  render() {
    return null;
  }
}

class InternalEditDialog extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getAlert(errorMessage) {
    if (errorMessage) {
      return (
        <Wrapper>
          <PortsAlert variant="danger">
            <FormatMessage>{errorMessage}</FormatMessage>
          </PortsAlert>
        </Wrapper>
      );
    }
  }

  getModalClassName = (modalClassName, height) => {
    let className = "";

    if (height) {
      className += " height-" + height;
    }

    if (modalClassName) {
      className += " " + modalClassName;
    }

    return className;
  };

  getNoteMessages(noteMessages) {
    if (noteMessages == null || noteMessages.length === 0) {
      return null;
    }
    let items = [];
    for (const element of noteMessages) {
      items.push(
        <Wrapper>
          <PortsAlert
            key="alert-warning"
            variant="warning"
            style={{ marginTop: "20px", marginBottom: "0px" }}
          >
            <FormatMessage>
              <strong>Note:</strong> {element}
            </FormatMessage>
          </PortsAlert>
        </Wrapper>,
      );
    }
    return items;
  }

  getDescription(description) {
    if (description) {
      return (
        <Container fluid={true}>
          <Row className="section-description">
            <Col>{description}</Col>
          </Row>
        </Container>
      );
    }
  }

  render() {
    let cancelButton = null;
    if (this.props.onCancel) {
      cancelButton = (
        <NavigationButton
          onClick={this.props.onCancel}
          text="Cancel"
          type={"button"}
        />
      );
    }
    return (
      <PrettyModal
        show={this.props.show}
        size={this.props.width}
        className={this.props.className}
        style={this.props.style}
      >
        <Form onSubmit={this.onSubmit}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <ProductDivider />
          <Modal.Body
            id="modal-body"
            className={this.getModalClassName(
              this.props.modalClassName,
              this.props.height,
            )}
          >
            {this.getAlert(this.props.errorMessage)}
            {this.getDescription(this.props.description)}
            {this.props.body}
            {this.getNoteMessages(this.props.noteMessages)}
          </Modal.Body>
          <Modal.Footer style={{ textAlign: "right" }}>
            {this.props.customButton}
            {cancelButton}
            <SubmitFormButton
              text={
                this.props.onConfirmLabel ? this.props.onConfirmLabel : "Save"
              }
              submit={this.props.submit}
            />
          </Modal.Footer>
        </Form>
        <MessageScroller
          elementId="modal-body"
          message={this.props.errorMessage}
        />
      </PrettyModal>
    );
  }

  onSubmit(e) {
    this.props.onConfirm();
    e.preventDefault();
  }
}

class EditDialog extends Component {
  wrapBody = (body) => {
    return <Container fluid={true}>{body}</Container>;
  };

  render() {
    const body = this.props.content.map((element) => (
      <FormGroup
        key={"FormDialog-" + (element.props.name || element.props.title)}
        data-class={element.props.name}
        controlId="formHorizontalAccount"
      >
        <Col sm={12} key={"Formgroup-elements-wrapper"}>
          {element}
        </Col>
      </FormGroup>
    ));
    return (
      <InternalEditDialog
        submit={this.props.submit}
        body={this.wrapBody(body)}
        errorMessage={this.props.errorMessage}
        title={this.props.title}
        description={this.props.description}
        onCancel={this.props.onCancel}
        onConfirm={this.props.onConfirm}
        onConfirmLabel={this.props.onConfirmLabel}
        width={this.props.width}
        height={this.props.height}
        show={this.props.show}
        className={this.props.className}
        style={this.props.style}
        noteMessages={this.props.noteMessages}
        customButton={this.props.customButton}
      />
    );
  }
}

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmConf: { showConfirm: false, message: null, onConfirm: null },
    };
  }

  // Below is used by Window
  handleUpdate(newState) {
    this.setState(newState);
  }

  render() {
    const body = (
      <FormatMessage>{this.state.confirmConf.message}</FormatMessage>
    );
    return (
      <InternalEditDialog
        body={body}
        title="Confirmation"
        onConfirmLabel="Proceed"
        show={this.state.confirmConf.showConfirm}
        onConfirm={this.state.confirmConf.onConfirm}
        {...this.props}
      />
    );
  }
}

class MessageDialog extends Component {
  render() {
    let body = this.props.body;
    if (this.props.message) {
      body = <FormatMessage>{this.props.message}</FormatMessage>;
    }

    return (
      <Dialog
        show={this.props.show}
        title={this.props.title}
        description={this.props.description}
        width={this.props.width}
        height={this.props.height}
        body={body}
        onClose={this.props.onClose}
        footer={this.props.footer}
      />
    );
  }
}

class FooterWrapper extends Component {
  render() {
    const containerStyle = this.props.isLeftAlignedToolbar
      ? { overflow: "auto" }
      : { width: "fit-content" };
    return (
      <Container fluid={true} style={containerStyle}>
        <Row>
          <Col>{this.props.children}</Col>
        </Row>
      </Container>
    );
  }
}

class Wrapper extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row>
          <Col>{this.props.children}</Col>
        </Row>
      </Container>
    );
  }
}

class Dialog extends Component {
  getErrorMessage(errorMessage) {
    if (errorMessage == null || errorMessage === "") {
      return null;
    }

    return (
      <Wrapper>
        <PortsAlert variant="danger" id="error">
          <FormatMessage>{errorMessage}</FormatMessage>
        </PortsAlert>
      </Wrapper>
    );
  }

  getErrorMessages(errorMessages) {
    if (errorMessages == null || errorMessages.length === 0) {
      return null;
    }

    let items = [];
    for (const element of errorMessages) {
      items.push(
        <Wrapper>
          <PortsAlert key="alert-danger" variant="danger">
            <FormatMessage>{element}</FormatMessage>
          </PortsAlert>
        </Wrapper>,
      );
    }

    return items;
  }

  getWarningMessages(warningMessages) {
    if (warningMessages == null || warningMessages.length === 0) {
      return null;
    }
    let items = [];
    for (const element of warningMessages) {
      items.push(
        <Wrapper>
          <PortsAlert key="alert-warning" variant="warning">
            <FormatMessage>{element}</FormatMessage>
          </PortsAlert>
        </Wrapper>,
      );
    }
    return items;
  }

  getNoteMessages(noteMessages) {
    if (noteMessages == null || noteMessages.length === 0) {
      return null;
    }
    let items = [];
    for (const element of noteMessages) {
      items.push(
        <Wrapper>
          <PortsAlert
            key="alert-warning"
            variant="warning"
            style={{ marginTop: "20px", marginBottom: "0px" }}
          >
            <FormatMessage>
              <strong>Note:</strong> {element}
            </FormatMessage>
          </PortsAlert>
        </Wrapper>,
      );
    }
    return items;
  }

  getDescription(description) {
    if (description) {
      return (
        <Container fluid={true}>
          <Row className="section-description">
            <Col>{description}</Col>
          </Row>
        </Container>
      );
    }
  }

  getActionsAndFilters = () => {
    if (this.props.actions && this.props.filters) {
      return (
        <Container fluid={true} style={{ marginBottom: "20px" }}>
          <Row>
            <Col xs={6}>{this.props.actions}</Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              {this.props.filters}
            </Col>
          </Row>
        </Container>
      );
    }
    if (this.props.actions) {
      return (
        <Container fluid={true} style={{ marginBottom: "20px" }}>
          {this.props.actions}
        </Container>
      );
    }
    if (this.props.filters) {
      return (
        <Container
          fluid={true}
          style={{ marginBottom: "20px", textAlign: "right" }}
        >
          {this.props.filters}
        </Container>
      );
    }
  };

  getFooter = (footer, onClose, onCloseDisabled, isLeftAlignedToolbar) => {
    let result = null;
    if (onClose) {
      result = this.getNavigationButtons(onClose, onCloseDisabled);
    } else {
      result = footer;
    }
    return (
      <FooterWrapper isLeftAlignedToolbar={isLeftAlignedToolbar}>
        {result}
      </FooterWrapper>
    );
  };

  getNavigationButtons = (onClose, onCloseDisabled) => {
    return (
      <NavigationButton
        key="close"
        text="Close"
        onClick={() => onClose()}
        disabled={onCloseDisabled}
        type={"button"}
      />
    );
  };

  getModalClassName = (modalClassName, height) => {
    let className = "";

    if (height) {
      className += " height-" + height;
    }

    if (modalClassName) {
      className += " " + modalClassName;
    }

    return className;
  };

  render() {
    const modalBodyStyle = this.props.scrollToBottom
      ? { display: "flex", flexDirection: "column-reverse" }
      : {};

    return (
      <PrettyModal
        show={this.props.show}
        size={this.props.width ? this.props.width : "md"}
      >
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <ProductDivider />
        <Modal.Body
          id="modal-body"
          className={this.getModalClassName(
            this.props.modalClassName,
            this.props.height,
          )}
          style={modalBodyStyle}
        >
          {this.getActionsAndFilters()}
          {this.getErrorMessage(this.props.errorMessage)}
          {this.getErrorMessages(this.props.errorMessages)}
          {this.getWarningMessages(this.props.warningMessages)}
          {this.getDescription(this.props.description)}
          {this.props.body}
          {this.getNoteMessages(this.props.noteMessages)}
        </Modal.Body>
        <Modal.Footer>
          {this.getFooter(
            this.props.footer,
            this.props.onClose,
            this.props.onCloseDisabled,
            this.props.isLeftAlignedToolbar,
          )}
        </Modal.Footer>
        <MessageScroller
          elementId="modal-body"
          message={
            new Array(
              this.props.errorMessage,
              this.props.errorMessages,
              this.props.warningMessages,
            )
          }
        />
      </PrettyModal>
    );
  }
}

class WizardDialog extends Component {
  render() {
    let buttons = [];

    if (this.props.onCancel) {
      buttons.push(
        <NavigationButton
          key="cancel"
          onClick={this.props.onCancel}
          text="Cancel"
        />,
      );
    }

    if (this.props.onPrevious) {
      buttons.push(
        <BlockingActionButton
          key="prev"
          onClick={this.props.onPrevious}
          text={"Previous"}
          reload={
            this.props.reload ? this.props.reload : this.props.errorMessage
          }
        />,
      );
    }

    if (this.props.onNext) {
      buttons.push(
        <BlockingMainActionButton
          key="next"
          onClick={this.props.onNext}
          text={"Next"}
          reload={
            this.props.reload ? this.props.reload : this.props.errorMessage
          }
        />,
      );
    }

    if (this.props.onCreate) {
      buttons.push(
        <BlockingMainActionButton
          key="create"
          onClick={this.props.onCreate}
          text={
            this.props.onCreateLabel ? this.props.onCreateLabel : "Place Order"
          }
          reload={
            this.props.reload ? this.props.reload : this.props.errorMessage
          }
          disabled={
            this.props.onCreateDisabled ? this.props.onCreateDisabled : false
          }
          disabledTooltip={
            this.props.onCreateDisabledTooltip
              ? this.props.onCreateDisabledTooltip
              : ""
          }
        />,
      );
    }

    if (this.props.onClose) {
      buttons.push(
        <NavigationButton
          key="close"
          onClick={this.props.onClose}
          text={"Close"}
          reload={this.props.errorMessage}
        />,
      );
    }

    return (
      <Dialog
        filters={this.props.filters}
        actions={this.props.actions}
        show={this.props.show}
        title={this.props.title}
        description={this.props.description}
        modalClassName={this.props.modalClassName}
        errorMessage={this.props.errorMessage}
        errorMessages={this.props.errorMessages}
        warningMessages={this.props.warningMessages}
        body={this.props.body}
        noteMessages={this.props.noteMessages}
        footer={buttons}
        height={this.props.height}
        width={this.props.width}
      />
    );
  }
}

class DetailsDialog extends Component {
  render() {
    return (
      <Dialog
        actions={this.props.actions}
        filters={this.props.filters}
        onClose={this.props.onClose}
        onCloseDisabled={this.props.onCloseDisabled}
        show={this.props.show}
        title={this.props.title}
        description={this.props.description}
        modalClassName={this.props.modalClassName}
        errorMessage={this.props.errorMessage}
        errorMessages={this.props.errorMessages}
        warningMessages={this.props.warningMessages}
        body={this.props.body}
        noteMessages={this.props.noteMessages}
        footer={this.props.footer}
        height={this.props.height}
        width={this.props.width}
      />
    );
  }
}

class SearchDialog extends Component {
  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        show={this.props.show}
        title={this.props.title}
        body={this.props.body}
        footer={this.props.footer}
        height={this.props.height}
        width={this.props.width}
        scrollToBottom={true}
        isLeftAlignedToolbar={true}
      />
    );
  }
}

class DialogSection extends Component {
  render() {
    let sectionItemClassName = "section-item";
    let sectionTitleClassName = "section-title";

    if (this.props.inline) {
      sectionItemClassName += " section-item-inline";
    }

    if (this.props.className) {
      sectionTitleClassName += " " + this.props.className;
    }

    return (
      <StyledSectionDiv className={sectionItemClassName}>
        {this.props.title ? (
          <div className={sectionTitleClassName}>{this.props.title}</div>
        ) : (
          ""
        )}
        <div>{this.props.children}</div>
      </StyledSectionDiv>
    );
  }
}

export {
  EditDialog,
  ConfirmDialog,
  MessageDialog,
  SearchDialog,
  WizardDialog,
  DetailsDialog,
  DialogSection,
};
export default EditDialog;

import React, { Component } from "react";
import { UserTagSelect } from "../../common/Selector";
import EditDialog, { MessageDialog } from "../../common/Dialog";

export default class UserTagDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTagIds: props.currentTagIds,
      errorMessage: null,
      submit: false,
    };
  }

  onSelectChange = (selectedTagIds) => {
    this.setState({
      selectedTagIds: selectedTagIds,
    });
  };

  getBody = () => {
    let options = this.props.options;
    if (!options || options.length === 0) {
      return [
        <div>
          There is no tag configured or you don't have enough privilege to
          access the tags.
        </div>,
      ];
    }

    return [
      <UserTagSelect
        allTags={this.props.options}
        currentTagIds={this.props.currentTagIds}
        onSelectChange={this.onSelectChange}
      />,
    ];
  };

  onClose = () => {
    this.setState({
      selectedTagIds: [],
      errorMessage: null,
      submit: false,
    });
    this.props.onClose();
  };

  onSave = () => {
    this.setState({ submit: true, errorMessage: null });
    this.props.session.backendPost(
      this.props.url,
      {
        tagIds: this.state.selectedTagIds,
      },
      () => {
        this.onClose();
        this.props.onSave();
        const message = "Tags are updated successfully";
        this.props.window.showSuccessFunc(message);
      },
      (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      },
    );
  };

  render() {
    if (this.props.options === null) {
      return;
    }
    if (this.props.options.length === 0) {
      return (
        <MessageDialog
          show={this.props.show}
          title="Edit Tags"
          description="There are no tags of type privilege available to restrict the user by."
          onClose={this.onClose}
        />
      );
    }
    let description =
      "Select the tags the user should be restricted by. " +
      "If no tags are selected that means the user is not restricted by tags. " +
      "Only tags of type privilege can restrict users.";
    return (
      <EditDialog
        session={this.props.session}
        content={this.getBody()}
        show={this.props.show}
        title="Edit Tags"
        description={description}
        onCancel={this.onClose}
        onConfirm={this.onSave}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import { MainActionButton, TableButton } from "../../common/Buttons";
import { InternalLink } from "../../common/Link";
import TagDialog from "./TagDialog";
import {
  getDisplayModeComponent,
  getDisplayModeDropdownFilter,
  getTypeDropdownFilter,
} from "../../common/TagUtil";
import styled from "styled-components";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 2px;
  }
`;
export default class TagList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        freetext: "",
        displayMode: "ALL",
        tagType: "ALL",
        reloadFlag: false,
      },
      showTagDialog: false,
      conf: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Tags",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().useradmin.links.tags;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&displayModeFilter=" + parameters.displayMode;
    url += "&tagTypeFilter=" + parameters.tagType;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    url += "&organisationIdFilter=" + parameters.organisationId;
    return url;
  };

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  getDisplayModeDropdownButton = () => {
    let onClick = (displayMode) => {
      this.setState({
        parameters: Object.assign({}, this.state.parameters, {
          displayMode: displayMode,
        }),
      });
    };
    return getDisplayModeDropdownFilter(
      this.state.parameters.displayMode,
      onClick,
    );
  };

  getTagTypeDropdownButton = () => {
    let onClick = (type) => {
      this.setState({
        parameters: Object.assign({}, this.state.parameters, {
          tagType: type,
        }),
      });
    };
    return getTypeDropdownFilter(this.state.parameters.tagType, onClick);
  };

  onCreate = () => {
    let confUrl =
      this.props.session.getModules().useradmin.links.tags + "/_/configuration";
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showTagDialog: true });
    });
  };

  closeDialog = () => {
    this.setState({
      showTagDialog: false,
      conf: null,
    });
  };

  openConfirmDialog = (tag) => {
    const message =
      "Are you sure you want to remove the tag " + tag.name + " ?";
    this.props.window.showConfirmFunc(message, () => this.onDelete(tag));
  };

  onDelete = (tag) => {
    const url = tag.links.self;
    const successMessage = "Tag " + tag.name + " is removed successfully!";
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    const successFun = () => {
      this.props.window.showSuccessFunc(successMessage);
      this.reloadPage();
    };

    this.props.session.backendDelete(url, successFun, errorFun);
  };

  handleSubmit = () => {
    this.closeDialog();
    this.reloadPage();
  };

  getButtons = (r) => {
    let elements = [];
    elements.push(
      <TableButton
        key="remove"
        onClick={() => this.openConfirmDialog(r)}
        icon="trash-alt"
        prefix={"fas"}
        text="Remove"
        className={"remove"}
      />,
    );

    return elements;
  };

  getActions = () => {
    let actions = [];
    actions.push(
      <MainActionButton
        key="create-tag"
        onClick={() => this.onCreate()}
        text="Create Tag"
        icon="plus"
        prefix="fas"
      />,
    );
    return actions;
  };

  getFilters = () => {
    let filters = [];
    filters.push(
      <SearchableOrganisationFilter
        session={this.props.session}
        onlyClient="true"
        hideExternal="true"
        withId="true"
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              organisationId: o,
            }),
          })
        }
      />,
    );
    filters.push(this.getDisplayModeDropdownButton());
    filters.push(this.getTagTypeDropdownButton());
    filters.push(
      <TableFilter
        key="freeText"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    );
    return filters;
  };

  getColumns = () => {
    let columns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
        contentFunction: (r) => (
          <InternalLink
            to={"/useradmin/tags/" + r.id}
            text={r.name}
            className="table-link"
          />
        ),
      },
      {
        label: "Display Mode",
        name: "displayMode",
        contentFunction: (r) =>
          getDisplayModeComponent(r.displayMode, r.displayModeDescription),
      },
      {
        label: "Cascade Mode",
        name: "cascadeModeDescription",
      },
      {
        label: "Tag Type",
        name: "tagTypeDescription",
      },
    ];

    if (this.props.session.hasRole("SYS_ADMIN")) {
      columns.push({
        label: "Organisation",
        name: "organisation",
      });
    }

    columns.push({
      style: { width: "150px" },
      contentFunction: (r) => <div>{this.getButtons(r)}</div>,
    });
    return columns;
  };

  render() {
    return (
      <PageStyle>
        <TableBackend
          id="tag"
          session={this.props.session}
          window={this.props.window}
          actions={this.getActions()}
          columns={this.getColumns()}
          filters={this.getFilters()}
          sorting="name"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <TagDialog
          session={this.props.session}
          window={this.props.window}
          showDialog={this.state.showTagDialog}
          closeDialog={this.closeDialog}
          handleSubmit={this.handleSubmit}
          conf={this.state.conf}
          create={true}
        />
      </PageStyle>
    );
  }
}

import Colors from "./theme/Colors";
import Icon, { UpdateCommentsIcon } from "./theme/Icons";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "./Buttons";
import { Tag } from "./theme/Theme";

export const getDisplayModeColor = (displayMode) => {
  switch (displayMode) {
    case "GREY":
      return Colors.tagGreyBackground;
    case "LIGHT_GREY":
      return Colors.tagLightGreyBackground;
    case "RED":
      return Colors.tagRedBackground;
    case "LIGHT_RED":
      return Colors.tagLightRedBackground;
    case "ORANGE":
      return Colors.tagOrangeBackground;
    case "LIGHT_ORANGE":
      return Colors.tagLightOrangeBackground;
    case "YELLOW":
      return Colors.tagYellowBackground;
    case "LIGHT_YELLOW":
      return Colors.tagLightYellowBackground;
    case "GREEN":
      return Colors.tagGreenBackground;
    case "LIGHT_GREEN":
      return Colors.tagLightGreenBackground;
    case "BLUE":
      return Colors.tagBlueBackground;
    case "LIGHT_BLUE":
      return Colors.tagLightBlueBackground;
    case "CYAN":
      return Colors.tagCyanBackground;
    case "LIGHT_CYAN":
      return Colors.tagLightCyanBackground;
    case "HIDDEN":
      return Colors.tagHiddenBackground;
    default:
      return Colors.tagHiddenBackground;
  }
};

export const getDisplayModeTextColor = (displayMode) => {
  switch (displayMode) {
    case "HIDDEN":
    case "GREY":
    case "RED":
    case "ORANGE":
    case "GREEN":
    case "BLUE":
    case "CYAN":
      return Colors.white;
    default:
      return Colors.black;
  }
};

export const getTagsForHeader = (
  sysAdmin,
  allTags,
  objectTags,
  readOnly,
  buttonTooltips,
  buttonFunc,
) => {
  let contents = [];
  if (objectTags && objectTags.length > 0) {
    objectTags.forEach((tag) => {
      contents.push(
        <Tag
          className={"tag"}
          textColor={getDisplayModeTextColor(tag.displayMode)}
          background={getDisplayModeColor(tag.displayMode)}
        >
          {tag.name}
        </Tag>,
      );
      contents.push(" ");
    });
  } else {
    contents.push("(no tags) ");
  }

  if (!readOnly && (sysAdmin || (allTags && allTags.length > 0))) {
    contents.push(
      <UpdateCommentsIcon onClick={buttonFunc} title={buttonTooltips} />,
    );
  }
  return contents;
};

export const getTagOptions = (tags, labelOnlyIncluded, visibleOnly) => {
  if (tags) {
    return tags
      .filter((tag) => labelOnlyIncluded || !tag.labelOnly)
      .filter((tag) => !visibleOnly || tag.visible === visibleOnly);
  }
  return [];
};

const allDisplayModes = [
  { mode: "GREY", label: "Grey" },
  { mode: "LIGHT_GREY", label: "Light Grey" },
  { mode: "RED", label: "Red" },
  { mode: "LIGHT_RED", label: "Light Red" },
  { mode: "ORANGE", label: "Orange" },
  { mode: "LIGHT_ORANGE", label: "Light Orange" },
  { mode: "YELLOW", label: "Yellow" },
  { mode: "LIGHT_YELLOW", label: "Light Yellow" },
  { mode: "GREEN", label: "Green" },
  { mode: "LIGHT_GREEN", label: "Light Green" },
  { mode: "BLUE", label: "Blue" },
  { mode: "LIGHT_BLUE", label: "Light Blue" },
  { mode: "CYAN", label: "Cyan" },
  { mode: "LIGHT_CYAN", label: "Light Cyan" },
  { mode: "HIDDEN", label: "Hidden" },
];

export const getDisplayModeDropdownFilter = (displayMode, onClick) => {
  let options = [];
  options.push({ mode: "ALL", label: "All Display Modes" });
  allDisplayModes.forEach((o) => options.push(o));

  let items = [];
  options.forEach((option) => {
    items.push(
      <Dropdown.Item onClick={() => onClick(option.mode)}>
        {getDisplayModeOptionLabel(option)}
      </Dropdown.Item>,
    );
  });
  const currentOption = options.filter(
    (option) => option.mode === displayMode,
  )[0];
  return (
    <DropdownButton
      id="displayMode"
      key="displayMode"
      title={getDisplayModeOptionLabel(currentOption)}
      style={{ verticalAlign: "top" }}
    >
      {items}
    </DropdownButton>
  );
};

export const getDisplayModeOptionLabel = (displayModeOption) => {
  if (displayModeOption.mode === "ALL") {
    return displayModeOption.label;
  }
  return getDisplayModeComponent(
    displayModeOption.mode,
    displayModeOption.label,
  );
};

export const getDisplayModeComponent = (mode, label) => {
  return (
    <span>
      {getDisplayModeIcon(mode)}
      {label}
    </span>
  );
};

export const getDisplayModeIcon = (mode) => {
  const color = getDisplayModeColor(mode);
  return (
    <Icon
      color={color}
      name="square"
      prefix={"fas"}
      paddingright={"5"}
      style={{ color: color }}
    />
  );
};

export const getTypeDropdownFilter = (type, onClick) => {
  const options = ["ALL", "LABEL", "PRIVILEGE"];
  let items = [];
  options.forEach((option) => {
    items.push(
      <Dropdown.Item onClick={() => onClick(option)}>
        {getTypeOptionLabel(option)}
      </Dropdown.Item>,
    );
  });
  return (
    <DropdownButton
      id="type"
      key="type"
      title={getTypeOptionLabel(type)}
      style={{ verticalAlign: "top" }}
    >
      {items}
    </DropdownButton>
  );
};

export const getTypeOptionLabel = (type) => {
  if (type === "LABEL") {
    return "Label Tags";
  } else if (type === "PRIVILEGE") {
    return "Privilege Tags";
  } else {
    return "All Types";
  }
};

export const getTypeTooltip = (tagType) => {
  if (tagType === "LABEL") {
    return "Tags of type 'Label' only tags objects, like IP rights, domains and services, they have no other function.";
  } else if (tagType === "PRIVILEGE") {
    return "Tags of type 'Privilege' can tag objects, like IP rights, domains and services, and they can also restrict users access to tagged objects, when users are configured to be limited by tags.";
  } else {
    return "Controls how the tag operates. Tags of type 'Label' only tags objects, like IP rights, domains and services, they have no other function. Tags of type 'Privilege' can tag objects, like IP rights, domains and services, and they can also restrict users access to tagged objects, when users are configured to be limited by tags.";
  }
};

export const getCascadeModeTooltip = (mode) => {
  if (mode === "ALWAYS") {
    return "Tags of cascade mode 'Always' propagate tag changes: from IP rights to services; from domains to related zones, certificates, and services; and from zones to services.";
  } else if (mode === "NEVER") {
    return "Tags of cascade mode 'Never' keep changes limited to the current object.";
  } else {
    return "Controls automatic tagging of related objects. 'Always' propagates tag changes: from IP rights to services; from domains to related zones, certificates, and services; and from zones to services. 'Never' keeps changes limited to the current object.";
  }
};

export const getDisplayModeTooltip = (mode, label) => {
  if (mode === "ALL") {
    return "Controls tag visibility and color. Choose a color to display the tag across all pages, in that color. Select 'Hidden' to hide it on rights and services pages, this is useful when you only want to control privileges with this tag.";
  } else if (mode === "HIDDEN") {
    return "Tags of display mode 'Hidden' are not visible on rights and services, this is useful when you only want to control privileges with this tag.";
  } else {
    return label;
  }
};

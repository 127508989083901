import React, { Component } from "react";
import styled from "styled-components";
import EditDialog from "../../common/Dialog";
import Selector from "../../common/Selector";
import { PortsAlert } from "../../common/PortsAlert";
import { withTracking } from "../../common/Tracking";

const StyledDiv = styled.div`
  .alert {
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;

class UserRoleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      value: [],
      submit: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.showDialog !== this.props.showDialog ||
      prevProps.url !== this.props.url
    ) {
      this.load();
    }
  }

  load = () => {
    this.setState({ conf: null, value: [], errorMessage: null });
    if (this.props.showDialog && this.props.url) {
      this.props.session.backendGet(this.props.url, (response) => {
        this.setState({
          conf: response,
          value: response.object.roles.map((o) => o.value),
        });
      });
    }
  };

  save = () => {
    const url = this.state.conf.object.links.roles;
    this.setState({ submit: true });
    this.props.session.backendPut(
      url,
      this.state.value,
      () => {
        this.props.closeDialog();
        this.setState({ submit: false });
        this.props.window.showSuccessFunc("Roles updated successfully!");
        this.props.trackEvent(this.props.session, "user_roles", "edited");
      },
      (errorMessage) => {
        this.setState({ submit: false, errorMessage: errorMessage });
      },
    );
  };

  onChange = (value) => {
    this.setState({ value: value.map((o) => o.value) });
  };

  render() {
    const elements = [];

    if (!this.state.conf) {
      elements.push(<div>Loading, please wait...</div>);
    } else {
      elements.push(
        <StyledDiv>
          <Selector
            isMulti
            ignoreAccents={false}
            required={true}
            options={this.state.conf.roles}
            defaultValue={this.state.conf.object.roles}
            onChange={this.onChange}
            placeholder="Select Roles"
          />

          <PortsAlert variant="warning">
            <strong>Note:</strong> Any role that is not necessary because it is
            implied by other roles (for example DNS Admin Edit implies DNS Admin
            View), are automatically removed on save.
          </PortsAlert>
        </StyledDiv>,
      );
    }

    return (
      <EditDialog
        show={this.props.showDialog}
        title="Edit Roles"
        content={elements}
        onCancel={this.props.closeDialog}
        onConfirm={this.save}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
        height="sm"
      />
    );
  }
}

export default withTracking(UserRoleDialog);

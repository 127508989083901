import React, { Component } from "react";
import { MessageDialog } from "./MessageDialog";
import { SearchMessageDialog } from "./SearchMessageDialog";
import styled from "styled-components";
import { darken, rgba } from "polished";
import Colors from "../common/theme/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProjectMessageComponent } from "./ProjectMessageComponent";

const Label = styled.span`
  display: block;
  font-size: 0.85em;
  color: ${rgba(Colors.black, 0.5)};
  text-align: center;
`;

const Buttons = styled.div`
  text-align: center;
  vertical-align: middle;
`;

const IconButton = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
    margin-top: 10px;

    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
    }
  }
`;

export class ProjectMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      showSearchMessageDialog: false,
      showMessageDialog: false,
      submit: false,
      errorMessage: null,
    };
  }
  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.props.session.backendGet(
      this.props.url,
      (response) => {
        this.setState({ submit: false, messages: response });
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  getButtons = () => {
    let buttons = [];
    const tooltipSearch = (
      <Tooltip style={{ position: "fixed" }}>Open Search View</Tooltip>
    );
    const tooltipApprove = (
      <Tooltip style={{ position: "fixed" }}>Approve the above message</Tooltip>
    );
    const tooltipDecline = (
      <Tooltip style={{ position: "fixed" }}>Decline the above message</Tooltip>
    );
    const tooltipCreate = (
      <Tooltip style={{ position: "fixed" }}>Send message</Tooltip>
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipSearch} placement={"bottom"}>
        <IconButton
          icon={["fas", "search"]}
          onClick={this.onOpenSearchMessageDialog}
        />
      </OverlayTrigger>,
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipApprove} placement={"bottom"}>
        <IconButton icon={["fas", "thumbs-up"]} onClick={this.onApprove} />
      </OverlayTrigger>,
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipDecline} placement={"bottom"}>
        <IconButton icon={["fas", "thumbs-down"]} onClick={this.onDecline} />
      </OverlayTrigger>,
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipCreate} placement={"bottom"}>
        <IconButton icon={["fas", "pen"]} onClick={this.onOpenMessageDialog} />
      </OverlayTrigger>,
    );
    return <Buttons>{buttons}</Buttons>;
  };

  onOpenSearchMessageDialog = () => {
    this.setState({
      showSearchMessageDialog: true,
    });
  };

  onCloseSearchMessageDialog = () => {
    this.setState({
      showSearchMessageDialog: false,
    });
  };

  onApprove = () => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_APPROVE",
        content: [
          {
            text: null,
          },
        ],
      },
      this.reload,
      (msg) => {
        this.setState({ submit: false });
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  onDecline = () => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_DECLINE",
        content: [
          {
            text: null,
          },
        ],
      },
      this.reload,
      (msg) => {
        this.setState({ submit: false });
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  onOpenMessageDialog = () => {
    this.setState({
      showMessageDialog: true,
    });
  };

  onCloseMessageDialog = () => {
    this.setState({
      showMessageDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  onSendMessage = (content) => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_COMMENT",
        content: [
          {
            text: content,
          },
        ],
      },
      () => {
        this.onCloseMessageDialog();
        this.reload();
      },
      (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      },
    );
  };

  getBody = () => {
    let body = [];
    let projectMessage = (
      <ProjectMessageComponent
        messages={this.state.messages}
        height="200px"
        overflow={"auto"}
      />
    );

    body.push(<Label>Messages</Label>);
    body.push(projectMessage);
    body.push(this.getButtons());
    return body;
  };

  render() {
    return (
      <div>
        {this.getBody()}
        <MessageDialog
          session={this.props.session}
          show={this.state.showMessageDialog}
          onCancel={this.onCloseMessageDialog}
          onSend={this.onSendMessage}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
        <SearchMessageDialog
          session={this.props.session}
          show={this.state.showSearchMessageDialog}
          body={this.state.messages}
          onClose={this.onCloseSearchMessageDialog}
          url={this.props.url}
        />
      </div>
    );
  }
}

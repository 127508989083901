import React, { Component } from "react";
import { BlockingActionButton } from "../common/Buttons";
import {
  FormatFloatWithFractionDigitsMonospace,
  FormatOrganisation,
} from "../common/Utils";
import { TableBase } from "../common/Tables";
import { WizardDialog } from "../common/Dialog";
import { getColumns, getCurrencyFilter } from "../common/InvoiceForecastUtils";
import fileDownload from "js-file-download";

export default class AccountForecastDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      currency: null,
      loading: true,
      currencies: [],
      forecast: null,
      exported: 0,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState(
        {
          url: this.props.url,
          currency: this.props.currency,
        },
        function () {
          this.reload();
        },
      );
    }
  }

  reload = () => {
    this.setState({ loading: true, currencies: [], forecast: null });
    let url = this.state.url + "?currency=" + this.state.currency;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        currencies: response.currencies,
        forecast: response,
        loading: false,
      });
    });
  };

  getTable = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <TableBase
          columns={getColumns(this.state.forecast, (r) => r.description)}
          rows={this.getTableRows()}
          footerRows={this.getFooterRows()}
        />
      );
    }
  };

  getTableRows = () => {
    let rows = [];
    if (this.state.forecast === null) {
      return rows;
    }

    const months = this.state.forecast.months;
    const services = this.state.forecast.services;

    if (!services) {
      return rows;
    }
    services.forEach((service) => {
      const serviceAmounts = service.amounts;
      let data = {
        description: service.description,
      };
      for (let i = 0; i < months.length; i++) {
        let key = months[i];
        data[key] = FormatFloatWithFractionDigitsMonospace(
          serviceAmounts[i],
          null,
          0,
        );
      }
      rows.push(data);
    });
    return rows;
  };

  getFooterRows = () => {
    let rows = [];
    if (this.state.forecast === null) {
      return rows;
    }
    const months = this.state.forecast.months;
    const services = this.state.forecast.services;
    const totalAmounts = this.state.forecast.amounts;
    const description = "Total (" + this.state.currency + "):";

    if (!services) {
      return rows;
    }
    let total = {
      description: description,
    };
    for (let i = 0; i < months.length; i++) {
      let key = months[i];
      total[key] = FormatFloatWithFractionDigitsMonospace(
        totalAmounts[i],
        null,
        0,
      );
    }
    rows.push(total);
    return rows;
  };

  getFilters = () => {
    const filters = [];
    const onClick = (currency) => {
      this.setState(
        {
          currency: currency,
        },
        function () {
          this.reload();
        },
      );
    };
    let filter = getCurrencyFilter(
      this.state.currencies,
      this.state.currency,
      onClick,
    );
    filters.push(filter);
    return filters;
  };

  getActionButtons = () => {
    let parts = [];
    parts.push(
      <BlockingActionButton
        key="ExportServices"
        onClick={this.onExport}
        text="Export Services"
        icon={"cloud-download"}
        prefix={"fas"}
        reload={this.state.exported}
        tooltip="Exports the services to an Excel file. Note that only rows matching current filter are exported."
      />,
    );
    return parts;
  };

  onClose = () => {
    this.setState({
      url: null,
      currency: null,
      currencies: [],
      forecast: null,
      errorMessage: null,
      exported: 0,
    });
    this.props.onClose();
  };

  onPrevious = () => {
    let currency = this.state.currency;
    this.setState({
      url: null,
      currency: null,
      currencies: [],
      forecast: null,
      errorMessage: null,
      exported: 0,
    });
    this.props.onPrevious(currency);
  };

  onExport = () => {
    if (!this.state.forecast) {
      return;
    }
    let url = this.state.forecast.links.export;
    url += "&currency=" + this.state.currency;
    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Invoice Forecast.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.setState({ exported: Date.now(), errorMessage: error });
      },
    );
  };

  getNotes = () => {
    let notes = [];
    notes.push(
      "The invoice forecast is calculated from your current portfolio and may change due to added/cancelled services, changed invoice preferences, price adjustments and currency rate changes. It doesn't include one off services such as consultancy or domain owner transfers.",
    );
    return notes;
  };

  render() {
    let title = "Invoice Forecast";
    if (this.state.forecast) {
      title =
        title +
        " - " +
        FormatOrganisation(
          this.state.forecast.organisation,
          this.props.session.getApplication().organisationPrefix,
        );
    }
    return (
      <WizardDialog
        show={this.props.show}
        title={title}
        actions={this.getActionButtons()}
        filters={this.getFilters()}
        body={this.getTable()}
        onClose={() => this.onClose()}
        onPrevious={() => this.onPrevious()}
        noteMessages={this.getNotes()}
        width="xl"
        height="lg"
      />
    );
  }
}

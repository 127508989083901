import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { ExternalLink } from "../../common/Link";
import fileDownload from "js-file-download";
import DOMPurify from "dompurify";

function NewlineText(props) {
  const text = props.text;
  return text.split("\n").map((str) => <p key={str}>{str}</p>);
}

export default class AgreementStep extends Component {
  getDisclamers = () => {
    let rows = [];

    for (const element of this.props.conf.parameters.agreements) {
      let template = element;

      if (template.content !== "") {
        rows.push(
          <Row className="section-field" style={{ paddingTop: "20px" }}>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(template.content),
                }}
              ></div>
            </Col>
          </Row>,
        );
      }
    }
    return rows;
  };

  getTemplates = () => {
    let rows = [];

    for (const element of this.props.conf.parameters.agreements) {
      let template = element;

      if (template.content === "") {
        rows.push(this.getTemplate(template));
      }
    }

    return rows;
  };

  getTemplate = (template) => {
    const url =
      this.props.session.getModules().order.links.createOrderAgreement +
      "/" +
      template.id;

    return (
      <Row key={"template-" + template.id} className="section-field">
        <Col xs={12}>
          <ExternalLink
            onClick={() =>
              this.props.session.backendPostFile(
                url,
                this.props.conf.object,
                (o) => this.onDownload(template.fileName, o),
              )
            }
            tooltip={"Open Agreement"}
            text={template.title}
            className={"download-link"}
          />
        </Col>
      </Row>
    );
  };

  onDownload = (fileName, fileBody) => {
    console.log("f: " + fileName);
    if (fileName == null) {
      window.open(new TextDecoder("utf-8").decode(fileBody), "_blank");
    } else {
      fileDownload(fileBody, fileName);
    }
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Agreements</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            You need to accept all the agreements listed below to proceed with
            the order.
          </Col>
        </Row>
        {this.getTemplates()}
        {this.getDisclamers()}
        <Row className="section-title">
          <Col xs={12}>Approval</Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <Selector
              label={"Accept All Agreements"}
              placeholder="Select yes to indicate you accept all above agreements"
              options={this.props.conf.parameters.agreementOptions}
              defaultValue={this.props.conf.object.parameters.agreementId}
              onChange={(v) =>
                this.props.onParameterChange("agreementId", v.value)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

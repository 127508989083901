import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProjectDetail from "./ProjectDetail";
import ProjectList from "./ProjectList";
import usePageViewTracker from "../common/Tracking";

const Project = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path={"/project"}
        render={() => <Redirect replace to={"/project/projects"} />}
      />
      <Route
        exact={true}
        path="/project/projects"
        render={(props) => (
          <ProjectList
            session={session}
            window={window}
            caseModule={false}
            {...props}
          />
        )}
      />
      <Route
        path="/project/projects/:projectId"
        render={(props) => (
          <ProjectDetail
            session={session}
            window={window}
            caseModule={false}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default Project;

import React, { Component } from "react";
import { FormGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import { MainActionButton } from "../../common/Buttons";
import { TabContent, TabControlPanel } from "../../common/TabContent";
import ZoneFileTextArea from "./ZoneFileTextArea.js";
import ZoneFileDialog from "./ZoneFileDialog.js";
import { withTracking } from "../../common/Tracking";

class ZoneFileTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneFile: null,
      zoneEditorOpen: false,
      showDialog: false,
      url: null,
      loaded: false,
      unsaved: false,
      submit: false,
    };
  }

  componentDidMount() {
    this.reloadZoneFile();
  }

  reloadZoneFile() {
    let url = this.props.zone.links.zoneFile;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        zoneFile: response.content,
        loaded: true,
      });
    });
  }

  save(zoneFile) {
    let data = zoneFile;
    let url = this.props.zone.links.zoneFile;
    this.setState({ submit: true });
    this.props.session.backendPost(
      url,
      {
        content: data,
      },
      (_) => {
        const message = "Zonefile was updated successfully!";
        this.props.window.showSuccessFunc(message);
        this.setState({ unsaved: false, submit: false, zoneEditorOpen: false });
        this.reloadZoneFile();
        this.props.onChange();
        this.props.trackEvent(this.props.session, "zone_file", "edited");
      },
      (response) => {
        this.setState({ submit: false });
        this.props.window.showErrorFunc(response);
      },
    );
  }

  openZoneEditor = () => {
    this.setState({ zoneEditorOpen: true });
  };

  closeZoneEditor = () => {
    this.setState({ zoneEditorOpen: false });
  };

  render() {
    return (
      <div>
        <ZoneFileDialog
          zoneFile={this.state.zoneFile}
          showDialog={this.state.zoneEditorOpen}
          onConfirm={(e) => this.save(e)}
          onCancel={() => this.closeZoneEditor()}
          submit={this.state.submit}
        />
        <TabControlPanel className="no-margin">
          <MainActionButton
            text={"Edit"}
            onClick={this.openZoneEditor}
            icon="edit"
            prefix={"fas"}
            disabled={
              !this.props.session.hasRole("DNS_ADMIN_EDIT", "DNS_ADMIN_PUBLISH")
            }
          />
        </TabControlPanel>
        <TabContent className="padded-none">
          <form>
            <FormGroup controlId="formHorizontalAccount" key={this.props.title}>
              <ZoneFileTextArea
                className="readonly"
                value={this.state.zoneFile}
                disabled={true}
              />
            </FormGroup>
          </form>
        </TabContent>
      </div>
    );
  }
}

export default withTracking(withRouter(ZoneFileTab));

const Colors = {
  // Base Colors
  black: "#000000",
  white: "#FFFFFF",
  primary: "#51CD75",
  secondary: "#31A7FF",
  tertiary: "#595B5F",
  border: "#e0e0e0",
  disabled: "#EEEEEE",
  link: "#337AB7",

  // Categories
  categoryLegal: "#5AA4DA",
  categoryDigital: "#60BE73",
  categoryTechnical: "#B2B1B2",
  categoryObp: "#ffb872",

  // Table, general div etc
  tableHoverBackground: "#F8F8F8",
  tableBackground: "#FFFFFF",
  recentlyUpdated: "#EAF2F8",

  // Buttons
  mainActionButtonBackground: "#51CD75",
  mainActionButton: "#FFFFFF",
  actionButtonBackground: "#31A7FF",
  actionButton: "#FFFFFF",
  navigationButtonBackground: "#31A7FF",
  navigationButton: "#FFFFFF",
  disabledButtonBackground: "#A9A9A9",
  disabledButton: "#FFFFFF",
  deleteButtonBackground: "#E74C3C",
  deleteButton: "#FFFFFF",
  tableNavigationButtonBackground: "#FFFFFF",
  tableNavigationButton: "#595B5F",
  tableNavigationButtonBorder: "#595B5F",

  //Navigation Bars
  topbarBackground: "#FFFFFF",
  bodyBackground: "#F2F2F2",
  sidebarBackground: "#595B5F",

  // warning, errors
  errorBackground: "#f2dede",
  errorBorder: "#ebccd1",
  error: "#e74c3c",
  warningBackground: "#fff3cd",
  warningBorder: "#e5d6a7",
  warning: "#FFA500",
  successBackground: "#51CD75",
  successBorder: "#60BE73",
  success: "#008000",
  failed: "#A9A9A9",
  alertBackground: "#FCF8E3",
  alertBorder: "#FAEBCC",
  alert: "#8A6D3B",
  dangerBackground: "#f2dede",
  dangerBorder: "#ebccd1",
  danger: "#a94442",
  inactive: "#D8D8D8",

  // search
  searchAvailableBackground: "#FFF3CD",
  searchAvailableBorder: "#E5D6A7",
  searchOccupiedBackground: "#F2DEDE",
  searchOccupiedBorder: "#EBCCD1",
  searchOwnedBackground: "#60BE7326",
  searchOwnedBorder: "#76BC84",

  // Risk
  riskGreenBackground: "#dff0d8",
  riskGreenBorder: "#d6e9c6",
  riskborder: "#d6d8db",
  riskYellowBackground: "#fff3cd",
  riskYellowBorder: "#e5d6a7",
  riskRedBackground: "#f2dede",
  riskRedBorder: "#ebccd1",
  riskBlackBackground: "#BDBDBD",
  riskBlackBorder: "#D6C8B2",
  riskBlueBackground: "#AEDFF7",
  riskBlueBorder: "#D4E8C8",
  riskPurpleBackground: "#DCCFFB",
  riskPurpleBorder: "#CBBFD6",

  //popup
  popupInfoBackground: "#FFA500",
  popupInfo: "#FFFFFF",
  popupSuccessBackground: "#51CD75",
  popupSuccess: "#FFFFFF",
  popupErrorBackground: "#E74C3C",
  popupError: "#FFFFFF",

  //message
  messageLeftBackground: "#e5e5ea",
  messageLeftText: "#000000",
  messageRightBackground: "#218aff",
  messageRightText: "#FFFFFF",

  //tag
  tagGreyBackground: "#A9A9A9",
  tagLightGreyBackground: "#DCDCDC",
  tagRedBackground: "#FF4500",
  tagLightRedBackground: "#FFA07A",
  tagOrangeBackground: "#FF8C00",
  tagLightOrangeBackground: "#FFBF00",
  tagYellowBackground: "#FFD700",
  tagLightYellowBackground: "#EEE8AA",
  tagGreenBackground: "#76BC84",
  tagLightGreenBackground: "#90EE90",
  tagBlueBackground: "#1E90FF",
  tagLightBlueBackground: "#89CFF0",
  tagCyanBackground: "#008B8B",
  tagLightCyanBackground: "#40E0D0",
  tagHiddenBackground: "#595B5F",

  //progress
  progressCompleteBackground: "#51CD75",
  progressCurrentBackground: "#FFA500",
  progressNoneBackground: "#A9A9A9",
};

export default Colors;

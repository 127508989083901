import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import { ActionButton } from "../../common/Buttons";
import { BulkTagObjectsDialog } from "./BulkTagObjectsDialog";

class TagObjectsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkTagObjectsDialogShow: false,
      bulkTagObjectsAction: null,
      bulkTagObjectsUrl: null,
      parameters: {
        freetext: "",
        reloadFlag: false,
      },
    };
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.getObjectsUrl;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  showAddBulkDialog = () => {
    this.setState({
      bulkTagObjectsDialogShow: true,
      bulkTagObjectsAction: "add",
      bulkTagObjectsUrl: this.props.addObjectsUrl,
    });
  };

  showRemoveBulkDialog = () => {
    this.setState({
      bulkTagObjectsDialogShow: true,
      bulkTagObjectsAction: "remove",
      bulkTagObjectsUrl: this.props.removeObjectsUrl,
    });
  };

  hideBulkDialog = () => {
    this.setState({
      bulkTagObjectsDialogShow: false,
      bulkTagObjectsAction: null,
      bulkTagObjectsUrl: null,
    });
  };

  submitBulkDialog = () => {
    this.hideBulkDialog();
    this.reloadPage();
  };

  render() {
    if (!this.props.tag) {
      return;
    }

    const addButtonText = "Bulk Add " + this.props.relationType;
    const removeButtonText = "Bulk Remove " + this.props.relationType;

    const actions = [
      <ActionButton
        key="bulk-add-objects"
        text={addButtonText}
        icon={"edit"}
        prefix={"fas"}
        onClick={this.showAddBulkDialog}
      />,
      <ActionButton
        key="bulk-remove-objects"
        text={removeButtonText}
        icon={"edit"}
        prefix={"fas"}
        onClick={this.showRemoveBulkDialog}
      />,
    ];

    const filters = [
      <TableFilter
        key="freeText"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    return (
      <div>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          filters={filters}
          columns={this.props.columns}
          sorting={this.props.sorting}
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
          actions={actions}
        />
        <BulkTagObjectsDialog
          session={this.props.session}
          window={this.props.window}
          onSubmit={this.submitBulkDialog}
          onClose={this.hideBulkDialog}
          show={this.state.bulkTagObjectsDialogShow}
          action={this.state.bulkTagObjectsAction}
          url={this.state.bulkTagObjectsUrl}
          relationType={this.props.relationType}
          idFieldName={this.props.idFieldName}
        />
      </div>
    );
  }
}

export default TagObjectsTab;

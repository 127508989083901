import React, { Component } from "react";
import { DialogSection, EditDialog } from "../../common/Dialog";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";
import { FormatInput, FormatOrganisation } from "../../common/Utils";

class OrganisationDialog extends Component {
  create;
  options = ["Company", "Person"];

  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      selectedOwnerType: null,
      selectedInvoiceType: null,
      showOwnerSection: false,
      showInvoiceSection: false,
      alwaysShowInvoiceSettings: false,
      invoiceCheckBoxOption: null,
      invoiceCheckBoxValue: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      prevProps.show !== this.props.show &&
      this.props.conf
    ) {
      this.create = this.props.conf.object.id === null;
      this.setState({
        conf: this.props.conf,
        selectedOwnerType: this.getSelectedType(
          this.props.conf.object.id === null,
          this.props.conf.object.ownerContactOrgName,
          this.props.conf.object.ownerContactPersonName,
        ),
        selectedInvoiceType: this.getSelectedType(
          this.props.conf.object.id === null,
          this.props.conf.object.invoiceContactOrgName,
          this.props.conf.object.invoiceContactPersonName,
        ),
        showOwnerSection: this.getShowOwnerSection(
          this.props.conf,
          this.getParameters(
            this.props.conf,
            this.props.conf.object.parentAccountId,
          ),
        ),
        showInvoiceSection: this.getShowInvoiceSection(
          this.props.conf,
          this.getParameters(
            this.props.conf,
            this.props.conf.object.parentAccountId,
          ),
        ),
        alwaysShowInvoiceSettings: this.getAlwaysShowInvoiceSettings(
          this.props.conf,
          this.getParameters(
            this.props.conf,
            this.props.conf.object.parentAccountId,
          ),
        ),
        invoiceCheckBoxOption: this.getInvoiceCheckBoxOption(
          this.props.conf,
          this.getParameters(
            this.props.conf,
            this.props.conf.object.parentAccountId,
          ),
        ),
        invoiceCheckBoxValue: this.getInvoiceCheckBoxValue(
          this.props.conf,
          this.getParameters(
            this.props.conf,
            this.props.conf.object.parentAccountId,
          ),
        ),
      });
    }
  }

  getSelectedType = (create, orgName, personName) => {
    if (create) {
      return "Company";
    }
    if (!orgName && !personName) {
      return "Company";
    } else if (!orgName) {
      return "Person";
    } else {
      return "Company";
    }
  };

  getParameters = (conf, id) => {
    if (id === null) {
      return null;
    }
    for (const element of conf.accounts) {
      if (element.value == id) {
        return element.parameters;
      }
    }
  };

  getShowOwnerSection = (conf, parameters) => {
    if (conf.object.id !== null) {
      return conf.ownerMandatory;
    }
    if (parameters === null) {
      return false;
    }
    return parameters["ownerMandatory"];
  };

  getShowInvoiceSection = (conf, parameters) => {
    if (conf.object.id !== null) {
      return (
        conf.invoiceMandatory ||
        conf.object.invoiceContactOrgName ||
        conf.object.invoiceContactPersonName
      );
    }
    if (parameters === null) {
      return false;
    }
    return parameters["invoiceMandatory"];
  };

  getInvoiceCheckBoxOption = (conf, parameters) => {
    if (conf.object.id !== null) {
      return conf.invoiceNullDefault;
    }
    if (parameters === null) {
      return null;
    }
    return parameters["invoiceNullDefault"];
  };

  getInvoiceCheckBoxValue = (conf, parameters) => {
    if (conf.object.id !== null) {
      return (
        conf.object.invoiceContactOrgName ||
        conf.object.invoiceContactPersonName
      );
    }
    return this.state.invoiceCheckBoxValue;
  };

  getAlwaysShowInvoiceSettings = (conf, parameters) => {
    if (conf.object.id !== null) {
      return conf.settingMandatory;
    }
    if (parameters === null) {
      return false;
    }
    return parameters["settingMandatory"];
  };

  updateField = (field, value) => {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  };

  updateFieldsToEmpty = (ownerOrInvoice, allFields) => {
    if (ownerOrInvoice === "owner") {
      this.updateField("ownerContactOrgName", "");
      this.updateField("ownerContactOrgNumber", "");
      this.updateField("ownerContactVatNumber", "");
      this.updateField("ownerContactPersonNumber", "");
      if (allFields) {
        this.updateField("ownerContactPersonName", "");
        this.updateField("ownerContactStreet1", "");
        this.updateField("ownerContactStreet2", "");
        this.updateField("ownerContactPostalCode", "");
        this.updateField("ownerContactCity", "");
        this.updateField("ownerContactStateProvince", "");
        this.updateField("ownerContactCountry", "");
        this.updateField("ownerContactPhone", "");
        this.updateField("ownerContactEmail", "");
      }
    }
    if (ownerOrInvoice === "invoice") {
      this.updateField("invoiceContactOrgName", "");
      this.updateField("invoiceContactOrgNumber", "");
      this.updateField("invoiceContactVatNumber", "");
      this.updateField("invoiceContactPersonNumber", "");
      if (allFields) {
        this.updateField("invoiceContactPersonName", "");
        this.updateField("invoiceContactStreet1", "");
        this.updateField("invoiceContactStreet2", "");
        this.updateField("invoiceContactPostalCode", "");
        this.updateField("invoiceContactCity", "");
        this.updateField("invoiceContactStateProvince", "");
        this.updateField("invoiceContactCountry", "");
        this.updateField("invoiceContactPhone", "");
        this.updateField("invoiceContactEmail", "");
        this.updateField("invoiceContactSecondaryEmail", "");
      }
    }
  };

  onChangeOrganisation = (id) => {
    this.updateField("parentAccountId", id);
    let parameters = this.getParameters(this.state.conf, id);
    this.setState({
      showOwnerSection: this.getShowOwnerSection(this.state.conf, parameters),
      showInvoiceSection: this.getShowInvoiceSection(
        this.state.conf,
        parameters,
      ),
      alwaysShowInvoiceSettings: this.getAlwaysShowInvoiceSettings(
        this.state.conf,
        parameters,
      ),
      invoiceCheckBoxOption: this.getInvoiceCheckBoxOption(
        this.state.conf,
        parameters,
      ),
      invoiceCheckBoxValue: this.getInvoiceCheckBoxValue(
        this.state.conf,
        parameters,
      ),
    });
  };

  onFieldChange = (field) => {
    switch (field) {
      case "organisation":
        return (e) => {
          this.onChangeOrganisation(e.value);
        };
      case "ownerContactCountry":
        return (e) => {
          this.updateField("ownerContactCountry", e.value);
        };
      case "invoiceContactCountry":
        return (e) => {
          this.updateField("invoiceContactCountry", e.value);
        };
      case "invoiceCurrency":
        return (e) => {
          this.updateField("invoiceCurrency", e.value);
        };
      case "invoiceLanguage":
        return (e) => {
          this.updateField("invoiceLanguage", e.value);
        };
      case "useCredits":
        return (e) => {
          this.updateField("useCredits", e.value);
        };
      default:
        return (e) => {
          this.updateField(field, e.target.value);
        };
    }
  };

  onOwnerTypeChange = (changeEvent) => {
    this.updateFieldsToEmpty("owner", false);
    this.setState({
      selectedOwnerType: changeEvent.target.value,
    });
  };

  onInvoiceTypeChange = (changeEvent) => {
    this.updateFieldsToEmpty("invoice", false);
    this.setState({
      selectedInvoiceType: changeEvent.target.value,
    });
  };

  ownerTypeChecked = (value) => {
    return !!(
      this.state.selectedOwnerType && this.state.selectedOwnerType === value
    );
  };

  invoiceTypeChecked = (value) => {
    return !!(
      this.state.selectedInvoiceType && this.state.selectedInvoiceType === value
    );
  };

  onInvoiceCheckChange = (changeEvent) => {
    this.updateFieldsToEmpty("invoice", true);
    this.setState({
      invoiceCheckBoxValue: changeEvent.target.checked,
      showInvoiceSection: changeEvent.target.checked,
    });
  };

  getOwnerSection = () => {
    let section = [];
    if (!this.state.showOwnerSection) {
      return section;
    }
    section.push(
      <Row key="owner" name="owner_row">
        <Col md={12}>
          <DialogSection title="Owner" />
        </Col>
      </Row>,
    );

    if (this.create) {
      section.push(
        <Row key="radio" name="radio_row">
          <Col md={6}>
            <div className="radio" tabIndex="3">
              <label>
                <input
                  type="radio"
                  value={FormatInput(this.options[0])}
                  onChange={this.onOwnerTypeChange}
                  checked={this.ownerTypeChecked(this.options[0])}
                />
                {this.options[0]}
              </label>
              &nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  value={FormatInput(this.options[1])}
                  onChange={this.onOwnerTypeChange}
                  checked={this.ownerTypeChecked(this.options[1])}
                />
                {this.options[1]}
              </label>
            </div>
          </Col>
        </Row>,
      );
    }

    let company = this.state.selectedOwnerType === this.options[0];

    section.push(
      <Row key="info" name="info_row">
        <Col md={6}>
          <DialogSection title="Company Info">
            <InputWithLabel
              label={"Company Name"}
              placeholder="Enter company name here"
              name={"ownerContactOrgName"}
              onChange={this.onFieldChange("ownerContactOrgName")}
              value={FormatInput(this.state.conf.object["ownerContactOrgName"])}
              disabled={!company}
              tabIndex="4"
            />
            <InputWithLabel
              label={"Organisation Number"}
              placeholder="Enter organisation number here"
              name={"ownerContactOrgNumber"}
              onChange={this.onFieldChange("ownerContactOrgNumber")}
              value={FormatInput(
                this.state.conf.object["ownerContactOrgNumber"],
              )}
              disabled={!company || !this.create}
              tabIndex="4"
            />
            <InputWithLabel
              label={"VAT Number"}
              placeholder="Enter VAT number here"
              name={"ownerContactVatNumber"}
              onChange={this.onFieldChange("ownerContactVatNumber")}
              value={FormatInput(
                this.state.conf.object["ownerContactVatNumber"],
              )}
              disabled={!company}
              tabIndex="4"
            />
          </DialogSection>
        </Col>
        <Col md={6}>
          <DialogSection title="Person Info">
            <InputWithLabel
              label={"Person Name"}
              placeholder="Enter person name here"
              name={"ownerContactPersonName"}
              onChange={this.onFieldChange("ownerContactPersonName")}
              value={FormatInput(
                this.state.conf.object["ownerContactPersonName"],
              )}
              tabIndex="5"
            />
            <InputWithLabel
              label={"Personal Number"}
              placeholder="Enter personal number here"
              name={"ownerContactPersonNumber"}
              onChange={this.onFieldChange("ownerContactPersonNumber")}
              value={FormatInput(
                this.state.conf.object["ownerContactPersonNumber"],
              )}
              disabled={company}
              tabIndex="5"
            />
          </DialogSection>
        </Col>
      </Row>,
    );

    section.push(
      <Row key="address_contact_info" name="address_contact_info_row">
        <Col md={6}>
          <DialogSection title="Address Info">
            <InputWithLabel
              label={"Street 1"}
              placeholder="Enter street here"
              name={"ownerContactStreet1"}
              onChange={this.onFieldChange("ownerContactStreet1")}
              value={FormatInput(this.state.conf.object["ownerContactStreet1"])}
              tabIndex="6"
            />
            <InputWithLabel
              label={"Street 2"}
              placeholder="Enter street here"
              name={"ownerContactStreet2"}
              onChange={this.onFieldChange("ownerContactStreet2")}
              value={FormatInput(this.state.conf.object["ownerContactStreet2"])}
              tabIndex="6"
            />
            <InputWithLabel
              label={"Postal Code"}
              placeholder="Enter postal code here"
              name={"ownerContactPostalCode"}
              onChange={this.onFieldChange("ownerContactPostalCode")}
              value={FormatInput(
                this.state.conf.object["ownerContactPostalCode"],
              )}
              tabIndex="6"
            />
            <InputWithLabel
              label={"City"}
              placeholder="Enter city here"
              name={"ownerContactCity"}
              onChange={this.onFieldChange("ownerContactCity")}
              value={FormatInput(this.state.conf.object["ownerContactCity"])}
              tabIndex="6"
            />
            <InputWithLabel
              label={"State / Province"}
              placeholder="Enter state or province here"
              name={"ownerContactStateProvince"}
              onChange={this.onFieldChange("ownerContactStateProvince")}
              value={FormatInput(
                this.state.conf.object["ownerContactStateProvince"],
              )}
              tabIndex="6"
            />
            <Selector
              label={"Country"}
              placeholder="Select country"
              options={this.state.conf.countries}
              onChange={this.onFieldChange("ownerContactCountry")}
              defaultValue={FormatInput(
                this.state.conf.object["ownerContactCountry"],
              )}
              tabIndex="6"
            />
          </DialogSection>
        </Col>
        <Col md={6}>
          <DialogSection title="Contact Info">
            <InputWithLabel
              label={"E-mail"}
              placeholder="Enter e-mail address here"
              name={"ownerContactEmail"}
              onChange={this.onFieldChange("ownerContactEmail")}
              value={FormatInput(this.state.conf.object["ownerContactEmail"])}
              tabIndex="7"
            />
            <InputWithLabel
              label={"Phone"}
              placeholder="Enter phone number here"
              name={"ownerContactPhone"}
              onChange={this.onFieldChange("ownerContactPhone")}
              value={FormatInput(this.state.conf.object["ownerContactPhone"])}
              tabIndex="7"
            />
          </DialogSection>
        </Col>
      </Row>,
    );
    return section;
  };

  getInvoiceSection = () => {
    let section = [];
    if (
      !this.state.showInvoiceSection &&
      !this.state.alwaysShowInvoiceSettings &&
      this.state.invoiceCheckBoxOption === null
    ) {
      return section;
    }
    section.push(
      <Row key="invoice_receiver" name="invoice_receiver">
        <Col md={12}>
          <DialogSection title="Invoice Receiver" />
        </Col>
      </Row>,
    );

    if (this.state.invoiceCheckBoxOption !== null) {
      let tooltip;
      if (this.state.invoiceCheckBoxOption === "owner") {
        tooltip = (
          <Tooltip style={{ position: "fixed" }}>
            Leaving it unchecked, invoices will be sent to the owner address
            above
          </Tooltip>
        );
      } else {
        tooltip = (
          <Tooltip style={{ position: "fixed" }}>
            Leaving it unchecked, invoices will be sent to the parent
            organisation invoice receiver address
          </Tooltip>
        );
      }

      section.push(
        <div className="check" tabIndex="8">
          <OverlayTrigger overlay={tooltip} placement="right" delay={500}>
            <label className="check-label">
              <input
                type="checkbox"
                onChange={this.onInvoiceCheckChange}
                checked={this.state.invoiceCheckBoxValue}
              />
              &nbsp;Use separate invoice address
            </label>
          </OverlayTrigger>
        </div>,
      );
    }

    if (this.state.showInvoiceSection) {
      section.push(
        <div className="radio" tabIndex="9">
          <label>
            <input
              type="radio"
              value={FormatInput(this.options[0])}
              onChange={this.onInvoiceTypeChange}
              checked={this.invoiceTypeChecked(this.options[0])}
            />
            {this.options[0]}
          </label>
          &nbsp;&nbsp;
          <label>
            <input
              type="radio"
              value={FormatInput(this.options[1])}
              onChange={this.onInvoiceTypeChange}
              checked={this.invoiceTypeChecked(this.options[1])}
            />
            {this.options[1]}
          </label>
        </div>,
      );

      let company = this.state.selectedInvoiceType === this.options[0];

      section.push(
        <Row key="company_person" name="company_person">
          <Col md={6}>
            <DialogSection title="Company Info">
              <InputWithLabel
                label={"Company Name"}
                placeholder="Enter company name here"
                name={"invoiceContactOrgName"}
                onChange={this.onFieldChange("invoiceContactOrgName")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactOrgName"],
                )}
                disabled={!company}
                tabIndex="10"
              />
              <InputWithLabel
                label={"Organisation Number"}
                placeholder="Enter organisation number here"
                name={"invoiceContactOrgNumber"}
                onChange={this.onFieldChange("invoiceContactOrgNumber")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactOrgNumber"],
                )}
                disabled={!company}
                tabIndex="10"
              />
              <InputWithLabel
                label={"VAT Number"}
                placeholder="Enter VAT number here"
                name={"invoiceContactVatNumber"}
                onChange={this.onFieldChange("invoiceContactVatNumber")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactVatNumber"],
                )}
                disabled={!company}
                tabIndex="10"
              />
            </DialogSection>
          </Col>
          <Col md={6}>
            <DialogSection title="Person Info">
              <InputWithLabel
                label={"Person Name"}
                placeholder="Enter person name here"
                name={"invoiceContactPersonName"}
                onChange={this.onFieldChange("invoiceContactPersonName")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactPersonName"],
                )}
                tabIndex="11"
              />
              <InputWithLabel
                label={"Personal Number"}
                placeholder="Enter personal number here"
                name={"invoiceContactPersonNumber"}
                onChange={this.onFieldChange("invoiceContactPersonNumber")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactPersonNumber"],
                )}
                disabled={company}
                tabIndex="11"
              />
            </DialogSection>
          </Col>
        </Row>,
      );
      section.push(
        <Row key="address_contact_info" name="address_contact_info">
          <Col md={6}>
            <DialogSection title="Address Info">
              <InputWithLabel
                label={"Street 1"}
                placeholder="Enter street here"
                name={"invoiceContactStreet1"}
                onChange={this.onFieldChange("invoiceContactStreet1")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactStreet1"],
                )}
                tabIndex="12"
              />
              <InputWithLabel
                label={"Street 2"}
                placeholder="Enter street here"
                name={"invoiceContactStreet2"}
                onChange={this.onFieldChange("invoiceContactStreet2")}
                value={this.state.conf.object["invoiceContactStreet2"]}
                tabIndex="12"
              />
              <InputWithLabel
                label={"Postal Code"}
                placeholder="Enter postal code here"
                name={"invoiceContactPostalCode"}
                onChange={this.onFieldChange("invoiceContactPostalCode")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactPostalCode"],
                )}
                tabIndex="12"
              />
              <InputWithLabel
                label={"City"}
                placeholder="Enter city here"
                name={"invoiceContactCity"}
                onChange={this.onFieldChange("invoiceContactCity")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactCity"],
                )}
                tabIndex="12"
              />
              <InputWithLabel
                label={"State / Province"}
                placeholder="Enter state or province here"
                name={"invoiceContactStateProvince"}
                onChange={this.onFieldChange("invoiceContactStateProvince")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactStateProvince"],
                )}
                tabIndex="12"
              />
              <Selector
                label={"Country"}
                placeholder="Select country"
                options={this.state.conf.countries}
                onChange={this.onFieldChange("invoiceContactCountry")}
                defaultValue={this.state.conf.object["invoiceContactCountry"]}
                tabIndex="12"
              />
            </DialogSection>
          </Col>
          <Col md={6}>
            <DialogSection title="Contact Info">
              <InputWithLabel
                label={"E-mail"}
                placeholder="Enter e-mail address here"
                name={"invoiceContactEmail"}
                onChange={this.onFieldChange("invoiceContactEmail")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactEmail"],
                )}
                tabIndex="13"
              />
              <InputWithLabel
                label={"Secondary E-mail"}
                placeholder="Enter secondary e-mail here"
                name={"invoiceContactSecondaryEmail"}
                onChange={this.onFieldChange("invoiceContactSecondaryEmail")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactSecondaryEmail"],
                )}
                tabIndex="13"
              />
              <InputWithLabel
                label={"Phone"}
                placeholder="Enter phone number here"
                name={"invoiceContactPhone"}
                onChange={this.onFieldChange("invoiceContactPhone")}
                value={FormatInput(
                  this.state.conf.object["invoiceContactPhone"],
                )}
                tabIndex="13"
              />
            </DialogSection>
          </Col>
        </Row>,
      );
    }
    if (this.state.alwaysShowInvoiceSettings || this.state.showInvoiceSection) {
      section.push(
        <Row>
          <Col md={6}>
            <DialogSection title="Invoice Settings">
              {this.addCredits()}
              <Selector
                label={"Invoice Currency"}
                placeholder="Select currency on the invoices"
                options={this.state.conf.invoiceCurrencies}
                onChange={this.onFieldChange("invoiceCurrency")}
                defaultValue={this.state.conf.object["invoiceCurrency"]}
                tabIndex="14"
              />
              <Selector
                label={"Invoice Language"}
                placeholder="Select preferred currency on the invoices"
                options={this.state.conf.invoiceLanguages}
                onChange={this.onFieldChange("invoiceLanguage")}
                defaultValue={this.state.conf.object["invoiceLanguage"]}
                tabIndex="15"
              />
            </DialogSection>
          </Col>
        </Row>,
      );
    }

    return section;
  };

  addCredits = () => {
    if (this.state.conf.creditsOptionAvailable) {
      const useCreditOptions = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];

      return (
        <Selector
          label={"Use Credits"}
          placeholder="Use credits as payment option"
          options={useCreditOptions}
          onChange={this.onFieldChange("useCredits")}
          defaultValue={this.state.conf.object["useCredits"]}
          tabIndex="13"
        />
      );
    }
  };

  getContent = () => {
    let body = [];
    if (this.create) {
      let accountOptions = this.state.conf.accounts.map((o) => {
        return {
          value: o.value,
          label: FormatOrganisation(
            o.label,
            this.props.session.getApplication().organisationHierarchyPrefix,
          ),
        };
      });
      body.push(
        <Row key="parent_organisation" name="parent_organisation_row">
          <Col md={12}>
            <Selector
              label={"Parent Organisation"}
              placeholder="Select organisation"
              options={accountOptions}
              onChange={this.onFieldChange("organisation")}
              defaultValue={this.state.conf.object["parentAccountId"]}
            />
          </Col>
        </Row>,
      );
    } else if (this.state.conf.object["parentOrganisation"]) {
      body.push(
        <Row key="parent_organisation" name="parent_organisation_row">
          <Col md={12}>
            <InputWithLabel
              label={"Parent Organisation"}
              name={"parentOrganisation"}
              value={FormatInput(this.state.conf.object["parentOrganisation"])}
              disabled={true}
              tabIndex="1"
            />
          </Col>
        </Row>,
      );
    }

    body.push(
      <Row key="description" name="description_row">
        <Col md={12}>
          <InputWithLabel
            label={"Description"}
            placeholder="Enter organisation description here"
            name={"accountDescription"}
            onChange={this.onFieldChange("accountDescription")}
            value={FormatInput(this.state.conf.object["accountDescription"])}
            tabIndex="2"
          />
        </Col>
      </Row>,
    );

    this.getOwnerSection().forEach((element) => body.push(element));
    this.getInvoiceSection().forEach((element) => body.push(element));

    return body;
  };

  onCancel = () => {
    this.setState({
      conf: null,
      create: null,
      selectedOwnerType: null,
      selectedInvoiceType: null,
      showOwnerSection: false,
      showInvoiceSection: false,
      alwaysShowInvoiceSettings: false,
      invoiceCheckBoxOption: null,
      invoiceCheckBoxValue: null,
    });
    this.props.onCancel();
  };

  render() {
    if (!this.state.conf) {
      return null;
    }

    let title = this.create ? "Create Organisation" : "Edit Organisation";

    return (
      <EditDialog
        show={this.props.show}
        title={title}
        content={this.getContent()}
        onCancel={this.onCancel}
        onConfirm={this.props.onConfirm}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
        height="lg"
        width="lg"
        name="orgainisation-details"
      />
    );
  }
}

export default OrganisationDialog;

import React, { Component } from "react";
import { ObjectTagSelect } from "./Selector";
import EditDialog, { MessageDialog } from "./Dialog";

export default class UpdateObjectTagsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toAdd: [],
      toRemove: [],
      errorMessage: null,
      submit: false,
    };
  }

  onSelectChange = (toAdd, toRemove) => {
    this.setState({
      toAdd: toAdd,
      toRemove: toRemove,
    });
  };

  getBody = () => {
    return [
      <ObjectTagSelect
        allTags={this.props.options}
        currentTagIds={this.props.currentTagIds}
        onSelectChange={this.onSelectChange}
      />,
    ];
  };

  onClose = () => {
    this.setState({
      toAdd: [],
      toRemove: [],
      errorMessage: null,
      submit: false,
    });
  };

  onCancel = () => {
    this.onClose();
    this.props.onClose();
  };

  onSave = () => {
    this.setState({ submit: true, errorMessage: null });
    this.props.session.backendPost(
      this.props.url,
      {
        tagIdsToAdd: this.state.toAdd,
        tagIdsToRemove: this.state.toRemove,
      },
      () => {
        this.onClose();
        this.props.onSave();
        const message = "Tags are updated successfully";
        this.props.window.showSuccessFunc(message);
      },
      (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      },
    );
  };

  render() {
    if (this.props.options === null) {
      return;
    }
    if (this.props.options.length === 0) {
      const description =
        "There are no tags available to active on the " +
        this.props.objectType +
        ".";
      return (
        <MessageDialog
          show={this.props.show}
          title="Update Tags"
          description={description}
          onClose={this.onCancel}
        />
      );
    }
    const description =
      "Select the tags that should be active on the " +
      this.props.objectType +
      ".";
    return (
      <EditDialog
        session={this.props.session}
        content={this.getBody()}
        show={this.props.show}
        title="Update Tags"
        description={description}
        onCancel={this.onCancel}
        onConfirm={this.onSave}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

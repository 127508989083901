import React, { Component } from "react";
import styled from "styled-components";
import { UpdateCommentsIcon } from "../common/theme/Icons";
import { RiskSpan } from "./RiskUtil";

const RiskComponentStyle = styled(RiskSpan)`
  .risk {
    width: 20px;
    height: 20px;
    margin: 0px;
  }

  .risk-image svg {
    margin-left: 10px;
    margin-bottom: 4px;
  }
`;

export default class RiskComponent extends Component {
  render() {
    return (
      <RiskComponentStyle>
        <div className="risk-image">
          <div className={"risk item item-" + this.props.value} />
          <UpdateCommentsIcon
            onClick={() => this.props.onEdit()}
            title="Update risk for this hit"
          />
        </div>
      </RiskComponentStyle>
    );
  }
}

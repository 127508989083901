import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import { FormatFloatMonospace, FormatOrganisation } from "../../common/Utils";
import styled from "styled-components";
import {
  ActionButton,
  MainActionButton,
  TableButton,
} from "../../common/Buttons";
import OrganisationDialog from "./OrganisationDialog";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";
import { withTracking } from "../../common/Tracking";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class OrganisationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: { organisationId: "", freetext: "", reloadFlag: false },
      showOrganisationDialog: false,
      conf: null,
      submit: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Organisations",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().useradmin.links.accounts;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  onRemove = (row) => {
    const message = (
      <div>Are you sure you want to remove the organisation?</div>
    );
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(
        url,
        () => {
          this.props.window.showSuccessFunc(
            "Organisation successfully deleted",
          );
          this.reloadPage();
          this.props.trackEvent(this.props.session, "organisation", "deleted");
        },
        errorFun,
      );
    });
  };

  onEdit = (row) => {
    const url = row.links.configuration;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        conf: response,
        showOrganisationDialog: true,
        submit: false,
      });
    });
  };

  onCreate = () => {
    const url =
      this.props.session.getModules().useradmin.links.organisationConfiguration;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        conf: response,
        showOrganisationDialog: true,
        submit: false,
      });
    });
  };

  onCreateOrder = () => {
    this.setState({ showCreateOrderDialog: true });
  };

  closeDialog = () => {
    this.setState({
      conf: null,
      showOrganisationDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  onCloseOrderDialog = () => {
    this.setState({ showCreateOrderDialog: false });
    this.reloadPage();
  };

  onSubmit = () => {
    this.setState({ submit: true });
    const successCreateFun = () => {
      this.closeDialog();
      const message = "Organisation successfully created";
      this.props.window.showSuccessFunc(message);
      this.props.session.backendGet(
        "api/rest",
        (response) => {
          this.props.session.setApplication(response);
          this.setState({ submit: false });
          this.reloadPage();
          this.props.trackEvent(this.props.session, "organisation", "created");
        },
        (errorMessage) => {
          this.setState({
            loaded: true,
            submit: false,
            errorMessage: errorMessage,
          });
        },
      );
    };
    const successEditFun = () => {
      this.closeDialog();
      const message = "Organisation successfully edited";
      this.props.window.showSuccessFunc(message);
      this.props.session.backendGet(
        "api/rest",
        (response) => {
          this.props.session.setApplication(response);
          this.reloadPage();
          this.props.trackEvent(this.props.session, "organisation", "edited");
        },
        (errorMessage) => {
          this.setState({
            loaded: true,
            submit: false,
            errorMessage: errorMessage,
          });
        },
      );
    };
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };

    if (this.state.conf.object.id) {
      this.setState({ errorMessage: null });
      const url = this.state.conf.object.links.self;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ errorMessage: null });
      const url = this.props.session.getModules().useradmin.links.accounts;
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successCreateFun,
        errorFun,
      );
    }
  };

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  getButtons = (r) => {
    let elements = [];
    elements.push(
      <TableButton
        key="edit"
        onClick={() => this.onEdit(r)}
        icon="edit"
        prefix={"fas"}
        text="Edit"
        className={"secondary"}
      />,
    );
    if (this.props.session.getApplication().agent && r.removable) {
      elements.push(
        <TableButton
          key="remove"
          onClick={() => this.onRemove(r)}
          icon="trash-alt"
          prefix={"fas"}
          text="Remove"
          className="remove"
        />,
      );
    }

    return elements;
  };

  render() {
    let actions = [];

    if (this.props.session.getApplication().agent) {
      actions.push(
        <MainActionButton
          key="create-organisation"
          onClick={() => this.onCreate()}
          text="Create Organisation"
          icon="plus"
          prefix="fas"
          tooltip="Open the organisation dialog and fill in details to create a new organisation."
        />,
      );
    }

    if (this.props.session.getApplication().creditsOptionAvailable) {
      actions.push(
        <ActionButton
          key="purchase-credits"
          onClick={() => this.onCreateOrder()}
          text="Purchase Credits"
          icon="plus"
          prefix="fas"
          tooltip="Purchase credits to receive 5% discount on everything you order and pay with credits."
        />,
      );
    }

    const filters = [
      <StyledDiv key="filter-div">
        <SearchableOrganisationFilter
          key="organisationFilter"
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                organisationId: o,
              }),
            })
          }
        />
        ,
        <TableFilter
          key="table-filter"
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        style: { width: "400px" },
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationHierarchyPrefix,
          ),
      },
      {
        label: "Category",
        name: "category",
      },
      {
        label: "Owner",
        name: "ownerContactDescription",
      },
      {
        label: "Invoice Receiver",
        name: "invoiceContactDescription",
      },
      {
        label: "Credits",
        name: "credits",
        contentFunction: (r) => FormatFloatMonospace(r.credits, "Credits"),
        showColumnIfAnyRowPredicate: (row) => row.useCredits || row.credits > 0,
      },
      {
        style: { width: "220px" },
        contentFunction: (r) => <div>{this.getButtons(r)}</div>,
      },
    ];

    return (
      <PageStyle>
        <TableBackend
          id="organisation"
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="organisation"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <OrganisationDialog
          session={this.props.session}
          window={this.props.window}
          conf={this.state.conf}
          show={this.state.showOrganisationDialog}
          onCancel={this.closeDialog}
          onConfirm={this.onSubmit}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateOrderDialog}
          step={"OPERATION"}
          operation={"CREDITS"}
          onClose={this.onCloseOrderDialog}
        />
      </PageStyle>
    );
  }
}
export default withTracking(OrganisationList);

import React, { Component } from "react";
import { MainActionButton, TableButton } from "../../common/Buttons";
import { TableFrontend } from "../../common/Tables";
import RedirectDialog from "./RedirectDialog";
import { withTracking } from "../../common/Tracking";

class RedirectTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
      submit: null,
      errorMessage: null,
    };
    this.onCreate = this.onCreate.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  handleSubmit() {
    const successFun = () => {
      this.closeDialog();
      const message = "Redirect successfully added!";
      this.props.window.showSuccessFunc(message);
      this.setState({ submit: false });
      this.props.onChange();
      this.props.trackEvent(this.props.session, "zone_redirect", "created");
    };
    const successEditFun = () => {
      this.closeDialog();
      const message = "Redirect successfully edited!";
      this.props.window.showSuccessFunc(message);
      this.setState({ submit: false });
      this.props.onChange();
      this.props.trackEvent(this.props.session, "zone_redirect", "edited");
    };
    const errorFun = (msg) => {
      this.setState({ submit: null, errorMessage: msg });
    };
    if (this.state.conf.object.id) {
      this.setState({ submit: true });
      const url = this.state.conf.object.links.self;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ submit: true });
      const url = this.props.zone.links.redirects;
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    }
  }

  onCreate() {
    let confUrl = this.props.zone.links.redirectConfiguration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onEdit(row) {
    let confUrl = row.links.configuration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onRemove(row) {
    const message = (
      <div>
        Are you sure you want to remove the redirect?
        <br />
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {row.redirectType.label + ":\n"}
          {row.source} → {row.destination}
        </pre>
      </div>
    );
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(url, (_) => {
        this.props.window.showSuccessFunc("Redirect successfully deleted");
        this.props.onChange();
      });
    });
  }

  closeDialog() {
    this.setState({ showDialog: false, submit: false, errorMessage: null });
  }

  render() {
    const columns = [
      {
        label: "Type",
        name: "type",
        sortable: true,
        filterable: true,
        contentFunction: (r) => r.redirectType.label,
        sortFunction: (r) => r.redirectType.label,
        filterFunction: (r) => r.redirectType.label,
      },
      {
        label: "Source",
        name: "source",
        sortable: true,
        filterable: true,
      },
      {
        label: "Destination",
        name: "destination",
        sortable: true,
        filterable: true,
      },
      {
        label: "Managed Certificate",
        name: "certificate",
        contentFunction: (r) => (r.certificate ? "Yes" : "No"),
      },
      {
        style: { width: "220px" },
        contentFunction: (r) =>
          !this.props.session.hasRole(
            "DNS_ADMIN_EDIT",
            "DNS_ADMIN_PUBLISH",
          ) ? null : (
            <div>
              <TableButton
                onClick={() => this.onEdit(r)}
                icon="edit"
                prefix={"fas"}
                text="Edit"
                className={"secondary"}
              />
              <TableButton
                onClick={() => this.onRemove(r)}
                icon="trash-alt"
                prefix={"fas"}
                text="Remove"
                className="remove"
              />
            </div>
          ),
      },
    ];

    const url = this.props.zone.links.redirects;
    const actions = !this.props.session.hasRole(
      "DNS_ADMIN_EDIT",
      "DNS_ADMIN_PUBLISH",
    )
      ? null
      : [
          <MainActionButton
            key="create-redirect"
            onClick={this.onCreate}
            icon="plus"
            prefix={"fas"}
            text="Create Redirect"
          />,
        ];
    const { showDialog, conf, submit, errorMessage } = this.state;
    const { session, window } = this.props;

    if (this.props.zone.redirectService) {
      return (
        <div>
          <RedirectDialog
            session={session}
            showDialog={showDialog}
            closeDialog={this.closeDialog}
            conf={conf}
            createUrl={url}
            handleSubmit={this.handleSubmit}
            submit={submit}
            errorMessage={errorMessage}
          />
          <TableFrontend
            id="zone.redirect"
            session={session}
            window={window}
            actions={actions}
            filterable={true}
            columns={columns}
            sorting="type"
            url={url}
            responseFunction={(o) => o.objects}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <br />
          <br />
          Contact Abion to purchase redirect add-on.
        </div>
      );
    }
  }
}

export default withTracking(RedirectTab);

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const initializeAnalytics = (trackingEnabled, trackingId) => {
  if (trackingEnabled) {
    ReactGA.initialize(trackingId, {
      gaOptions: {},
    });
  }
};

/**
 * Custom react hook for tracking page views which automatically includes the current pathname.
 */
const usePageViewTracker = (session) => {
  const location = useLocation();
  const anonymizedPath = anonymizePath(location.pathname);

  useEffect(() => {
    ReactGA.event("page_view", {
      page_path: anonymizedPath,
      user_type: session.isInternalUser() ? "internal" : "external",
    });
  }, [location]);
};

/**
 * Workaround to trigger page view tracking from existing class components.
 * @Deprecated, re-write the class component into a functional component and use the custom usePageViewTracker react hook instead.
 */
export const PageViewTracker = ({ session }) => {
  usePageViewTracker(session);
  return null;
};

function anonymizePath(pathname) {
  return pathname
    .replace(/\/\d+(?=\/|$)/g, "/{id}") // Replace numeric IDs
    .replace(
      /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi,
      "/{id}",
    ) // Replace UUID Ids (Users)
    .replace(/\/[a-z0-9]+-[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g, "/{id}") // Replace SAML user Ids
    .replace(/\/[A-Z]{3}-\d+/g, "/{id}"); // Replace DOM-123... ids
}

/**
 * Higher-Order Component (HOC) to inject tracking function into class components.
 */
export const withTracking = (WrappedComponent) => {
  return (props) => {
    const location = useLocation();
    const anonymizedPath = anonymizePath(location.pathname);

    const trackEvent = (session, category, action, additionalData = {}) => {
      ReactGA.event("user_engagement", {
        event_category: `${category}_${action}`,
        page_path: anonymizedPath,
        user_type: session.isInternalUser() ? "internal" : "external",
        ...additionalData,
      });
    };

    return <WrappedComponent {...props} trackEvent={trackEvent} />;
  };
};

export default usePageViewTracker;

import React, { Component } from "react";
import { getDisplayModeColor, getTagOptions } from "./TagUtil";
import { SearchableFilter } from "./SearchableFilter";
import { Dropdown } from "react-bootstrap";
import Icon from "./theme/Icons";
import styled from "styled-components";

const FilterStyle = styled.span`
  .styled-filter-selector {
    width: 200px;
  }
`;
export default class TagFilter extends Component {
  constructor(props) {
    super(props);
  }

  formatOptions(options) {
    return options.map((o) => {
      return {
        value: o.tagId,
        label: o.description,
      };
    });
  }

  getLoadOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.tags;
    url += "?freetext=" + freetext;
    url += "&labelOnlyIncluded=" + this.props.labelOnlyIncluded;
    url += "&visibleOnly=" + this.props.visibleOnly;

    this.props.session.backendGet(url).then((response) => {
      callback(this.formatOptions(response));
    });
  };

  getStaticOptions = () => {
    return getTagOptions(
      this.props.session.getApplication().tags,
      this.props.labelOnlyIncluded,
      this.props.visibleOnly,
    );
  };

  getItemCreator = (tag, onClick) => {
    const color = getDisplayModeColor(tag.displayMode);
    return (
      <Dropdown.Item
        key={tag.name}
        onClick={() => {
          onClick(tag.name);
          this.props.onChange(tag.id);
        }}
      >
        <Icon color={color} name="square" prefix={"fas"} /> {tag.name}
      </Dropdown.Item>
    );
  };

  render() {
    return (
      <FilterStyle>
        <SearchableFilter
          type="Tags"
          session={this.props.session}
          onChange={this.props.onChange}
          staticOptions={this.getStaticOptions}
          dropdownItemCreator={this.getItemCreator}
          loadOptions={this.getLoadOptions}
        />
      </FilterStyle>
    );
  }
}

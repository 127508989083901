import styled from "styled-components";
import Colors from "../common/theme/Colors";

export const RiskSpan = styled.span`
  .item {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid ${Colors.riskborder};
    margin: 10px;
    display: inline-block;
    cursor: pointer;
  }
  .item-selected,
  .item:hover {
    border-width: 4px;
  }

  .item- {
    border-color: transparent;
  }

  .item-WHITE {
  }

  .item-GREEN {
    background-color: ${Colors.riskGreenBackground};
    border-color: ${Colors.riskGreenBorder};
  }

  .item-YELLOW {
    background-color: ${Colors.riskYellowBackground};
    border-color: ${Colors.riskYellowBorder};
  }

  .item-RED {
    background-color: ${Colors.riskRedBackground};
    border-color: ${Colors.riskRedBorder};
  }

  .item-BLACK {
    background-color: ${Colors.riskBlackBackground};
    border-color: ${Colors.riskBlackBorder};
  }

  .item-BLUE {
    background-color: ${Colors.riskBlueBackground};
    border-color: ${Colors.riskBlueBorder};
  }

  .item-PURPLE {
    background-color: ${Colors.riskPurpleBackground};
    border-color: ${Colors.riskPurpleBorder};
  }
`;

export const RiskItems = [
  {
    item: "WHITE",
    name: "White Risk",
    description: "Awaiting Review",
  },
  {
    item: "GREEN",
    name: "Green Risk",
    description: "Low Risk",
  },
  {
    item: "YELLOW",
    name: "Yellow Risk",
    description: "Medium Risk",
  },
  {
    item: "RED",
    name: "Red Risk",
    description: "High Risk",
  },
  {
    item: "BLACK",
    name: "Black Risk",
    description: "Ignore",
  },
  {
    item: "BLUE",
    name: "Blue Risk",
    description: "Owned By Client",
  },
  {
    item: "PURPLE",
    name: "Purple Risk",
    description: "Authorized By Client",
  },
];

import React, { Component } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import {
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
  TableButton,
} from "../common/Buttons";
import { TableBackend, TableFilter } from "../common/Tables";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import CertificateDetailsDialog from "./CertificateDetailsDialog";
import UpdateCommentsDialog from "../common/UpdateCommentsDialog";
import SearchableOrganisationFilter from "../common/SearchableOrganisationFilter";
import { UpdateCommentsIcon } from "../common/theme/Icons";
import CreateOrderDialog from "../order/CreateOrderDialog";
import fileDownload from "js-file-download";
import { getTagOptions } from "../common/TagUtil";
import TagFilter from "../common/TagFilter";
import TagComponent from "../common/TagComponent";
import UpdateObjectTagsDialog from "../common/UpdateObjectTagsDialog";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class CertificateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        freetext: "",
        lifecycle: "ALL",
        onExpire: "ALL",
        tagIds: "",
        reloadFlag: false,
      },
      certificateId: null,
      showDetailsDialog: false,
      showUpdateCommentsDialog: false,
      showCreateDialog: false,
      showReissueDialog: false,
      showRenewDialog: false,
      reissueParameters: null,
      renewParameters: null,
      comments: null,
      commentsUrl: null,
      submit: false,
      errorMessage: null,
      exported: 0,
      updateTagUrl: null,
      certTagIds: [],
      tagOptions: [],
      updateObjectTagsShow: false,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "SSL Certificates",
        url: null,
      },
    ];
    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().certadmin.links.certificates;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&lifecycleFilter=" + parameters.lifecycle;
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext.trim());
    url += "&onExpireFilter=" + parameters.onExpire;
    url += "&tagIdsFilter=" + parameters.tagIds;
    return url;
  };

  getLifecycleFilterLabel = (lifecycle) => {
    if (lifecycle === "EXPIRES_90_DAYS") {
      return "Expiring Certificates (within 90 days)";
    } else if (lifecycle === "EXPIRES_60_DAYS") {
      return "Expiring Certificates (within 60 days)";
    } else if (lifecycle === "EXPIRES_30_DAYS") {
      return "Expiring Certificates (within 30 days)";
    } else {
      return "All Certificates";
    }
  };

  getOnExpireLabel = (onExpire) => {
    if (onExpire === "RENEW") {
      return "Certificates that will be Renewed";
    } else if (onExpire === "CANCEL") {
      return "Certificates that will be Cancelled";
    } else {
      return "All On Service Expiry";
    }
  };

  onEditComments = (url, comments) => {
    this.setState({
      showUpdateCommentsDialog: true,
      commentsUrl: url,
      comments: comments,
    });
  };

  onUpdateComments = (newComments) => {
    const message = "Comments updated successfully";
    if (
      newComments === null ||
      (newComments.trim() === "" && this.state.comments === null) ||
      this.state.comments === newComments.trim()
    ) {
      this.props.window.showSuccessFunc(message);
      this.closeDialogs();
    } else {
      this.setState({ submit: true });
      const errorFun = (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      };
      this.props.session.backendPost(
        this.state.commentsUrl,
        {
          comments: newComments.trim(),
        },
        () => {
          this.props.window.showSuccessFunc(message);
          this.closeDialogs();
          this.reloadPage();
        },
        errorFun,
      );
    }
  };

  reloadPage = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  openDetailsDialog = (id) => {
    this.setState({
      certificateId: id,
      showDetailsDialog: true,
    });
  };

  reissueCertificate = (parameters) => {
    this.setState({ reissueParameters: parameters, showReissueDialog: true });
  };

  renewCertificate = (parameters) => {
    this.setState({ renewParameters: parameters, showRenewDialog: true });
  };

  onChangeAccount = () => {
    this.reloadPage();
  };

  closeDialogs = () => {
    this.setState({
      certificateId: null,
      submit: false,
      showDetailsDialog: false,
      showUpdateCommentsDialog: false,
      comments: null,
      commentsUrl: null,
      errorMessage: null,
      certTagIds: [],
      updateObjectTagsShow: false,
    });
  };

  onSaveUpdateTags = () => {
    this.closeDialogs();
    this.reloadPage();
  };

  onUpdateTagsDialogShow = (cert) => {
    if (this.props.session.hasRole("SYS_ADMIN")) {
      let url = this.props.session.getApplication().links.tags;
      url += "?clientId=" + cert.clientId;
      url += "&labelOnlyIncluded=" + true;
      url += "&visibleOnly=" + true;

      this.props.session.backendGet(url).then((response) => {
        this.setState({
          tagOptions: response,
          certTagIds: cert.tags?.map((o) => o.id),
          updateTagUrl: cert.links.tags,
          updateObjectTagsShow: true,
        });
      });
    } else {
      this.setState({
        tagOptions: getTagOptions(
          this.props.session.getApplication().tags,
          true,
          true,
        ),
        certTagIds: cert.tags?.map((o) => o.id),
        updateTagUrl: cert.links.tags,
        updateObjectTagsShow: true,
      });
    }
  };

  getDescription = (commonName, sans) => {
    let elements = [];
    if (commonName) {
      elements.push(
        <div key="commonname">
          {commonName}
          <br></br>
        </div>,
      );
    }
    if (sans) {
      let list = sans;
      list.sort();
      list.forEach((v) => {
        if (v !== commonName) {
          elements.push(
            <div key={"name-" + v}>
              {v}
              <br></br>
            </div>,
          );
        }
      });
    }
    if (elements.length > 4) {
      elements = elements.slice(0, 4);
      elements.push(
        <div key="more">
          ...<br></br>
        </div>,
      );
    }
    return elements;
  };

  exportCertificates = () => {
    let url =
      this.props.session.getModules().certadmin.links.exportCertificates;
    url += "?lifecycleFilter=" + this.state.parameters.lifecycle;
    url += "&organisationIdFilter=" + this.state.parameters.organisationId;
    url +=
      "&freetextFilter=" +
      encodeURIComponent(this.state.parameters.freetext.trim());
    url += "&onExpireFilter=" + this.state.parameters.onExpire;

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Certificates.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  onUpdateLifecycle = (serviceDescription, lifecycle, url) => {
    const text = serviceDescription ? serviceDescription + " " : "";
    const message =
      "Are you sure you want to " +
      lifecycle.toLowerCase() +
      " the certificate service " +
      text +
      "when it expires?";
    this.props.window.showConfirmFunc(message, () =>
      this.updateLifecycle(lifecycle, url),
    );
  };

  updateLifecycle = (lifecycle, url) => {
    const callback = () => {
      this.props.window.showSuccessFunc("Service is updated successfully!");
      this.reloadPage();
    };
    let renew = lifecycle.toLowerCase() === "renew";
    this.props.session.backendPut(
      url,
      {
        renew: renew,
      },
      callback,
      this.props.window.showErrorFunc,
    );
  };

  render() {
    const sysAdmin = this.props.session.hasRole("SYS_ADMIN");
    const tags = getTagOptions(
      this.props.session.getApplication().tags,
      true,
      true,
    );
    let actions = [];

    actions.push(
      <MainActionButton
        key="order-certificate"
        onClick={() => this.setState({ showCreateDialog: true })}
        text="Order Certificate"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new certificate."
        disabled={
          !this.props.session.hasRole("ORDER_CREATE") ||
          !this.props.session.hasRole("CERT_ADMIN_EDIT")
        }
        disabledTooltip="Your user account does not have privileges to order certificates, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    if (!this.props.session.hasRole("SYS_ADMIN")) {
      actions.push(
        <BlockingActionButton
          key="export"
          onClick={this.exportCertificates}
          text={"Export"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip="Exports the certificates to an Excel file. Note that only rows matching current filter are exported."
          reload={this.state.exported}
          invisible={this.props.session.hasRole("SYS_ADMIN")}
        />,
      );
    }

    const filters = [
      <StyledDiv key="filters">
        <SearchableOrganisationFilter
          key="organisationFilter"
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                organisationId: o,
              }),
            })
          }
        />
        <TagFilter
          key="tagFilter"
          labelOnlyIncluded={true}
          visibleOnly={true}
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                tagIds: o,
              }),
            })
          }
        />
        <DropdownButton
          key="expires"
          title={this.getLifecycleFilterLabel(this.state.parameters.lifecycle)}
          style={{ verticalAlign: "top" }}
        >
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  lifecycle: "ALL",
                }),
              })
            }
          >
            {this.getLifecycleFilterLabel("ALL")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  lifecycle: "EXPIRES_90_DAYS",
                }),
              })
            }
          >
            {this.getLifecycleFilterLabel("EXPIRES_90_DAYS")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  lifecycle: "EXPIRES_60_DAYS",
                }),
              })
            }
          >
            {this.getLifecycleFilterLabel("EXPIRES_60_DAYS")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  lifecycle: "EXPIRES_30_DAYS",
                }),
              })
            }
          >
            {this.getLifecycleFilterLabel("EXPIRES_30_DAYS")}
          </Dropdown.Item>
        </DropdownButton>
        <DropdownButton
          id="onExpire"
          key="onExpire"
          title={this.getOnExpireLabel(this.state.parameters.onExpire)}
          style={{ verticalAlign: "top" }}
        >
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  onExpire: "ALL",
                }),
              })
            }
          >
            {this.getOnExpireLabel("ALL")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  onExpire: "RENEW",
                }),
              })
            }
          >
            {this.getOnExpireLabel("RENEW")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  onExpire: "CANCEL",
                }),
              })
            }
          >
            {this.getOnExpireLabel("CANCEL")}
          </Dropdown.Item>
        </DropdownButton>
        <TableFilter
          key="filter-text"
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Id",
        name: "id",
        sortable: false,
        hidden: true,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        contentFunction: (r) => (
          <div>{this.getDescription(r.commonName, r.sans)}</div>
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: false,
        contentFunction: (r) => (
          <div>
            {FormatOrganisation(
              r.organisation,
              this.props.session.getApplication().organisationPrefix,
            )}
          </div>
        ),
      },
    ];

    if (sysAdmin || (tags && tags.length > 0)) {
      columns.push({
        label: "Tags",
        name: "tags",
        sortable: false,
        style: { width: "150px" },
        contentFunction: (r) => (
          <TagComponent
            tags={r.tags}
            onEdit={() => this.onUpdateTagsDialogShow(r)}
            readOnly={
              !(
                sysAdmin ||
                (this.props.session.hasRole("ORDER_EDIT") &&
                  this.props.session.hasRole("CERT_ADMIN_EDIT"))
              )
            }
          />
        ),
      });
    }

    columns.push(
      {
        label: "Type",
        name: "certificateType",
        contentFunction: (r) => <div>{r.certificateTypeDescription}</div>,
      },
      {
        label: "Expiry Date",
        name: "expiryDate",
        sortable: true,
        style: { width: "120px" },
        contentFunction: (r) => FormatDateMonospace(r.expiryDate),
      },
      {
        label: "Service Expiry Date",
        name: "invoiceDate",
        sortable: true,
        style: { width: "130px" },
        contentFunction: (r) => FormatDateMonospace(r.invoiceDate),
      },
      {
        label: "On Service Expiry",
        name: "onServiceExpire",
        style: { width: "120px" },
      },
      {
        label: "Comments",
        name: "comments",
        style: { width: "270px" },
        contentFunction: (r) => (
          <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
            <table>
              <tbody>
                <tr>
                  <td width="230px">{r.comments}</td>
                  <td style={{ verticalAlign: "top" }}>
                    <UpdateCommentsIcon
                      onClick={() =>
                        this.onEditComments(r.links.comments, r.comments)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        style: { width: "130px" },
        contentFunction: (r) => (
          <TableButton
            onClick={() => this.openDetailsDialog(r.certificateId)}
            icon="eye"
            prefix={"fas"}
            text="Details"
            className={"secondary"}
          />
        ),
      },
    );

    return (
      <PageStyle>
        <TableBackend
          id="certificate"
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="description"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />

        <CertificateDetailsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDetailsDialog}
          certificateId={this.state.certificateId}
          onClose={this.closeDialogs}
          onReissue={this.reissueCertificate}
          onRenew={this.renewCertificate}
          onChangeAccount={this.onChangeAccount}
          onUpdateLifecycle={this.onUpdateLifecycle}
        />

        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          comments={this.state.comments}
          show={this.state.showUpdateCommentsDialog}
          onCancel={this.closeDialogs}
          onSave={this.onUpdateComments}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />

        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
          step="OPERATION"
          operation="SUBSCRIPTION-IT_CERTIFICATE"
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showReissueDialog}
          onClose={() => this.setState({ showReissueDialog: false })}
          step="CERTIFICATE_PARAMETER"
          operation="SUBSCRIPTION-IT_CERTIFICATE"
          parameters={this.state.reissueParameters}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showRenewDialog}
          onClose={() => this.setState({ showRenewDialog: false })}
          step="OPERATION"
          operation="SUBSCRIPTION-IT_CERTIFICATE"
          parameters={this.state.renewParameters}
        />
        <UpdateObjectTagsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateObjectTagsShow}
          options={this.state.tagOptions}
          currentTagIds={this.state.certTagIds}
          url={this.state.updateTagUrl}
          onClose={this.closeDialogs}
          onSave={this.onSaveUpdateTags}
          objectType="certificate"
        />
      </PageStyle>
    );
  }
}

export default CertificateList;

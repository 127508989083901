import React from "react";
import { Route, Switch } from "react-router-dom";
import DomainList from "./domain/DomainList";
import ContactList from "./contact/ContactList";
import SearchTool from "./searchtool/SearchTool";
import NameserverSetList from "./nameserver/NameserverSetList";
import DomainPage from "./domain/DomainPage";
import usePageViewTracker from "../common/Tracking";

const DomainAdmin = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path="/domainadmin/domains"
        render={(props) => (
          <DomainList session={session} window={window} {...props} />
        )}
      />
      <Route
        path="/domainadmin/domains/:domainId"
        render={(props) => (
          <DomainPage session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/domainadmin/contacts"
        render={(props) => (
          <ContactList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/domainadmin/nameserversets"
        render={(props) => (
          <NameserverSetList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/domainadmin/searchtool"
        render={(props) => (
          <SearchTool session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/domainadmin/searchtool/:domain"
        render={(props) => (
          <SearchTool session={session} window={window} {...props} />
        )}
      />
    </Switch>
  );
};

export default DomainAdmin;

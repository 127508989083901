import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import PortfolioPage from "./PortfolioPage";
import IpRightPage from "./ipright/IpRightPage";
import usePageViewTracker from "../common/Tracking";

const Portfolio = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        path="/portfolio/ipRights/:ipRightId"
        render={(props) => (
          <IpRightPage session={session} window={window} {...props} />
        )}
      />
      <Route
        path="/portfolio/:page?"
        render={(props) => (
          <PortfolioPage session={session} window={window} {...props} />
        )}
      />
    </Switch>
  );
};

export default withRouter(Portfolio);

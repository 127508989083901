import React, { Component } from "react";
import styled from "styled-components";
import { Tag } from "./theme/Theme";
import { UpdateCommentsIcon } from "./theme/Icons";
import { getDisplayModeColor, getDisplayModeTextColor } from "./TagUtil";

const TagComponentStyle = styled.span`
  div svg {
    margin-left: 10px;
  }
`;

const Tags = styled.span`
  .badge {
    margin-right: 5px;
    max-width: 120px;
    text-wrap: initial;
  }
`;

export default class TagComponent extends Component {
  getTags = (tags) => {
    let contents = [];
    if (tags) {
      tags.forEach((o) => {
        contents.push(
          <div key={"tag-" + o}>
            <Tag
              className={"tag"}
              textColor={getDisplayModeTextColor(o.displayMode)}
              background={getDisplayModeColor(o.displayMode)}
            >
              {o.name}
            </Tag>
            <br></br>
          </div>,
        );
      });
    }
    return <Tags>{contents}</Tags>;
  };
  render() {
    let parts = [];
    parts.push(
      <div
        style={{
          whiteSpace: "pre-wrap",
          display: "inline-block",
          verticalAlign: "top",
        }}
      >
        {this.getTags(this.props.tags)}
      </div>,
    );
    if (!this.props.readOnly) {
      parts.push(
        <UpdateCommentsIcon
          onClick={() => this.props.onEdit()}
          title="Update tags"
        />,
      );
    }
    return <TagComponentStyle>{parts}</TagComponentStyle>;
  }
}

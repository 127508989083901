import React, { Component } from "react";
import { MainActionButton, TableButton } from "../../common/Buttons";
import { TableBackend, TableFilter } from "../../common/Tables";
import { InternalLink } from "../../common/Link";
import { Tag } from "../../common/theme/Theme";
import UserDialog from "./UserDialog";
import TagFilter from "../../common/TagFilter";
import TagComponent from "../../common/TagComponent";
import { getTagOptions } from "../../common/TagUtil";
import styled from "styled-components";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";
import { withTracking } from "../../common/Tracking";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagUrl: null,
      userTagIds: [],
      tagOptions: null,
      updateUserTagsShow: false,
      conf: null,
      showDialog: false,
      submit: null,
      errorMessage: null,
      parameters: {
        organisationId: "",
        tagIds: "",
        freetext: "",
        reloadFlag: false,
      },
    };
    this.createUser = this.createUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Users",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  handleSubmit() {
    this.setState({ submit: true });
    const url = this.props.session.getModules().useradmin.links.users;
    const message =
      "User with email " +
      this.state.conf.object.email +
      " is created successfully!";
    this.props.session.backendPost(
      url,
      this.state.conf.object,
      () => {
        this.closeDialog();
        this.setState({ submit: null });
        this.props.window.showSuccessFunc(message);
        this.reloadRows();
        this.props.trackEvent(this.props.session, "user", "created");
      },
      (msg) => {
        this.setState({ submit: null, errorMessage: msg });
      },
    );
  }

  getRolesDescription(roles) {
    return roles.map((role) => (
      <div key={role.label}>
        <Tag key={role.label} className={"parameter clear"}>
          {role.label}
        </Tag>
      </div>
    ));
  }

  getOrganisationFullDescription(organisations) {
    return organisations.map((organisation) => (
      <div key={organisation.fullDescription}>
        {organisation.fullDescription}
      </div>
    ));
  }

  createUser() {
    let confUrl =
      this.props.session.getModules().useradmin.links.users +
      "/_/configuration";
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  closeDialog() {
    this.setState({
      showDialog: false,
      errorMessage: null,
    });
  }

  openConfirmDialog(user) {
    const message =
      "Are you sure you want to remove the user with email " +
      user.email +
      " ?";
    this.props.window.showConfirmFunc(message, () => this.removeUser(user));
  }

  removeUser(user) {
    const url = user.links.self;
    const successMessage =
      "User with email " + user.email + " is removed successfully!";
    const callback = () => {
      this.props.window.showSuccessFunc(successMessage);
      this.setState({
        loading: true,
      });
      this.reloadRows();
      this.props.trackEvent(this.props.session, "user", "deleted");
    };

    this.props.session.backendDelete(
      url,
      callback,
      this.props.window.showErrorFunc,
    );
  }

  reloadRows = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().useradmin.links.users;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&tagIdsFilter=" + parameters.tagIds;
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getRemoveButton = (r) => {
    return (
      <div>
        <TableButton
          onClick={() => this.openConfirmDialog(r)}
          icon="trash-alt"
          prefix={"fas"}
          text="Remove"
          className="remove"
        />
      </div>
    );
  };

  getEmailLink = (r) => {
    return (
      <InternalLink
        to={"/useradmin/users/" + r.userName}
        text={r.email}
        className="table-link"
      />
    );
  };

  render() {
    const sysAdmin = this.props.session.hasRole("SYS_ADMIN");
    const tags = getTagOptions(
      this.props.session.getApplication().tags,
      false,
      false,
    );

    const filters = [
      <StyledDiv key="filters">
        <SearchableOrganisationFilter
          key="organisationFilter"
          session={this.props.session}
          withId="true"
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                organisationId: o,
              }),
            })
          }
        />
        <TagFilter
          key="tagFilter"
          labelOnlyIncluded={false}
          visibleOnly={false}
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                tagIds: o,
              }),
            })
          }
        />
        <TableFilter
          key="FreeTextFilter"
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    let columns = [];
    columns.push(
      {
        label: "Email",
        name: "email",
        sortable: true,
        contentFunction: (r) => (r.readOnly ? r.email : this.getEmailLink(r)),
      },
      {
        label: "First Name",
        name: "firstName",
        sortable: true,
      },
      {
        label: "Last Name",
        name: "lastName",
        sortable: true,
      },
      {
        label: "Organisations",
        name: "organisations",
        contentFunction: (r) => (
          <div>{this.getOrganisationFullDescription(r.organisations)}</div>
        ),
      },
    );

    if (sysAdmin || (tags && tags.length > 0)) {
      columns.push({
        label: "Tags",
        name: "tags",
        sortable: false,
        style: { width: "150px" },
        contentFunction: (r) => <TagComponent tags={r.tags} readOnly={true} />,
      });
    }

    columns.push(
      {
        label: "Roles",
        name: "roles",
        contentFunction: (r) => <div>{this.getRolesDescription(r.roles)}</div>,
      },
      {
        label: "External Identity Provider",
        name: "externalIdp",
        sortable: true,
        style: { width: "170px" },
      },
      {
        label: "Status",
        name: "enabled",
        style: { width: "100px" },
        contentFunction: (r) => (r.enabled ? "Enabled" : "Disabled"),
      },
      {
        style: { width: "100px" },
        contentFunction: (r) => (r.readOnly ? null : this.getRemoveButton(r)),
      },
    );

    const actions = [
      <MainActionButton
        key="CreateUser"
        onClick={this.createUser}
        icon="plus"
        prefix={"fas"}
        text="Create User"
      />,
    ];
    const { showDialog, conf, submit, errorMessage } = this.state;

    return (
      <div>
        <UserDialog
          session={this.props.session}
          showDialog={showDialog}
          closeDialog={this.closeDialog}
          conf={conf}
          createUrl={this.props.session.getModules().useradmin.links.users}
          window={window}
          handleSubmit={this.handleSubmit}
          submit={submit}
          errorMessage={errorMessage}
        />
        <TableBackend
          id="user"
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="email"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
      </div>
    );
  }
}

export default withTracking(UserList);

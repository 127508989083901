import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { FormatDateMonospace } from "../../common/Utils";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  .step-menu {
    margin: 0 auto;
  }

  .step-menu div {
    float: left;
  }

  .step-menu .info {
    position: absolute;
    color: black;
    padding-top: 40px;
  }

  .step-menu .box {
    box-sizing: border-box;
    float: left;
    background: ${Colors.progressNoneBackground};
    height: 80px;
    text-align: center;
    padding: 30px 15px 15px 50px;
    position: relative;
    margin-left: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 170px;
  }

  .step-menu .box:before {
    content: "";
    position: absolute;
    border: 40px solid transparent;
    border-left-color: ${Colors.white};
    top: 0px;
    right: 91px;
  }

  .step-menu .box:nth-child(1):before {
    border: 25px solid transparent;
  }

  .step-menu .box:after {
    content: "";
    position: absolute;
    border: 40px solid transparent;
    border-left-color: ${Colors.progressNoneBackground};
    top: 0px;
    right: -80px;
  }
  .step-menu > .box:nth-child(1) {
    z-index: 100;
  }
  .step-menu > .box:nth-child(2) {
    z-index: 99;
  }
  .step-menu > .box:nth-child(3) {
    z-index: 98;
  }
  .step-menu > .box:nth-child(4) {
    z-index: 97;
  }
  .step-menu > .box:nth-child(5) {
    z-index: 96;
  }
  .step-menu > .box:nth-child(6) {
    z-index: 95;
  }
  .step-menu > .box:nth-child(7) {
    z-index: 94;
  }

  .step-menu > .box.current {
    background: ${Colors.progressCurrentBackground};
    color: white;
  }
  .step-menu > .box.current:after {
    border-left-color: ${Colors.progressCurrentBackground};
  }
  .step-menu > .box.complete {
    background: ${Colors.progressCompleteBackground};
    color: white;
  }
  .step-menu > .box.complete:after {
    border-left-color: ${Colors.progressCompleteBackground};
  }
`;

class ProgressTab extends Component {
  constructor(props) {
    super(props);
  }

  getInfoBox = (date) => {
    return <div class="info"> {FormatDateMonospace(date)}</div>;
  };

  getDetails = (progress) => {
    let classes = "box " + progress.state;
    let info = "";

    if (progress.date != null) {
      info = this.getInfoBox(progress.date);
    }

    return (
      <div class={classes}>
        {progress.phase}
        {info}
      </div>
    );
  };

  getProgressDetails = (ipRight) => {
    const progress = ipRight.progress;
    if (!progress) {
      return;
    }

    let parts = [];
    progress.forEach((p) => {
      parts.push(this.getDetails(p));
    });

    return (
      <div class="step-menu">
        {parts}
        <br style={{ clear: "both" }} />
      </div>
    );
  };

  render() {
    return (
      <DivStyle>
        {this.getProgressDetails(this.props.ipRight)}
        <br style={{ clear: "both" }} />
      </DivStyle>
    );
  }
}

export default ProgressTab;

import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import {
  FormatDateMonospace,
  FormatList,
  FormatOrganisation,
} from "../../common/Utils";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionButton,
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
} from "../../common/Buttons";
import fileDownload from "js-file-download";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import { FormatFeatures } from "../../common/DomainUtils";
import { InternalLink } from "../../common/Link";
import TagFilter from "../../common/TagFilter";
import UpdateObjectTagsDialog from "../../common/UpdateObjectTagsDialog";
import TagComponent from "../../common/TagComponent";
import { getTagOptions } from "../../common/TagUtil";
import UpdateCommentsDialog from "../../common/UpdateCommentsDialog";
import { UpdateCommentsIcon } from "../../common/theme/Icons";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 2px;
  }
`;

export default class DomainList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        freetext: "",
        onExpire: "ALL",
        reloadFlag: false,
        tagIds: "",
      },
      createOrderDialogRegisterShow: false,
      createOrderDialogRegistrarTransferShow: false,
      domain: null,
      domainTagIds: [],
      submit: false,
      errorMessage: null,
      exported: 0,
      updateObjectTagsShow: false,
      comments: null,
      commentsUrl: null,
      showUpdateCommentsDialog: false,
    };
    this.analyzeDomains = this.analyzeDomains.bind(this);
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Domains",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().domainadmin.links.domains;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&onExpireFilter=" + parameters.onExpire;
    url += "&tagIdsFilter=" + parameters.tagIds;
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  reload = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  getNameservers = (nameservers) => {
    let names = [];
    nameservers.forEach((o) => names.push(o.name));
    return names;
  };

  getOnExpireLabel = (onExpire) => {
    if (onExpire === "RENEW") {
      return "Domains that will be Renewed";
    } else if (onExpire === "CANCEL") {
      return "Domains that will be Cancelled";
    } else {
      return "All On Expiry";
    }
  };

  exportDomains = () => {
    let url = this.props.session.getModules().domainadmin.links.exportDomains;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&tagIdsFilter=" + this.state.parameters.tagIds;
    url += "&organisationIdFilter=" + this.state.parameters.organisationId;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Domains.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  analyzeDomains = () => {
    this.props.history.push({
      pathname: "/domainadmin/searchtool",
      state: { analyze: true },
    });
  };

  onCloseUpdateTags = () => {
    this.setState({
      updateObjectTagsShow: false,
      domain: null,
      domainTagIds: [],
    });
  };

  onSaveUpdateTags = () => {
    this.onCloseUpdateTags();
    this.reload();
  };

  onUpdateTagsDialogShow = (domain) => {
    this.setState({
      domain: domain,
      domainTagIds: domain.tags?.map((o) => o.id),
      updateObjectTagsShow: true,
    });
  };

  onCloseCommentDialog = () => {
    this.setState({
      commentsUrl: null,
      comments: null,
      showUpdateCommentsDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  onEditComments = (url, comments) => {
    this.setState({
      showUpdateCommentsDialog: true,
      commentsUrl: url,
      comments: comments,
    });
  };

  onUpdateComments = (newComments) => {
    const message = "Comments updated successfully";
    if (
      newComments === null ||
      (newComments.trim() === "" && this.state.comments === null) ||
      this.state.comments === newComments.trim()
    ) {
      this.props.window.showSuccessFunc(message);
      this.onCloseCommentDialog();
    } else {
      this.setState({ submit: true });
      const errorFun = (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      };
      this.props.session.backendPut(
        this.state.commentsUrl,
        {
          comments: newComments.trim(),
        },
        () => {
          this.props.window.showSuccessFunc(message);
          this.onCloseCommentDialog();
          this.reload();
        },
        errorFun,
      );
    }
  };

  render() {
    const tags = getTagOptions(
      this.props.session.getApplication().tags,
      true,
      true,
    );
    let actions = [];

    let disabled =
      !this.props.session.hasRole("ORDER_CREATE") ||
      !this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    let invisible = this.props.session.hasRole("SYS_ADMIN");

    actions.push(
      <MainActionButton
        key="register-domain"
        onClick={() => this.setState({ createOrderDialogRegisterShow: true })}
        text="Register Domain"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new domain registration."
        disabled={disabled}
        disabledTooltip="Your user account does not have privileges to order domain registrations, contact your client manager to activate this functionality."
        invisible={invisible}
      />,
    );

    actions.push(
      <MainActionButton
        key="transfer-in"
        onClick={() =>
          this.setState({ createOrderDialogRegistrarTransferShow: true })
        }
        text="Transfer In"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a domain registrar transfer to transfer an existing domain to Abion."
        disabled={disabled}
        disabledTooltip="Your user account does not have privileges to order domain registrations, contact your client manager to activate this functionality."
        invisible={invisible}
      />,
    );

    actions.push(
      <BlockingActionButton
        key="ExportDomains"
        onClick={this.exportDomains}
        text={"Export"}
        icon={"cloud-download"}
        prefix={"fas"}
        tooltip="Exports the domains to an Excel file. Note that only rows matching current filter are exported."
        reload={this.state.exported}
      />,
    );
    actions.push(
      <ActionButton
        key="Analyze"
        onClick={this.analyzeDomains}
        text="Analyze"
        prefix="fas"
        icon="search-location"
        tooltip="Open search tool and analyze brands and TLDs present in your domain portfolio."
      />,
    );

    const filters = [
      <SearchableOrganisationFilter
        key="organisationFilter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              organisationId: o,
            }),
          })
        }
      />,
      <TagFilter
        key="tagFilter"
        labelOnlyIncluded={true}
        visibleOnly={true}
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              tagIds: o,
            }),
          })
        }
      />,
      <DropdownButton
        id="onExpire"
        key="onExpire"
        title={this.getOnExpireLabel(this.state.parameters.onExpire)}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "ALL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "RENEW",
              }),
            })
          }
        >
          {this.getOnExpireLabel("RENEW")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "CANCEL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("CANCEL")}
        </Dropdown.Item>
      </DropdownButton>,
      <TableFilter
        key="freeText"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    let columns = [];

    columns.push(
      {
        label: "Domain",
        name: "name",
        sortable: true,
        contentFunction: (r) => (
          <InternalLink
            to={"/domainadmin/domains/" + r.id}
            text={r.name}
            className="table-link"
          />
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: false,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
    );

    if (tags && tags.length > 0) {
      columns.push({
        label: "Tags",
        name: "tags",
        sortable: false,
        style: { width: "150px" },
        contentFunction: (r) => (
          <TagComponent
            tags={r.tags}
            onEdit={() => this.onUpdateTagsDialogShow(r)}
            readOnly={
              !this.props.session.hasRole("ORDER_EDIT") ||
              !this.props.session.hasRole("DOMAIN_ADMIN_EDIT")
            }
          />
        ),
      });
    }

    columns.push(
      {
        label: "Registrant",
        name: "owner",
      },
      {
        label: "Nameservers",
        name: "nameservers",
        contentFunction: (r) => FormatList(this.getNameservers(r.nameservers)),
      },
      {
        label: "Features",
        name: "features",
        contentFunction: (r) => FormatFeatures(r),
      },
      {
        label: "Expiry Date",
        name: "expires",
        sortable: true,
        style: { width: "120px" },
        contentFunction: (r) => FormatDateMonospace(r.expires),
      },
      {
        label: "On Expiry",
        name: "onExpire",
        style: { width: "120px" },
      },
    );

    columns.push({
      label: "Comments",
      name: "comments",
      style: { width: "270px" },
      contentFunction: (r) => (
        <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
          <table>
            <tbody>
              <tr>
                <td width="230px">{r.comments}</td>
                <td style={{ verticalAlign: "top" }}>
                  <UpdateCommentsIcon
                    onClick={() =>
                      this.onEditComments(r.links.comments, r.comments)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    });

    return (
      <PageStyle>
        <TableBackend
          id="domain"
          session={this.props.session}
          window={this.props.window}
          filters={filters}
          columns={columns}
          sorting="name"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
          actions={actions}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogRegisterShow}
          onClose={() =>
            this.setState({ createOrderDialogRegisterShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-REGISTER_DOMAIN"
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogRegistrarTransferShow}
          onClose={() =>
            this.setState({ createOrderDialogRegistrarTransferShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-REGISTRAR_TRANSFER"
        />
        <UpdateObjectTagsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateObjectTagsShow}
          options={tags}
          currentTagIds={this.state.domainTagIds}
          url={this.state.domain?.links.tags}
          onClose={this.onCloseUpdateTags}
          onSave={this.onSaveUpdateTags}
          objectType="domain"
        />
        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          comments={this.state.comments}
          show={this.state.showUpdateCommentsDialog}
          onCancel={this.onCloseCommentDialog}
          onSave={this.onUpdateComments}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </PageStyle>
    );
  }
}

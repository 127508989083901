import React from "react";
import { Route, Switch } from "react-router-dom";
import ZoneList from "./zone/ZoneList";
import ZonePage from "./zone/ZonePage";
import PublishPage from "./publish/PublishPage";
import BulkPage from "./bulk/BulkPage";
import usePageViewTracker from "../common/Tracking";

const DnsAdmin = ({ session, window }) => {
  usePageViewTracker(session);

  return (
    <Switch>
      <Route
        exact={true}
        path="/dnsadmin/zones"
        render={(props) => (
          <ZoneList session={session} window={window} {...props} />
        )}
      />
      <Route
        path="/dnsadmin/zones/:zoneId"
        render={(props) => (
          <ZonePage session={session} window={window} {...props} />
        )}
      />
      <Route
        path="/dnsadmin/publish"
        render={(props) => (
          <PublishPage session={session} window={window} {...props} />
        )}
      />
      <Route
        path="/dnsadmin/bulk"
        render={(props) => (
          <BulkPage session={session} window={window} {...props} />
        )}
      />
    </Switch>
  );
};

export default DnsAdmin;

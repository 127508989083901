import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef } from "react";
import styled from "styled-components";
import Colors from "./Colors";
import { darken } from "polished";

const setIconColor = (color) => {
  switch (color) {
    case "error":
      return Colors.error;
    case "primary":
      return Colors.primary;
    case "secondary":
      return Colors.secondary;
    case "warning":
      return Colors.warning;
    case "success":
      return Colors.success;
    case "inactive":
      return Colors.inactive;
    default:
      return color;
  }
};

const PortsIcon = styled(FontAwesomeIcon)`
  && {
    font-size: ${(props) => (props.iconsize ? props.iconsize + "px" : "12px")};
    color: ${(props) => (props.color ? setIconColor(props.color) : null)};
    padding-right: ${(props) =>
      props.paddingright ? props.paddingright + "px" : "0"};

    &.crumb {
      margin: 0 7px;
    }

    &.menu-icon {
      font-size: 16px;
    }
  }
`;

library.add(fal, far, fas);

const Icon = forwardRef((props, ref) => {
  const innerProps = props;

  return (
    <PortsIcon
      icon={[innerProps.prefix ? innerProps.prefix : "fal", innerProps.name]}
      ref={ref}
      color={innerProps.color}
      className={"fa" + innerProps.className}
      size={innerProps.size}
      iconsize={innerProps.iconsize}
      {...innerProps}
    />
  );
});

const UpdateCommentsStyle = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    size: 1x;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
    }
  }
`;

const UpdateCommentsIcon = (props) => (
  <UpdateCommentsStyle icon={["fas", "pen"]} {...props} />
);

export { UpdateCommentsIcon };

export default Icon;

import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import { Col, Row } from "react-bootstrap";
import { InputWithLabel } from "../../common/theme/Theme";
import { InformationField } from "../../common/Information";
import Selector from "../../common/Selector";
import {
  getCascadeModeTooltip,
  getDisplayModeComponent,
  getDisplayModeTooltip,
  getTypeTooltip,
} from "../../common/TagUtil";

class TagDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      submit: false,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.showDialog &&
      prevProps.showDialog !== this.props.showDialog
    ) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  toOrgOptions(organisations) {
    return organisations.map((o) => {
      return {
        value: o.clientId,
        label: o.fullDescriptionWithIds,
      };
    });
  }

  getOrganisationOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.organisations;
    url += "?freetext=" + freetext;
    url += "&onlyClient=true";

    this.props.session.backendGet(url).then((response) => {
      callback(this.toOrgOptions(response));
    });
  };

  getOrganisationDefaultValue = () => {
    if (
      this.state.conf &&
      this.state.conf.object &&
      this.state.conf.object.clientId
    ) {
      return {
        value: this.state.conf.object.clientId,
        label: this.state.conf.object.organisation,
      };
    }
  };

  onClose = () => {
    this.setState({ submit: false, errorMessage: null, conf: null });
    this.props.closeDialog();
  };

  handleSubmit = () => {
    this.setState({ submit: true });

    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };

    if (this.props.create) {
      const successFun = () => {
        this.setState({ submit: false, errorMessage: null, conf: null });
        this.props.handleSubmit();
        this.props.window.showSuccessFunc("Tag successfully created");
        this.props.session.reloadApplication();
      };
      const url = this.props.session.getModules().useradmin.links.tags;
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    } else {
      const successFun = () => {
        this.setState({ submit: false, errorMessage: null, conf: null });
        this.props.handleSubmit();
        this.props.window.showSuccessFunc("Tag successfully updated");
        this.props.session.reloadApplication();
      };
      const url = this.state.conf.object.links.self;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    }
  };
  createElements() {
    let elements = [];
    elements.push(
      <Row key="tag-name" name={"tag-name-row"}>
        <Col sm={12} name={"tag-name-container"}>
          <InformationField>
            <InputWithLabel
              label={"Tag Name"}
              type="text"
              placeholder="Tag Name"
              name="name"
              value={this.state.conf.object.name}
              onChange={(e) => this.updateField("name", e.target.value)}
              required="required"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="tag" name={"tag-displayMode-row"}>
        <Col sm={12} name={"tag-displayMode-row-container"}>
          <InformationField
            tooltip={getDisplayModeTooltip("ALL")}
            style={{ width: "100%" }}
          >
            <Selector
              label={"Display Mode"}
              required={true}
              options={this.getDisplayModeOptions(this.state.conf.displayModes)}
              defaultValue={this.state.conf.object.displayMode}
              onChange={(selection) =>
                this.updateField("displayMode", selection.value)
              }
              placeholder="Select Display Mode"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="tag" name={"tag-cascadeMode-row"}>
        <Col sm={12} name={"tag-cascadeMode-row-container"}>
          <InformationField
            tooltip={getCascadeModeTooltip("ALL")}
            style={{ width: "100%" }}
          >
            <Selector
              label={"Cascade Mode"}
              required={true}
              options={this.state.conf.cascadeModes}
              defaultValue={this.state.conf.object.cascadeMode}
              onChange={(selection) =>
                this.updateField("cascadeMode", selection.value)
              }
              placeholder="Select Cascade Mode"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="tag" name={"tag-type-row"}>
        <Col sm={12} name={"tag-type-row-container"}>
          <InformationField
            tooltip={getTypeTooltip("ALL")}
            style={{ width: "100%" }}
          >
            <Selector
              label={"Tag Type"}
              required={true}
              options={this.state.conf.tagTypes}
              defaultValue={this.state.conf.object.tagType}
              onChange={(selection) =>
                this.updateField("tagType", selection.value)
              }
              placeholder="Select Tag Type"
            />
          </InformationField>
        </Col>
      </Row>,
    );

    if (this.props.session.hasRole("SYS_ADMIN")) {
      elements.push(
        <Row key="organisation" name={"organisaion-selector-row"}>
          <Col sm={12} name={"organization-selector-container"}>
            <InformationField style={{ width: "100%" }}>
              <Selector
                label={"Organisation"}
                ignoreAccents={false}
                removeSelected={false}
                isAsync
                required={true}
                loadOptions={this.getOrganisationOptions}
                defaultValue={this.getOrganisationDefaultValue}
                onChange={(selection) =>
                  this.updateField("clientId", selection.value)
                }
                placeholder="Select Organisation"
              />
            </InformationField>
          </Col>
        </Row>,
      );
    }
    return elements;
  }

  getDisplayModeOptions = (options) => {
    let result = [];
    options.forEach((option) => {
      result.push({
        value: option.value,
        label: getDisplayModeComponent(option.value, option.label),
      });
    });
    return result;
  };

  render() {
    if (this.state.conf === null) {
      return;
    }
    return (
      <EditDialog
        content={this.createElements()}
        show={this.props.showDialog}
        title={this.props.create ? "Create Tag" : "Edit Tag"}
        onCancel={this.onClose}
        onConfirm={this.handleSubmit}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
        name={"tag"}
      />
    );
  }
}

export default TagDialog;

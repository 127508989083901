import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";
import { RiskItems, RiskSpan } from "./RiskUtil";

const RiskFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-left: 0px !important;
    margin-right: 20px;
  }
`;

const RiskComponentStyle = styled(RiskSpan)`
  .risk {
    width: 15px;
    height: 15px;
    margin: 0px;
    margin-right: 10px;
  }

  .risk-image svg {
    margin-left: 10px;
    margin-bottom: 4px;
  }

  .item:hover {
    border-width: 1px;
  }
`;

export default class RiskFilter extends Component {
  render() {
    let items = [];
    let title = "";

    let itemsToAdd = RiskItems.slice();
    itemsToAdd.unshift({
      item: "",
      name: "Any Risk",
      description: "Any Risk",
    });

    itemsToAdd.forEach((item) => {
      items.push(
        <Dropdown.Item
          key={item.name}
          onClick={() => this.props.onChange(item.item)}
        >
          <RiskComponentStyle className="risk-image">
            <div className={"risk item item-" + item.item} />
            {item.description}
          </RiskComponentStyle>
        </Dropdown.Item>,
      );

      if (item.item === this.props.value) {
        title = item.description;
      }
    });

    return (
      <RiskFilterStyle>
        <DropdownButton title={title} style={{ verticalAlign: "top" }}>
          {items}
        </DropdownButton>
      </RiskFilterStyle>
    );
  }
}

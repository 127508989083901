import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../common/theme/Colors";
import { darken } from "polished";
import { NavigationButton } from "../common/Buttons";
import { SearchDialog } from "../common/Dialog";
import { ProjectMessageComponent } from "./ProjectMessageComponent";
import { InputWithLabel } from "../common/theme/Theme";

const IconButton = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    font-size: 20px;
    padding: 0 5px 0;

    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
      cursor: pointer;
    }

    &:disabled,
    &[disabled] {
      color: #d6d6d6;
      cursor: default;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftAligned = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 10px;
`;

const TertiaryButtons = styled.div`
  display: flex;
  margin: 0 10px;
`;

IconButton.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

export class SearchMessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      messages: [],
      current: -1,
      matches: 0,
    };
    this.currentRef = React.createRef();
    this.debouncedReload = this.debounce(this.reload, 300);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.reload("", 0);
    }
  }

  reload = (query, current) => {
    this.props.session.backendGet(
      this.getUrl(encodeURIComponent(query), current),
      (response) => {
        let count = this.count(response);

        this.setState(
          {
            messages: response,
            query: query,
            current: count[0],
            matches: count[1],
          },
          () => {
            if (this.currentRef.current) {
              this.currentRef.current.scrollIntoView();
            }
          },
        );
      },
    );
  };

  getFooter = (matchCount) => {
    const tooltipPrevious = (
      <Tooltip style={{ position: "fixed" }}>Previous</Tooltip>
    );
    const tooltipNext = <Tooltip style={{ position: "fixed" }}>Next</Tooltip>;
    const isDisabled = this.state.matches === 0;
    return (
      <Footer>
        <LeftAligned>
          <InputWithLabel
            name="searchField"
            placeholder={"Search"}
            autoFocus={true}
            value={this.state.query.value}
            onChange={(event) => this.onSearchMessages(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                this.reload(this.state.query, this.state.current + 1);
              }
            }}
          />
          <TertiaryButtons>
            <OverlayTrigger overlay={tooltipPrevious} placement={"bottom"}>
              <IconButton
                icon={["fas", "angle-up"]}
                onClick={() =>
                  this.reload(this.state.query, this.state.current - 1)
                }
                disabled={isDisabled}
              />
            </OverlayTrigger>
            <OverlayTrigger overlay={tooltipNext} placement={"bottom"}>
              <IconButton
                icon={["fas", "angle-down"]}
                onClick={() =>
                  this.reload(this.state.query, this.state.current + 1)
                }
                disabled={isDisabled}
              />
            </OverlayTrigger>
          </TertiaryButtons>
          <span>{matchCount}</span>
        </LeftAligned>
        <NavigationButton
          key="close"
          onClick={this.onClose}
          text="Close"
          type={"button"}
        />
      </Footer>
    );
  };

  onSearchMessages = (query) => {
    this.setState({ query: query });
    this.debouncedReload(query, 0);
  };

  onClose = () => {
    this.setState({ messages: [], query: "", current: -1, matches: 0 });
    this.props.onClose();
  };

  count = (messages) => {
    let count = [];
    let matches = 0;
    let current = -1;
    messages.forEach((message) => {
      message.content.forEach((textChunk) => {
        if (textChunk.current) {
          current = matches;
        }
        if (textChunk.match) {
          matches++;
        }
      });
    });
    count.push(current);
    count.push(matches);
    return count;
  };

  getUrl = (query, current) => {
    let url = this.props.url;
    url += "?query=" + query;
    url += "&current=" + current;
    return url;
  };

  debounce = (callback, wait) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => callback(...args), wait);
    };
  };

  render() {
    let matchCount = this.state.current + 1 + "/" + this.state.matches;
    let body = (
      <ProjectMessageComponent
        messages={this.state.messages}
        currentRef={this.currentRef}
        height="inherit"
      />
    );

    return (
      <SearchDialog
        show={this.props.show}
        title="Search Messages"
        body={body}
        footer={this.getFooter(matchCount)}
        width="lg"
        height="lg"
      />
    );
  }
}

import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import {
  FormatDateMonospace,
  FormatIntegerMonospace,
  FormatOrganisation,
} from "../../common/Utils";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";
import { LinkContainer } from "react-router-bootstrap";
import { TableButton } from "../../common/Buttons";
import styled from "styled-components";
import { getTagOptions } from "../../common/TagUtil";
import TagFilter from "../../common/TagFilter";
import TagComponent from "../../common/TagComponent";
import UpdateObjectTagsDialog from "../../common/UpdateObjectTagsDialog";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 2px;
  }
`;

export default class DomainMonitorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        freetext: "",
        tagIds: "",
        reloadFlag: false,
      },
      objectTagIds: [],
      watch: null,
      tagOptions: [],
      updateObjectTagsShow: false,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Watches",
        url: "/monitoring/watches",
      },
      {
        label: "Domain Monitors",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().monitoring.links.domainmonitors;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&tagIdsFilter=" + parameters.tagIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  reload = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  onCloseUpdateTags = () => {
    this.setState({
      updateObjectTagsShow: false,
      watch: null,
      objectTagIds: [],
    });
  };

  onSaveUpdateTags = () => {
    this.onCloseUpdateTags();
    this.reload();
  };

  onUpdateTagsDialogShow = (watch) => {
    if (this.props.session.hasRole("SYS_ADMIN")) {
      let url = this.props.session.getApplication().links.tags;
      url += "?clientId=" + watch.clientId;
      url += "&labelOnlyIncluded=" + true;
      url += "&visibleOnly=" + true;

      this.props.session.backendGet(url).then((response) => {
        this.setState({
          tagOptions: response,
          watch: watch,
          objectTagIds: watch.tags?.map((o) => o.id),
          updateObjectTagsShow: true,
        });
      });
    } else {
      this.setState({
        tagOptions: getTagOptions(
          this.props.session.getApplication().tags,
          true,
          true,
        ),
        watch: watch,
        objectTagIds: watch.tags?.map((o) => o.id),
        updateObjectTagsShow: true,
      });
    }
  };

  getActionButton = (id) => {
    return (
      <LinkContainer
        to={{
          pathname: "/monitoring/watches/domainmonitorhits",
          state: { domainMonitorId: id },
        }}
      >
        <TableButton
          text="Hits"
          className="secondary"
          prefix="fas"
          icon="bullseye"
        />
      </LinkContainer>
    );
  };

  render() {
    const sysAdmin = this.props.session.hasRole("SYS_ADMIN");
    const tags = getTagOptions(
      this.props.session.getApplication().tags,
      true,
      true,
    );

    const filters = [
      <SearchableOrganisationFilter
        key="organisationFilter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              organisationId: o,
            }),
          })
        }
      />,
      <TagFilter
        key="tagFilter"
        labelOnlyIncluded={true}
        visibleOnly={true}
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              tagIds: o,
            }),
          })
        }
      />,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    const columns = [
      {
        label: "Id",
        name: "id",
        sortable: false,
        hidden: true,
      },
      {
        label: "Domain Name",
        name: "description",
        sortable: true,
        contentFunction: (r) => r.description,
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: false,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
    ];

    if (sysAdmin || (tags && tags.length > 0)) {
      columns.push({
        label: "Tags",
        name: "tags",
        sortable: false,
        style: { width: "150px" },
        contentFunction: (r) => (
          <TagComponent
            tags={r.tags}
            onEdit={() => this.onUpdateTagsDialogShow(r)}
            readOnly={!sysAdmin && !this.props.session.hasRole("ORDER_EDIT")}
          />
        ),
      });
    }

    columns.push(
      {
        label: "Hits Total",
        name: "hitsTotal",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsTotal),
      },
      {
        label: "Hits 30 Days",
        name: "hitsThirtyDays",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsThirtyDays),
      },
      {
        label: "Hits 7 Days",
        name: "hitsSevenDays",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsSevenDays),
      },
      {
        label: "Last Discovered",
        name: "lastDiscovered",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatDateMonospace(r.lastDiscovered),
      },
      {
        label: null,
        name: "hits",
        style: { width: "150px" },
        contentFunction: (r) => {
          return this.getActionButton(r.id);
        },
      },
    );

    return (
      <PageStyle>
        <TableBackend
          id="watch.domainmonitor"
          session={this.props.session}
          window={this.props.window}
          filters={filters}
          columns={columns}
          sorting="description"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <UpdateObjectTagsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateObjectTagsShow}
          options={this.state.tagOptions}
          currentTagIds={this.state.objectTagIds}
          url={this.state.watch?.links.tags}
          onClose={this.onCloseUpdateTags}
          onSave={this.onSaveUpdateTags}
          objectType="domain monitor"
        />
      </PageStyle>
    );
  }
}

import React, { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import Window from "./Window";
import { Loader } from "./theme/Theme";
import { initializeAnalytics } from "./Tracking";

const App = ({ session }) => {
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    session.backendGet(
      "api/rest",
      (response) => {
        session.setApplication(response);
        initializeAnalytics(response.trackingEnabled, response.trackingId);
        setLoaded(true);
      },
      (error) => {
        setLoaded(true);
        setErrorMessage(error);
      },
    );
  }, [session]);

  if (errorMessage == null) {
    return (
      <Loader loaded={loaded}>
        <HashRouter>
          <Window session={session} />
        </HashRouter>
      </Loader>
    );
  } else {
    return (
      <div style={{ padding: "20px" }}>
        <h3>Initialization Error</h3>
        <p>{errorMessage}</p>
      </div>
    );
  }
};

export default App;

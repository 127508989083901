import React, { Component } from "react";
import WindowedSelect, { WindowedMenuList } from "react-windowed-select";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import Colors from "./theme/Colors";
import { BorderColor, InputLabel } from "./theme/Theme";
import { IsSet } from "./Utils";

const StyledSelect = styled(WindowedSelect)`
    &.styled-selector.focused {
	   border-bottom: 1px solid ${Colors.secondary};
	   font-weight: 300;
	   font-size: 14px;

	   svg {
	      color:  ${Colors.secondary};
	   }
	}
	
	&.selector__option--is-selected {
		font-size: 20px;
	}
	
    &.styled-selector {
	    border: none;
        border-bottom: 1px solid ${Colors.primary};
        font-weight: 300;
	    font-size: 14px;
	    margin-bottom: 10px;

	    .selector__indicator-separator {
		   width: 0px;
	    }
	   
        .control-selector {
	       border-width: 0px;
	       heigth: 35px;
	       
        }
        
        .is-focused {
	      outline: none transparent;
          box-shadow: none;
	    }
	    
	    svg {
		   color: ${Colors.primary};
	    }
	    
	    .selector__multi-value {
		    background-color:  ${Colors.secondary};
		    svg {
		       color: ${Colors.white};
	        }
	    }
	    
	    .selector__multi-value__label {
		   color: ${Colors.white}
	    }
	    
	    .selector__multi-value__remove:hover {
		   background-color: ${Colors.secondary};
		   svg {
		      color: ${Colors.secondary};
	       }
	    }
	}
	  
    &.Select--multi{
      .Select-multi-value-wrapper{
        .Select-value{
          background: ${Colors.secondary};
          border: 1px solid ${Colors.secondary};
          color: ${Colors.white};
          font-weight: 300;
          border-radius: 3px;
          
          .Select-value-icon{
            border-right: none;
            padding: 1px 7px 1px;
          }
          .Select-value-label{
            padding: 2px 7px 2px 0px;
          }
        }
      }
    }

    &.controller{
      float: right;
      width: 100px;
    }

    &.is-disabled > .Select-control {
	   cursor: not-allowed;
	   background: ${Colors.disabled};
    }
  }
`;

const StyledAsyncSelect = styled(AsyncSelect)`
     &.styled-selector.focused {
	   border-bottom: 1px solid ${Colors.secondary};
	   font-weight: 300;
	   font-size: 14px;
	   
	   svg {
	      color:  ${Colors.secondary};
	   }
	}
	
	&.selector__option--is-selected {
		font-size: 20px;
	}
	
    &.styled-selector {
	    border: none;
        border-bottom: 1px solid ${Colors.primary};
        font-weight: 300;
	    font-size: 14px;
	    margin-bottom: 10px;
	 
	    .selector__indicator-separator {
		   width: 0px;
	    }
	   
        .control-selector {
	       border-width: 0px;
	       heigth: 35px;
	       
        }
        
        .is-focused {
	      outline: none transparent;
          box-shadow: none;
	    }
	    
	    svg {
		   color: ${Colors.primary};
	    }
	    
	    .selector__multi-value {
		    background-color:  ${Colors.secondary};
		    svg {
		       color: ${Colors.white};
	        }
	    }
	    
	    .selector__multi-value__label {
		   color: ${Colors.white}
	    }
	    
	    .selector__multi-value__remove:hover {
		   background-color: ${Colors.secondary};
		   svg {
		      color: ${Colors.secondary};
	       }
	    }
	}
	  
    &.Select--multi{
      .Select-multi-value-wrapper{
        .Select-value{
          background: ${Colors.secondary};
          border: 1px solid ${Colors.secondary};
          color: ${Colors.white};
          font-weight: 300;
          border-radius: 3px;
          
          .Select-value-icon{
            border-right: none;
            padding: 1px 7px 1px;
          }
          .Select-value-label{
            padding: 2px 7px 2px 0px;
          }
        }
      }
    }

    &.controller{
      float: right;
      width: 100px;
    }

    &.is-disabled > .Select-control {
	   cursor: not-allowed;
	   background: ${Colors.disabled};
    }
  }
`;

const Controls = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;

  button {
    margin-right: 0 !important;
    width: 90%;
  }
`;

const StyledFilterSelector = styled(AsyncSelect)`
  &.styled-filter-selector {
    .control-filter-selector {
      border-radius: 4px;
      border: 1px solid ${BorderColor};
      max-height: 34px;
    }

    .filter-selector__control {
      min-height: 34px;
    }

    .is-focused {
      border-color: rgb(224, 224, 224);
      box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 5px inset;
    }

    .filter-selector__dropdown-indicator {
      padding: 4px;
      color: ${Colors.tertiary};
    }

    .filter-selector__placeholder {
      color: ${Colors.tertiary};
    }

    .filter-selector__clear-indicator {
      color: ${Colors.tertiary};
    }
  }
`;

class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: props.defaultValue, focused: false };
    this.onChange = this.onChange.bind(this);
    this.changeLabelColor = this.changeLabelColor.bind(this);
    this.changeLabelColorBack = this.changeLabelColorBack.bind(this);
  }

  changeLabelColor() {
    this.setState({
      focused: true,
    });
  }

  changeLabelColorBack() {
    this.setState({
      focused: false,
    });
  }

  getLabel() {
    if (this.state.focused) {
      return (
        <InputLabel style={{ color: Colors.secondary }}>
          {this.props.label}
        </InputLabel>
      );
    } else {
      return <InputLabel>{this.props.label}</InputLabel>;
    }
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  isContentArray(array) {
    return Array.isArray(array) && array.length > 0 && IsSet(array[0]);
  }

  render() {
    const { value, onChange, ...passThroughProps } = this.props;

    let className = "styled-selector";

    if (this.state.focused) {
      className = "focused styled-selector";
    }

    let selection = passThroughProps.defaultValue;
    let options = this.props.options;

    if (IsSet(passThroughProps.loadOptions)) {
      options = passThroughProps.loadOptions;
    } else if (IsSet(passThroughProps.options)) {
      options = passThroughProps.options;
    }

    if (!this.isFunction(options)) {
      if (IsSet(passThroughProps.defaultValue)) {
        if (this.isContentArray(passThroughProps.defaultValue)) {
          selection = passThroughProps.defaultValue;
        } else {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue,
          );
        }
        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue.value,
          );
        }
      } else if (IsSet(this.state.selection)) {
        if (this.isContentArray(this.state.selection)) {
          selection = this.state.selection;
        } else {
          selection = options.filter((v) => v.value == this.state.selection);
        }

        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == this.state.selection.value,
          );
        }
      } else if (IsSet(this.props.value)) {
        if (this.isContentArray(this.props.value)) {
          selection = this.props.value;
        } else {
          selection = options.filter((v) => v.value == this.props.value);
        }

        if (selection.length === 0) {
          selection = options.filter((v) => v.value == this.props.value.value);
        }
      }
    }

    if (this.props.isAsync) {
      return (
        <div>
          {this.getLabel()}
          <StyledAsyncSelect
            {...passThroughProps}
            components={{ MenuList: WindowedMenuList }}
            className={className}
            classNamePrefix="selector"
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "is-focused control-selector "
                  : " control-selector ",
            }}
            styles={{
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? state.isFocused
                    ? "#007eff10"
                    : "inherit"
                  : state.isFocused
                    ? "#007eff10"
                    : "inherit",
                fontWeight: state.isSelected ? "700" : "300",
                color: "inherit",
                padding: "0.5rem",
                alignItems: "center",
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: "12px",
                fontWeight: 300,
                fontStyle: "oblique",
              }),
            }}
            menuPosition="fixed"
            onFocus={this.changeLabelColor}
            onBlur={this.changeLabelColorBack}
            focused={this.state.focused}
            cacheOptions
            defaultOptions
            onChange={this.onChange}
            defaultValue={selection}
            options={options}
            noOptionsMessage={(a) =>
              !this.isEmpty(a.inputValue) && a.inputValue.length > 2
                ? "No options found"
                : "Type to search"
            }
            isDisabled={this.props.disabled}
          />
        </div>
      );
    } else {
      return (
        <div>
          {this.getLabel()}
          <StyledSelect
            {...passThroughProps}
            className={className}
            classNamePrefix="selector"
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "is-focused control-selector "
                  : " control-selector ",
            }}
            styles={{
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? state.isFocused
                    ? "#007eff10"
                    : "inherit"
                  : state.isFocused
                    ? "#007eff10"
                    : "inherit",
                fontWeight: state.isSelected ? "700" : "300",
                color: "inherit",
                padding: "0.5rem",
                alignItems: "center",
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: "12px",
                fontWeight: 300,
                fontStyle: "oblique",
              }),
            }}
            menuPosition="fixed"
            onFocus={this.changeLabelColor}
            onBlur={this.changeLabelColorBack}
            focused={this.state.focused}
            isDisabled={this.props.disabled}
            defaultValue={selection}
            onChange={this.onChange}
            options={options}
            closeMenuOnSelect={!IsSet(passThroughProps.isMulti)}
            removeSelected={false}
          />
        </div>
      );
    }
  }

  isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  onChange(event) {
    this.setState({ selection: event });
    this.props.onChange(event);
  }
}

class UserTagSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTagIds: props.currentTagIds,
    };
  }

  onCheckChange = (changeEvent, tagId) => {
    let selectedTagIds = this.state.selectedTagIds;
    if (changeEvent.target.checked) {
      selectedTagIds.push(tagId);
    } else {
      selectedTagIds = selectedTagIds.filter((o) => o !== tagId);
    }

    this.setState({
      selectedTagIds: selectedTagIds,
    });

    this.props.onSelectChange(selectedTagIds);
  };

  isChecked = (tagId) => {
    return this.state.selectedTagIds.includes(tagId);
  };

  getCheckboxes = () => {
    let tags = this.props.allTags;
    return tags.map((tag) => (
      <div>
        <label style={{ marginTop: "5px" }}>
          <input
            type="checkbox"
            onChange={(e) => this.onCheckChange(e, tag.id)}
            checked={this.isChecked(tag.id)}
          />
          &nbsp;{tag.name}
        </label>
      </div>
    ));
  };

  getCheck = () => {
    return <div>{this.getCheckboxes()}</div>;
  };

  render() {
    return this.getCheck();
  }
}

class ObjectTagSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toAdd: [],
      toRemove: [],
      checkedIds: this.getInitialCheckedIds(this.props.currentTagIds),
    };
  }

  getInitialCheckedIds(currentTagIds) {
    let checkedIds = [];
    if (currentTagIds) {
      currentTagIds.forEach((id) => checkedIds.push(id));
    }
    return checkedIds;
  }

  onCheckChange = (changeEvent, tagId) => {
    let toAdd = this.state.toAdd;
    let toRemove = this.state.toRemove;
    let checkedIds = this.state.checkedIds;

    if (changeEvent.target.checked) {
      if (!this.props.currentTagIds.includes(tagId) && !toAdd.includes(tagId)) {
        toAdd.push(tagId);
      }
      toRemove = toRemove.filter((o) => o !== tagId);
      checkedIds.push(tagId);
    } else {
      if (
        this.props.currentTagIds.includes(tagId) &&
        !toRemove.includes(tagId)
      ) {
        toRemove.push(tagId);
      }
      toAdd = toAdd.filter((o) => o !== tagId);
      checkedIds = checkedIds.filter((o) => o !== tagId);
    }

    this.setState({
      toAdd: toAdd,
      toRemove: toRemove,
      checkedIds: checkedIds,
    });

    this.props.onSelectChange(toAdd, toRemove);
  };

  isChecked = (tagId) => {
    return this.state.checkedIds.includes(tagId);
  };

  getCheckboxes = () => {
    let tags = this.props.allTags;
    return tags.map((tag) => (
      <div>
        <label style={{ marginTop: "5px" }}>
          <input
            type="checkbox"
            onChange={(e) => this.onCheckChange(e, tag.id)}
            checked={this.isChecked(tag.id)}
          />
          &nbsp;{tag.name}
        </label>
      </div>
    ));
  };

  getCheck = () => {
    return <div>{this.getCheckboxes()}</div>;
  };

  render() {
    return this.getCheck();
  }
}

class FilterSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: props.defaultValue, focused: false };
    this.onChange = this.onChange.bind(this);
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  isContentArray(array) {
    return Array.isArray(array) && array.length > 0 && IsSet(array[0]);
  }

  render() {
    const { value, onChange, ...passThroughProps } = this.props;

    let className = "styled-filter-selector";

    let selection = passThroughProps.defaultValue;
    let options = this.props.options;

    if (IsSet(passThroughProps.loadOptions)) {
      options = passThroughProps.loadOptions;
    } else if (IsSet(passThroughProps.options)) {
      options = passThroughProps.options;
    }

    if (!this.isFunction(options)) {
      if (IsSet(passThroughProps.defaultValue)) {
        if (this.isContentArray(passThroughProps.defaultValue)) {
          selection = passThroughProps.defaultValue;
        } else {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue,
          );
        }
        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue.value,
          );
        }
      } else if (IsSet(this.state.selection)) {
        if (this.isContentArray(this.state.selection)) {
          selection = this.state.selection;
        } else {
          selection = options.filter((v) => v.value == this.state.selection);
        }

        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == this.state.selection.value,
          );
        }
      } else if (IsSet(this.props.value)) {
        if (this.isContentArray(this.props.value)) {
          selection = this.props.value;
        } else {
          selection = options.filter((v) => v.value == this.props.value);
        }

        if (selection.length === 0) {
          selection = options.filter((v) => v.value == this.props.value.value);
        }
      }
    }

    return (
      <div>
        <InputLabel>{this.props.label}</InputLabel>
        <StyledFilterSelector
          {...passThroughProps}
          components={{ MenuList: WindowedMenuList }}
          className={className}
          classNamePrefix="filter-selector"
          classNames={{
            control: (state) =>
              state.isFocused
                ? "is-focused control-filter-selector "
                : " control-filter-selector ",
          }}
          styles={{
            placeholder: (base) => ({
              ...base,
              fontSize: "14px",
              fontWeight: 400,
              fontStyle: "oblique",
            }),
          }}
          focused={this.state.focused}
          cacheOptions
          defaultOptions
          onChange={this.onChange}
          defaultValue={selection}
          options={options}
          noOptionsMessage={(a) =>
            !this.isEmpty(a.inputValue) && a.inputValue.length > 2
              ? "No options found"
              : "Type to search"
          }
          isDisabled={this.props.disabled}
        />
      </div>
    );
  }

  isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  onChange(event) {
    this.setState({ selection: event });
    this.props.onChange(event);
  }
}

export default Selector;
export { ObjectTagSelect, UserTagSelect, FilterSelector };

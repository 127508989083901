import React, { Component } from "react";
import { DialogSection, WizardDialog } from "./Dialog";
import SelectAccountDialog from "./SelectAccountDialog";
import ChangeAccountResult from "./ChangeAccountResult";
import ReadOnlyForm from "./ReadOnlyForm";
import { PortsAlert } from "./PortsAlert";

export default class ChangeAccountDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: null,
      allSubscriptions: false,
      changeAccountDialogStep: null,
      includeSubscriptions: true,
      submit: false,
    };
  }

  componentDidMount() {
    this.reloadSubscriptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadSubscriptions();
    }
  }

  reloadSubscriptions = () => {
    this.setState({ subscriptions: null });
    if (this.props.domainSubscriptionsUrl) {
      this.props.session.backendGet(
        this.props.domainSubscriptionsUrl,
        (response) => {
          this.setState({
            subscriptions: response.domainSoftSubscriptions,
            allSubscriptions: response.allSoftSubscriptions,
          });
          if (
            response.domainSoftSubscriptions &&
            (!response.allSoftSubscriptions ||
              response.domainSoftSubscriptions.length > 0)
          ) {
            this.setState({ changeAccountDialogStep: "RELATED_SERVICES" });
          } else {
            this.showChangeAccountDialog();
          }
        },
      );
    } else {
      this.showChangeAccountDialog();
    }
  };

  onCheckChange = (changeEvent) => {
    this.setState({
      includeSubscriptions: changeEvent.target.checked,
    });
  };

  onConfirm = (accountId) => {
    let transferRelatedServices = false;

    if (
      this.state.includeSubscriptions &&
      this.state.subscriptions &&
      this.state.subscriptions.length > 0
    ) {
      transferRelatedServices = true;
    }

    const resource = {
      transferRelatedServices,
      accountId,
    };

    this.changeAccount(resource);
  };

  changeAccount = (resource) => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg, submit: false });
    };
    const callback = () => {
      this.setState({ errorMessage: null, changeAccountDialogStep: "RESULT" });
    };
    let url = this.props.changeAccountUrl;
    this.props.session.backendPost(url, resource, callback, errorFun);
  };

  showChangeAccountDialog = () => {
    let confUrl = this.props.session.getApplication().links.organisations;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({
        organisations: response,
        changeAccountDialogStep: "CHANGE_ACCOUNT",
        submit: false,
      });
    });
  };

  onClose = () => {
    this.resetDialogState();
    this.props.onClose();
  };

  onChangeAccount = () => {
    this.resetDialogState();
    this.props.onClose();
  };

  resetDialogState() {
    this.setState({
      changeAccountDialogStep: null,
      includeSubscriptions: true,
    });
  }

  getSubscriptions = (subscriptions, allSubscriptions) => {
    let parts = [];

    if (subscriptions && subscriptions.length > 0) {
      parts.push(
        <div>
          The domain has related services, they are shown in the list below. If
          you don't want to transfer them, uncheck the checkbox below.
          <br />
          <br />
        </div>,
      );

      let checkbox;
      let text = "Transfer all related services";
      const input = (
        <input
          type="checkbox"
          onChange={this.onCheckChange}
          checked={this.state.includeSubscriptions}
        />
      );
      checkbox = (
        <label className="check-label">
          {input}&nbsp;&nbsp;{text}
        </label>
      );
      parts.push(checkbox);

      let subscriptionList = [];
      subscriptions.forEach((o) => {
        subscriptionList.push({
          label: o.typeDescription,
          value: o.description,
          labelStyle: { paddingLeft: "20px" },
        });
      });
      let listPart = (
        <DialogSection title="">
          {" "}
          <ReadOnlyForm content={subscriptionList} />
        </DialogSection>
      );
      parts.push(listPart);
    }

    if (!allSubscriptions) {
      parts.push(
        <div key="warning">
          <PortsAlert variant="warning">
            <strong>Note:</strong> The domain has services configured. Your user
            account does not have privileges to access to those services. Please
            contact your client manager for further details.
          </PortsAlert>
        </div>,
      );
    }

    return parts;
  };

  getSubscriptionBody = () => {
    if (!this.state.subscriptions) {
      return null;
    }

    let parts = [];
    let subscriptions = this.state.subscriptions;
    let allSubscriptions = this.state.allSubscriptions;

    if (subscriptions && subscriptions.length === 0 && allSubscriptions) {
      return parts;
    }
    parts.push(this.getSubscriptions(subscriptions, allSubscriptions));

    return <div>{parts}</div>;
  };

  render() {
    switch (this.state.changeAccountDialogStep) {
      case "RELATED_SERVICES":
        return (
          <WizardDialog
            show={this.state.changeAccountDialogStep === "RELATED_SERVICES"}
            title={"Change Account"}
            body={this.getSubscriptionBody()}
            errorMessage={this.props.errorMessage}
            onCancel={this.onClose}
            height="sm"
            onNext={this.showChangeAccountDialog}
          />
        );
      case "CHANGE_ACCOUNT":
        return (
          <SelectAccountDialog
            session={this.props.session}
            show={this.state.changeAccountDialogStep === "CHANGE_ACCOUNT"}
            organisations={this.state.organisations}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            onConfirm={this.onConfirm}
            onClose={this.onClose}
          />
        );
      case "RESULT":
        return (
          <ChangeAccountResult
            show={this.state.changeAccountDialogStep === "RESULT"}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            onChangeAccount={this.onChangeAccount}
          />
        );
      default:
        return <div />;
    }
  }
}

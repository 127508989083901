import React, { Component } from "react";
import { EditDialog } from "../common/Dialog";
import { InputWithLabel } from "../common/theme/Theme";
import Selector from "../common/Selector";

let messageTypes = [
  {
    value: null,
    label: "Don't send an initial message",
  },
  {
    value: "EMPLOYEE_QUESTION",
    label: "Question",
  },
  {
    value: "EMPLOYEE_COMMENT",
    label: "Comment",
  },
];

export default class UpdateProjectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      comments: "",
      initialMessage: "",
      messageType: messageTypes[0],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState({
        description: "",
        comments: "",
        initialMessage: "",
        messageType: messageTypes[0],
      });
    }
  }

  getTitle = () => {
    if (this.props.nbrOfHits == 1) {
      return "Create Case";
    } else {
      return "Create Cases";
    }
  };

  getDescription = () => {
    if (this.props.nbrOfHits == 1) {
      return "Create a case for the hit.";
    } else {
      return "Create cases for the " + this.props.nbrOfHits + " selected hits.";
    }
  };

  getFields = () => {
    return [
      <InputWithLabel
        key="description"
        label="Description"
        type="textarea"
        rows="7"
        name="description"
        placeholder="Enter case description, the customer will be able to see this text"
        value={this.state.description}
        onChange={(e) => this.setState({ description: e.target.value })}
        autoFocus
      />,
      <InputWithLabel
        key="comments"
        label="Internal Notes"
        type="textarea"
        rows="7"
        name="comments"
        placeholder="Enter internal notes, only your colleagues will be able to see these notes"
        value={this.state.comments}
        onChange={(e) => this.setState({ comments: e.target.value })}
      />,
      <Selector
        label="Initial Message Type"
        name="messageType"
        options={messageTypes}
        placeholder="Select Message Type"
        closeMenuOnSelect={true}
        removeSelected={false}
        defaultValue={this.state.messageType}
        onChange={(selection) => {
          this.setState({ initialMessage: "", messageType: selection });
        }}
        required={false}
      />,

      <InputWithLabel
        key="message"
        label={
          "Initial " +
          (this.state.messageType && this.state.messageType.value
            ? this.state.messageType.label
            : "Message ")
        }
        type="textarea"
        rows="7"
        name="initialMessage"
        disabled={!this.state.messageType.value}
        placeholder={
          "Enter initial " +
          (this.state.messageType && this.state.messageType.value
            ? this.state.messageType.label
            : "Message ") +
          " for the customer"
        }
        value={this.state.initialMessage}
        onChange={(e) => {
          console.log(this.state.messageType);
          this.setState({ initialMessage: e.target.value });
        }}
      />,
    ];
  };

  onSave = () => {
    this.props.onSave(
      this.state.description,
      this.state.comments,
      this.state.messageType != null ? this.state.messageType.value : null,
      this.state.initialMessage,
    );
  };

  render() {
    return (
      <EditDialog
        show={this.props.show}
        title={this.getTitle()}
        description={this.getDescription()}
        content={this.getFields()}
        onConfirm={this.onSave}
        onCancel={this.props.onCancel}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import Selector from "../../common/Selector";
import { FormatOrganisation } from "../../common/Utils";
import { withTracking } from "../../common/Tracking";

class UserOrganisationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      value: [],
      errorMessage: null,
      submit: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.showDialog !== this.props.showDialog ||
      prevProps.url !== this.props.url
    ) {
      this.load();
    }
  }

  load = () => {
    this.setState({ conf: null, value: [], errorMessage: null });
    if (this.props.showDialog && this.props.url) {
      this.props.session.backendGet(this.props.url, (response) => {
        this.setState({
          conf: response,
          value: response.object.organisations.map((o) => o.id),
        });
      });
    }
  };

  save = () => {
    const url = this.state.conf.object.links.organisations;
    this.setState({ submit: true });
    this.props.session.backendPut(
      url,
      this.state.value,
      () => {
        this.props.closeDialog();
        this.setState({ submit: false });
        this.props.window.showSuccessFunc(
          "Organisations updated successfully!",
        );
        this.props.trackEvent(
          this.props.session,
          "user_organisation",
          "edited",
        );
      },
      (errorMessage) => {
        this.setState({ submit: false, errorMessage: errorMessage });
      },
    );
  };

  onChange = (value) => {
    this.setState({ value: value.map((o) => o.value) });
  };

  filterOrganisations(organisations) {
    let orgOptions = organisations
      .filter((org) => org.accessible)
      .map((o) => {
        return {
          value: o.id,
          label: FormatOrganisation(
            o.fullDescriptionWithIds,
            this.props.session.getApplication().organisationHierarchyPrefix,
          ),
        };
      });
    return orgOptions;
  }

  getOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.organisations;
    url += "?freetext=" + freetext;

    this.props.session.backendGet(url).then((response) => {
      callback(this.filterOrganisations(response));
    });
  };

  getValue = () => {
    if (this.state.conf.object == null) {
      return null;
    } else {
      return this.state.conf.object.organisations.map((o) => {
        return {
          value: o.id,
          label: o.fullDescription,
        };
      });
    }
  };

  render() {
    const elements = [];

    if (!this.state.conf) {
      elements.push(<div>Loading, please wait...</div>);
    } else {
      elements.push(
        <Selector
          isMulti
          isAsync
          ignoreAccents={false}
          removeSelected={false}
          required={true}
          loadOptions={this.getOptions}
          defaultValue={this.getValue}
          onChange={this.onChange}
          placeholder="Select Organisations"
        />,
      );
    }

    return (
      <EditDialog
        show={this.props.showDialog}
        title="Edit Organisations"
        content={elements}
        onCancel={this.props.closeDialog}
        onConfirm={this.save}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
        height="sm"
      />
    );
  }
}

export default withTracking(UserOrganisationDialog);

import React, { Component } from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { BorderColor, Loader } from "../../common/theme/Theme";
import styled from "styled-components";
import { MainActionButton } from "../../common/Buttons";
import { TabContent, TabControlPanel } from "../../common/TabContent";
import Colors from "../../common/theme/Colors";
import SettingDialog from "./SettingDialog";
import { withTracking } from "../../common/Tracking";

const Setting = styled(Col)`
  text-align: center;

  .form-group {
    background: ${Colors.white};
    padding: 35px;
    border: 1px solid ${BorderColor};
  }

  label {
    color: ${Colors.primary};
    font-weight: 700;
  }
  .value {
    font-size: 2em;
    font-weight: 100;
  }
`;

class SettingTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
      loaded: false,
      submit: false,
      errorMessage: null,
    };
    this.openDialog = this.openDialog.bind(this);
    this.onDialogCancel = this.onDialogCancel.bind(this);
    this.onDialogOk = this.onDialogOk.bind(this);
  }

  componentDidMount() {
    this.setState({ loaded: true });
    this.reloadZone();
  }

  reloadZone() {
    let url = this.props.zone.links.configuration;
    this.props.session.backendGet(url, (response) => {
      this.setState({ conf: response });
    });
  }

  openDialog() {
    this.setState({ showDialog: true });
    this.reloadZone();
  }

  onDialogCancel() {
    this.setState({ showDialog: false, errorMessage: null });
    this.reloadZone();
  }

  onDialogOk(data) {
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };
    let url = this.props.zone.links.self;
    this.setState({ submit: true });
    this.props.session.backendPut(
      url,
      data,
      (_) => {
        this.setState({ submit: false, showDialog: false });
        this.reloadZone();
        this.props.onChange();
        const message = "Your new settings are saved";
        this.props.window.showSuccessFunc(message);
        this.props.trackEvent(this.props.session, "zone_settings", "edited");
      },
      errorFun,
    );
  }

  getForm() {
    let fields = [];

    fields.push(
      <Setting key="primary-nameserver" sm={3}>
        <FormGroup className="form-group">
          <Col as={FormLabel}>Primary Nameserver</Col>
          <Col className="value">{this.state.conf.object.mName}</Col>
        </FormGroup>
      </Setting>,
    );

    fields.push(
      <Setting key="refresh" sm={2}>
        <FormGroup className="form-group">
          <Col as={FormLabel}>Refresh</Col>
          <Col className="value">{this.state.conf.object.refresh}</Col>
        </FormGroup>
      </Setting>,
    );

    fields.push(
      <Setting key="retry" sm={2}>
        <FormGroup className="form-group">
          <Col as={FormLabel}>Retry</Col>
          <Col className="value">{this.state.conf.object.retry}</Col>
        </FormGroup>
      </Setting>,
    );

    fields.push(
      <Setting key="expire" sm={2}>
        <FormGroup className="form-group">
          <Col as={FormLabel}>Expire</Col>
          <Col className="value">{this.state.conf.object.expire}</Col>
        </FormGroup>
      </Setting>,
    );

    fields.push(
      <Setting key="ttl" sm={2}>
        <FormGroup className="form-group">
          <Col as={FormLabel}>TTL</Col>
          <Col className="value">{this.state.conf.object.ttl}</Col>
        </FormGroup>
      </Setting>,
    );

    return (
      <Form>
        <Container fluid={true}>
          <Row>{fields}</Row>
        </Container>
      </Form>
    );
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    let editButton = null;
    if (this.props.session.hasRole("DNS_ADMIN_EDIT", "DNS_ADMIN_PUBLISH")) {
      editButton = (
        <MainActionButton
          onClick={this.openDialog}
          icon="cogs"
          prefix={"fas"}
          text="Edit Settings"
        />
      );
    }

    return (
      <Loader loaded={this.state.loaded}>
        <TabControlPanel className="side-padding">{editButton}</TabControlPanel>
        <TabContent className={"clearbg no-shadow"}>
          <Row>{this.getForm()}</Row>
        </TabContent>
        <SettingDialog
          showDialog={this.state.showDialog}
          conf={this.state.conf}
          onCancel={this.onDialogCancel}
          onConfirm={this.onDialogOk}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </Loader>
    );
  }
}

export default withTracking(SettingTab);

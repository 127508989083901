import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import { Loader } from "../../common/theme/Theme";
import { PageTabsNew } from "../../common/PageTabs";
import Icon from "../../common/theme/Icons";
import HistoryTab from "./HistoryTab.js";
import RecordTab from "./RecordTab.js";
import RedirectTab from "./RedirectTab.js";
import ServerTab from "./ServerTab.js";
import SettingTab from "./SettingTab";
import ZoneFileTab from "./ZoneFileTab.js";
import ZoneHeader from "./ZoneHeader";
import RelationsTab from "./RelationsTab";
import { PageViewTracker } from "../../common/Tracking";

class ZonePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      zone: null,
      baseUrl: null,
    };
    this.reloadZone = this.reloadZone.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.zoneHead = this.zoneHead.bind(this);
  }

  componentDidMount() {
    this.reloadZone();
  }

  reloadZone() {
    let url =
      this.props.session.getModules().dnsadmin.links.zones +
      "/" +
      this.props.match.params.zoneId;
    this.reloadZoneWithUrl(url);
  }

  reloadZoneWithUrl = (url) => {
    this.props.session.backendGet(url, (response) => {
      this.setState({
        zone: response,
        baseUrl: `/dnsadmin/zones/${response.id}`,
        loaded: true,
      });

      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Zones",
          url: "/dnsadmin/zones",
        },
        {
          label: response.zoneName,
          url: null,
        },
      ];

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  };

  handleTabSelect(event) {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  }

  zoneHead() {
    return (
      <div>
        {this.state.zone.zoneName}{" "}
        <span className="type">({this.state.zone.zoneType.label})</span>
      </div>
    );
  }

  onRelationClicked = (id) => {
    let url = this.props.session.getModules().dnsadmin.links.zones + "/" + id;
    this.reloadZoneWithUrl(url);
  };

  render() {
    const Records = () => {
      return (
        <RecordTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Redirects = () => {
      return (
        <RedirectTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Servers = () => {
      return (
        <ServerTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Zonefile = () => {
      return (
        <ZoneFileTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Settings = () => {
      return (
        <SettingTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Relations = () => {
      return (
        <RelationsTab
          zone={this.state.zone}
          session={this.props.session}
          window={this.props.window}
          onClick={(id) => this.onRelationClicked(id)}
        />
      );
    };
    const History = () => {
      return (
        <HistoryTab
          zone={this.state.zone}
          onChange={this.reloadZone}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const Tabs = (props) => {
      let tabs = [];
      if (props.zone.zoneType.value === "MASTER") {
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"zone-" + props.zone.id + "-records"}
            data-tab={1}
            to={`${props.baseUrl}/records`}
          >
            <Icon className={"menu-icon"} name="at" /> Records
          </NavTab>,
        );
        if (props.zone.redirectService) {
          tabs.push(
            <NavTab
              onClick={this.handleTabSelect}
              key={"zone-" + props.zone.id + "-redirects"}
              data-tab={2}
              to={`${props.baseUrl}/redirects`}
            >
              <Icon className={"menu-icon"} name="directions" /> Redirects
            </NavTab>,
          );
        }
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"zone-" + props.zone.id + "-servers"}
            data-tab={3}
            to={`${props.baseUrl}/servers`}
          >
            <Icon className={"menu-icon"} name="server" /> Servers
          </NavTab>,
        );
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"zone-" + props.zone.id + "-advanced"}
            data-tab={4}
            to={`${props.baseUrl}/advanced`}
          >
            <Icon className={"menu-icon"} name="code" /> Advanced
          </NavTab>,
        );
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"zone-" + props.zone.id + "-settings"}
            data-tab={5}
            to={`${props.baseUrl}/settings`}
          >
            <Icon className={"menu-icon"} name="sliders-h" /> Settings
          </NavTab>,
        );
      } else {
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"zone-" + props.zone.id + "-servers"}
            data-tab={3}
            to={`${props.baseUrl}/servers`}
          >
            <Icon className={"menu-icon"} name="arrows-h" /> Servers
          </NavTab>,
        );
      }
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"zone-" + props.zone.id + "-relations"}
          data-tab={6}
          to={`${props.baseUrl}/relations`}
        >
          <Icon className={"menu-icon"} name="folder-tree" /> Relations
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"zone-" + props.zone.id + "-history"}
          data-tab={7}
          to={`${props.baseUrl}/history`}
        >
          <Icon className={"menu-icon"} name="history" /> History
        </NavTab>,
      );

      return tabs;
    };

    const { zone, loaded, baseUrl } = this.state;
    const { session, window } = this.props;
    const zoneName = zone ? zone.zoneName : "Loading Zone name...";
    const zoneOrg = zone ? zone.organisation : "Loading Zone organisation...";

    if (this.state.zone) {
      const defaultPath =
        baseUrl + (zone.zoneType.value === "MASTER" ? "/records" : "/servers");
      return (
        <Loader loaded={loaded}>
          <PageViewTracker session={session} />
          <ZoneHeader
            session={session}
            zone={zone}
            window={window}
            onChange={this.reloadZone}
            title={zoneName}
            org={zoneOrg}
          />
          <PageTabsNew className={"nav nav-tabs"}>
            <Tabs zone={zone} baseUrl={baseUrl} />
          </PageTabsNew>
          <Switch>
            <Route
              path={`${this.state.baseUrl}/records`}
              component={Records}
              key={"route-records"}
            />
            <Route
              path={`${this.state.baseUrl}/redirects`}
              component={Redirects}
              key={"route-redirects"}
            />
            <Route
              path={`${this.state.baseUrl}/servers`}
              component={Servers}
              key={"route-servers"}
            />
            <Route
              path={`${this.state.baseUrl}/advanced`}
              component={Zonefile}
              key={"route-advanced"}
            />
            <Route
              path={`${this.state.baseUrl}/settings`}
              component={Settings}
              key={"route-settings"}
            />
            <Route
              path={`${this.state.baseUrl}/relations`}
              component={Relations}
              key={"route-relations"}
            />
            <Route
              path={`${this.state.baseUrl}/history`}
              component={History}
              key={"route-history"}
            />
            <Route
              exact
              path={`${this.state.baseUrl}`}
              key={"route-records-redirect-1"}
              render={() => <Redirect replace to={defaultPath} />}
            />
            <Route
              path={`${this.state.baseUrl}/*`}
              key={"route-records-redirect-2"}
              render={() => <Redirect replace to={defaultPath} />}
            />
          </Switch>
        </Loader>
      );
    } else {
      return null;
    }
  }
}

export default ZonePage;

import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import { SwitchCheckbox } from "../common/theme/Theme";
import { FormatDate } from "../common/IpRightUtils";
import { MainActionButton, TableButton } from "../common/Buttons";
import { ExternalLink } from "../common/Link";
import { FormatDateMonospace } from "../common/Utils";
import DocumentDialog from "./DocumentDialog";
import fileDownload from "js-file-download";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import { withTracking } from "../common/Tracking";

const DivStyle = styled.div`
  .download-link {
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.tertiary};
  }
`;

class DocumentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyDisplayActive: true,
      showDocumentDialog: false,
      conf: null,
      submit: false,
      downloadingDocs: new Map(),
      errorMessage: null,
    };
  }

  handleCheckboxOnChange = (event) => {
    this.setState({ onlyDisplayActive: event.target.checked });
  };

  filterByState = (rows) => {
    if (this.state.onlyDisplayActive) {
      rows = rows.filter((o) => o.status.value === "ACTIVE");
    }
    return rows;
  };

  onOpenDialog = (documentId) => {
    let confUrl =
      this.props.project.links.documents + "/" + documentId + "/configuration";
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({
        showDocumentDialog: true,
        conf: response,
        errorMessage: null,
      });
    });
  };

  onCloseDialog = () => {
    this.setState({
      showDocumentDialog: false,
      submit: false,
      conf: null,
      errorMessage: null,
    });
  };

  handleSubmit = () => {
    let url = this.props.project.links.documents;
    const successFun = () => {
      this.onCloseDialog();
      this.props.onChange();
      const message = "Document successfully created";
      this.props.window.showSuccessFunc(message);
      this.props.trackEvent(this.props.session, "project_document", "created");
    };
    const successEditFun = () => {
      this.onCloseDialog();
      this.props.onChange();
      const message = "Document successfully edited";
      this.props.window.showSuccessFunc(message);
      this.props.trackEvent(this.props.session, "project_document", "edited");
    };
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };

    if (this.state.conf.object.id) {
      this.setState({ submit: true });
      url = url + "/" + this.state.conf.object.id;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ submit: true });
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    }
  };

  onRemove = (document) => {
    let message = (
      <div>
        Are you sure you want to remove the document "{document.fileName}"?
      </div>
    );

    const successFun = () => {
      this.props.onChange();
      const message = "Document successfully removed";
      this.props.window.showSuccessFunc(message);
      this.props.trackEvent(this.props.session, "project_document", "deleted");
    };

    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };

    this.props.window.showConfirmFunc(message, () => {
      const url = this.props.project.links.documents + "/" + document.id;
      this.props.session.backendDelete(url, successFun, errorFun);
    });
  };

  getLink = (document) => {
    let icon = this.state.downloadingDocs.has(document.id) ? "spinner" : "";
    return (
      <ExternalLink
        onClick={() => this.onDownload(document)}
        tooltip={"Click to download"}
        text={document.name}
        className={"download-link"}
        icon={icon}
      />
    );
  };

  onDownload = (document) => {
    const url =
      this.props.project.links.documents + "/" + document.id + "/file";
    const fileName = document.fileName;
    this.beforeDownload(document.id);
    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, fileName);
        this.afterDownload(document.id);
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.afterDownload(document.id);
      },
    );
  };

  beforeDownload = (key) => {
    let docs = this.state.downloadingDocs;
    if (!docs.has(key)) {
      docs.set(key, null);
    }
    this.setState({ downloadingDocs: docs });
  };

  afterDownload = (key) => {
    let docs = this.state.downloadingDocs;
    docs.delete(key);
    this.setState({ downloadingDocs: docs });
  };

  getButtons = (document) => {
    if (!document.editable) {
      return;
    }
    let buttons = [
      <TableButton
        key={"edit-" + document.id}
        onClick={() => this.onOpenDialog(document.id)}
        icon="edit"
        prefix={"fas"}
        text="Edit"
        className={"secondary"}
      />,
      <TableButton
        key={"remove-" + document.id}
        onClick={() => this.onRemove(document)}
        icon="trash-alt"
        prefix={"fas"}
        text="Remove"
        className={"remove"}
      />,
    ];

    return <div>{buttons}</div>;
  };

  render() {
    const columns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
        filterable: true,
        contentFunction: (r) => this.getLink(r),
      },
      {
        label: "Status",
        name: "status",
        sortable: true,
        filterable: true,
        contentFunction: (r) => r.status.label,
        sortFunction: (r) => r.status.label,
      },
      {
        label: "Created At",
        name: "created",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateMonospace(r.created),
        filterFunction: (r) => FormatDate(r.created),
      },
      {
        label: "Created By",
        name: "createdBy",
        sortable: true,
        filterable: true,
      },
      {
        label: "",
        name: "action",
        style: { width: "210px" },
        contentFunction: (r) => this.getButtons(r),
      },
    ];

    const { session, window } = this.props;
    const url = this.props.project.links.documents;
    const filters = [
      SwitchCheckbox(
        "Only display active documents",
        this.state.onlyDisplayActive,
        (o) => this.handleCheckboxOnChange(o),
      ),
    ];
    const actions = [
      <MainActionButton
        key="create-document"
        onClick={() => this.onOpenDialog("_")}
        icon="plus"
        prefix={"fas"}
        text="Create Document"
      />,
    ];

    return (
      <DivStyle>
        <TableFrontend
          id="project.document"
          session={session}
          window={window}
          otherFilters={filters}
          filterable={true}
          columns={columns}
          actions={actions}
          sorting="name"
          url={url}
          responseFunction={this.filterByState}
          reload={this.state.onlyDisplayActive}
        />
        <DocumentDialog
          session={this.props.session}
          window={this.props.window}
          name="document dialog"
          show={this.state.showDocumentDialog}
          conf={this.state.conf}
          submit={this.state.submit}
          onCancel={this.onCloseDialog}
          onSave={this.handleSubmit}
          errorMessage={this.state.errorMessage}
        />
      </DivStyle>
    );
  }
}

export default withTracking(DocumentsTab);

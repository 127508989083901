import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import { InputWithLabel } from "../../common/theme/Theme";

export class BulkTagObjectsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [],
      errorMessage: null,
      submit: false,
    };
  }

  onConfirm = () => {
    this.setState({ submit: true, errorMessage: null });
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };
    const successFuc = () => {
      this.onCloseDialog();
      this.props.onSubmit();
      const action =
        this.props.action === "add" ? "added to tag" : "removed from tag";
      const message =
        this.props.relationType + " are " + action + " successfully";
      this.props.window.showSuccessFunc(message);
    };

    this.props.session.backendPost(
      this.props.url,
      {
        ids: this.state.ids,
      },
      successFuc,
      errorFun,
    );
  };

  onCancel = () => {
    this.onCloseDialog();
    this.props.onClose();
  };

  onCloseDialog = () => {
    this.setState({
      ids: [],
      errorMessage: null,
      submit: false,
    });
  };

  render() {
    let elements = [
      <InputWithLabel
        label={this.props.relationType + " To " + this.props.action}
        type="textarea"
        rows="10"
        placeholder={"Enter " + this.props.idFieldName + ", one per line"}
        onChange={(e) =>
          this.setState({
            ids: e.target.value.split(/\r?\n/),
          })
        }
      />,
    ];

    let action = this.props.action === "add" ? "Add" : "Remove";
    let title = "Bulk " + action + " " + this.props.relationType;

    let noteMessages = [];
    noteMessages.push(
      "The bulk update function allows you to tag or untag a large number of objects in one operation. " +
        "It is an advanced function and it requires you to have the object identifiers available, usually you would get it from an excel export or your Abion representative. " +
        "The easiest way to tag objects is on their respective pages (and not here), that is recommended for most use cases.",
    );

    return (
      <EditDialog
        session={this.props.session}
        content={elements}
        noteMessages={noteMessages}
        show={this.props.show}
        title={title}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        onConfirmLabel={"Save"}
        submit={this.state.submit}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

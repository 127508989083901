import React from "react";
import { Route, Switch } from "react-router-dom";
import DomainWatchList from "./domainwatch/DomainWatchList";
import DomainWatchHitList from "./domainwatch/DomainWatchHitList";
import TrademarkWatchList from "./trademarkwatch/TrademarkWatchList";
import TrademarkWatchHitList from "./trademarkwatch/TrademarkWatchHitList";
import DomainMonitorList from "./domainmonitor/DomainMonitorList";
import DomainMonitorHitList from "./domainmonitor/DomainMonitorHitList";
import WebContentWatchList from "./webcontentwatch/WebContentWatchList";
import WebContentWatchHitList from "./webcontentwatch/WebContentWatchHitList";
import WatchList from "./WatchList";
import ProjectList from "../project/ProjectList";
import ProjectDetail from "../project/ProjectDetail";
import usePageViewTracker from "../common/Tracking";

const Monitoring = ({ session, window }) => {
  usePageViewTracker(session);
  return (
    <Switch>
      <Route
        exact={true}
        path="/monitoring/watches"
        render={(props) => (
          <WatchList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/domainwatches"
        render={(props) => (
          <DomainWatchList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/domainwatchhits"
        render={(props) => (
          <DomainWatchHitList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/webcontentwatches"
        render={(props) => (
          <WebContentWatchList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/webcontentwatchhits"
        render={(props) => (
          <WebContentWatchHitList
            session={session}
            window={window}
            {...props}
          />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/trademarkwatches"
        render={(props) => (
          <TrademarkWatchList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/trademarkwatchhits"
        render={(props) => (
          <TrademarkWatchHitList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/domainmonitors"
        render={(props) => (
          <DomainMonitorList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/watches/domainmonitorhits"
        render={(props) => (
          <DomainMonitorHitList session={session} window={window} {...props} />
        )}
      />
      <Route
        exact={true}
        path="/monitoring/cases"
        render={(props) => (
          <ProjectList
            session={session}
            window={window}
            caseModule={true}
            {...props}
          />
        )}
      />
      <Route
        path="/monitoring/cases/:caseId"
        render={(props) => (
          <ProjectDetail
            session={session}
            window={window}
            caseModule={true}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default Monitoring;

import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../common/theme/Colors";

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

const MessageRows = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  border-radius: 0.8rem;
  max-width: 75%;
  padding: 0.2rem 0.875rem;
  position: relative;
  text-wrap: initial;
  width: fit-content;
  margin: 0.2rem 0.8rem;
  white-space: pre-line;

  &.left {
    align-items: flex-start;
    background-color: ${Colors.messageLeftBackground};
    color: ${Colors.messageLeftText};
    overflow-wrap: anywhere;
  }

  &.right {
    align-self: flex-end;
    background-color: ${Colors.messageRightBackground};
    color: ${Colors.messageRightText};
    overflow-wrap: anywhere;
  }
`;

const CurrentSpan = styled.span`
  background-color: #ff9632;
  color: black;
`;

const MatchSpan = styled.span`
  background-color: yellow;
  color: black;
`;

export class ProjectMessageComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ messages: this.getMessages() });
  }

  getMessages = () => {
    let messages = [];
    this.props.messages.forEach((o) => {
      messages.push(this.getMessageChunk(o));
    });
    return (
      <Messages
        style={{
          height: `${this.props.height}`,
          overflow: `${this.props.overflow}`,
        }}
      >
        <MessageRows>{messages}</MessageRows>
      </Messages>
    );
  };

  getMessageChunk = (message) => {
    let messageChunk = [];
    message.content.forEach((o) => {
      let textChunk = this.getSpan(o);
      messageChunk.push(textChunk);
    });
    let className = message.fromCustomer ? "right" : "left";
    return <Message className={className}>{messageChunk}</Message>;
  };

  getSpan = (textChunk) => {
    let span;
    let text = textChunk.text;
    if (textChunk.current) {
      span = <CurrentSpan ref={this.props.currentRef}>{text}</CurrentSpan>;
    } else if (textChunk.match) {
      span = <MatchSpan>{text}</MatchSpan>;
    } else {
      span = <span>{text}</span>;
    }
    return span;
  };

  render() {
    return <>{this.getMessages()}</>;
  }
}
